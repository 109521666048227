export default function AlternativeImage({
  alternative,
  points
}: Admin.ExecuteAlternativeImageProps) {
  return (
    <>
      <dt className="text-sm font-semibold">
        <span className="flex items-center gap-2">
          {alternative.properties?.src && (
            <img src={alternative.properties.src} alt={alternative.text} className="w-10 h-10" />
          )}
          {alternative.properties.alt}
        </span>
      </dt>
      <dd className="text-lg">{points || 0}</dd>
    </>
  );
}
