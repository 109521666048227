import { useParams } from 'react-router';
import ProjectConfig from './config/project-config';
import useProject from '../../services/use-project';
import PageContent from '../../components/page/page-content';
import PageFooter from '../../components/page/page-footer';
import { ButtonSave } from '../../components/button/button';

interface Params {
  projectId: string;
}

export default function EditProjectPage() {
  const { projectId } = useParams<Params>();

  const {
    project,
    setValue,
    addReusableType,
    removeReusableType,
    addField,
    removeField,
    resetProject,
    saveProject,
    updateReusableType,
    updateReusableTypeField,
    addPollType,
    removePollType,
    updatePollType,
    updateRuleType,
    addFlagType,
    removeFlagType,
    updateFlagType,
    checkMatchedError,
    getErrorMessage
  } = useProject({ projectId });

  function handleChangeFactory(path: string) {
    return function handleChange(e: any) {
      const value = e.target?.value;
      if (path && value) {
        setValue(path, value);
      }
    };
  }

  return (
    <>
      <PageContent>
        <ProjectConfig
          project={project}
          addReusableType={addReusableType}
          removeReusableType={removeReusableType}
          addField={addField}
          removeField={removeField}
          addPollType={addPollType}
          removePollType={removePollType}
          updatePollType={updatePollType}
          updateRuleType={updateRuleType}
          updateReusableType={updateReusableType}
          updateReusableTypeField={updateReusableTypeField}
          addFlagType={addFlagType}
          removeFlagType={removeFlagType}
          updateFlagType={updateFlagType}
          updateValue={handleChangeFactory}
          checkMatchedError={checkMatchedError}
          getErrorMessage={getErrorMessage}
        />
      </PageContent>

      {/* Make this appear if stuff has changed */}
      <PageFooter>
        <div className="flex gap-2 items-center ml-auto">
          <ButtonSave onSave={resetProject} variant="secondary">
            Forkast endringer
          </ButtonSave>
          <ButtonSave onSave={saveProject} variant="primary">
            Lagre innstillinger
          </ButtonSave>
        </div>
      </PageFooter>
    </>
  );
}
