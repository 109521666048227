import { ReactNode } from 'react';
import classNames from 'classnames';

function Stack({ children, className }: Props) {
  const stackClassNames = classNames('grid gap-2 mb-3', className);
  return <div className={stackClassNames}>{children}</div>;
}

interface Props {
  children: ReactNode;
  className?: string;
}

export default Stack;
