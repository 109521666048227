import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import Page from '../../components/page/page';
import PageHeader from '../../components/page/page-header';

import ProjectList from './project-list';
import ProjectReusables from '../reusables/reusables';
import EditProjectPage from './edit-project-page';
import useProject from '../../services/use-project';
import useGroups from '../../services/use-groups';
import ErrorBoundary from '../../components/error-boundary/error-boundary';

interface Params {
  projectId: string;
}

export default function ProjectPage(props: any) {
  const { projectId } = useParams<Params>();
  const match = useRouteMatch();

  const { project } = useProject({ projectId });
  const {
    groups,
    createGroup,
    publishGroup,
    unpublishGroup,
    removeGroup,
    duplicateGroup,
    changeGroupName
  } = useGroups({
    projectId: Number(projectId)
  });

  const [publishedGroups, setPublishedGroups] = useState<Array<Midas.Group>>([]);
  const [draftGroups, setDraftGroups] = useState<Array<Midas.Group>>([]);
  const [finishedGroups, setFinishedGroups] = useState<Array<Midas.Group>>([]);

  const breadcrumbs = [{ path: '/', name: 'Prosjekter' }];

  useEffect(() => {
    setPublishedGroups(groups.filter((group) => group.state === 'PUBLISHED'));
    setDraftGroups(groups.filter((group) => group.state === 'DRAFT'));
    setFinishedGroups(groups.filter((group) => group.state === 'ARCHIVED'));
  }, [groups]);

  return (
    <>
      <Page>
        <PageHeader title={project?.title || 'Mitt nye prosjekt'} breadcrumbs={breadcrumbs}>
          <div className="flex items-end">
            <nav className="flex-1 flex gap-6 text-sm">
              <NavLink
                className="py-2"
                activeClassName="border-b-4 border-primary"
                to={`/projects/${projectId}/list`}
              >
                Avstemninger
              </NavLink>
              <NavLink
                className="py-2 mr-auto"
                activeClassName="border-b-4 border-primary"
                to={`/projects/${projectId}/shared-content`}
              >
                Delt innhold
              </NavLink>
              <NavLink
                className="py-2"
                activeClassName="border-b-4 border-primary"
                to={`/projects/${projectId}/config`}
              >
                Innstillinger
              </NavLink>
            </nav>
          </div>
        </PageHeader>
        <Switch>
          <Route path={match.url} exact>
            <Redirect to={`${match.url}/list`} />
          </Route>
          <Route path={`${match.url}/list`} exact>
            <ProjectList
              projectId={projectId}
              publishedGroups={publishedGroups}
              draftGroups={draftGroups}
              finishedGroups={finishedGroups}
              createGroup={createGroup}
              publishGroup={publishGroup}
              unpublishGroup={unpublishGroup}
              duplicateGroup={duplicateGroup}
              removeGroup={removeGroup}
              changeGroupName={changeGroupName}
            />
          </Route>
          <Route path={`${match.url}/shared-content`} exact>
            <ProjectReusables projectId={Number(projectId)} />
          </Route>
          <Route path="/projects/:projectId/config" exact>
            <ErrorBoundary>
              <EditProjectPage />
            </ErrorBoundary>
          </Route>
        </Switch>
      </Page>
    </>
  );
}
