import { isImageField } from '../typeguards/field';

export function getDefaultImageSpecification(): Midas.ImageSpecification {
  return {
    // @todo: create sensible defaults? This is just random.
    width: 500,
    height: 500,
    format: 'png'
  };
}
/**
 * Get the first image field (if any) found in the fields of the reusable otherwise return null.
 * @param reusable
 * @returns
 */
export function getImageField(reusable: Midas.Reusable): Midas.ImageField | null {
  const imageField = reusable.fields.find((field) => field.type === 'IMAGE');
  if (imageField && isImageField(imageField)) {
    return imageField;
  }

  return null;
}

export function getHighlightedImageField(
  reusable: Midas.Reusable,
  reusableType: Midas.ReusableType
): Midas.ImageField | null {
  const fieldType = reusableType.fields.find((field) => field.highlight && field.type === 'IMAGE');
  const imageField = reusable.fields.find(
    (field) => field.variableName === fieldType?.variableName
  );

  if (imageField && isImageField(imageField)) {
    return imageField;
  }

  return null;
}

export function getImageSpecificationFromReusable(
  field: Midas.Field,
  reusableType: Midas.ReusableType
): Midas.ImageSpecification | null {
  const imageField = reusableType.fields.find((fieldType) => {
    if (fieldType.type === 'IMAGE') {
      return fieldType.variableName === field.variableName;
    }
    return false;
  });

  if (imageField) {
    return (imageField as Midas.ReusableTypeImageField).properties;
  }

  return null;
}

export function isImageInPortrait(imageSpecification?: Midas.ImageSpecification): boolean {
  if (imageSpecification) {
    const width = Number(imageSpecification.width) || 1;
    const height = Number(imageSpecification.height) || 1;

    return height > width;
  }

  return false;
}

export function getImageStyling(imageSpecification: Midas.ImageSpecification): Midas.ImageStyling {
  const width = imageSpecification.width || 1;
  const height = imageSpecification.height || 1;
  const isPortrait = height > width;
  const styling: any = {
    aspectRatio: `${width} / ${height}`
  };

  if (isPortrait) {
    styling.maxHeight = '80vh';
    styling.height = '100%';
  } else {
    styling.width = '100%';
  }

  return styling;
}
