import Joi from 'joi';
import ReusableTypeSchema from './reusable-type';
import PollTypeSchema from './poll-type';

const ProjectSchema = Joi.object({
  id: Joi.number(),
  title: Joi.string().min(3).max(250).required(),
  properties: Joi.object({
    executionType: Joi.string().valid('SERIE', 'SINGLE').required(),
    clientURL: Joi.string(),
    reusableTypes: Joi.array().items(ReusableTypeSchema),
    pollTypes: Joi.array().items(PollTypeSchema)
  }).required(),
  maxPublishedGroups: Joi.number().min(1).required().messages({
    'any.required': '"Maks antall publiserte grupper" er påkrevd',
    'number.base': '"Maks antall publiserte grupper" må være et tall',
    'number.min': '"Maks antall publiserte grupper" må være minst 1'
  }),
  updated: Joi.any()
});

export default ProjectSchema;
