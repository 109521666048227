import classNames from 'classnames';
import FormProps from '../form-props';

interface Props extends FormProps {
  checked: boolean;
  disabled?: boolean;
  hasWarning?: boolean;
  value?: any;
  onChange: (e: any) => void;
}

export default function LabelledSwitch({
  className,
  label,
  name,
  checked = false,
  disabled = false,
  hasWarning = false,
  onChange,
  value
}: Props) {
  const labelClassName = classNames('flex items-center gap-2', className);
  return (
    <label className={labelClassName}>
      <input
        type="checkbox"
        name={name}
        className="mid-switch"
        checked={checked}
        disabled={disabled}
        aria-invalid={hasWarning}
        onChange={onChange}
        value={value}
      />
      {label}
    </label>
  );
}
