import { ReactNode } from 'react';

export function SectionContent({ useContainer, children }: Props) {
  let classStyling = 'flex-1';

  return <div className={classStyling}>{children}</div>;
}

interface Props {
  useContainer?: boolean;
  children: ReactNode;
}
