import { useDrop } from 'react-dnd';
import classnames from 'classnames';
import { EXECUTE_ITEM_TYPE } from './execute-item';

interface Props {
  onDrop: (item: any, monitor: any, index: number) => void;
  index: number;
}

export default function ExecuteDrop({ onDrop, index }: Props) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: EXECUTE_ITEM_TYPE,
    drop: (item: any, monitor: any) => {
      onDrop(item, monitor, index);
    },
    collect: (monitor) => {
      const data = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
      return data;
    }
  });

  const classes = classnames('block w-100', {
    'border border-black h-8': canDrop,
    'h-0': !canDrop
  });

  return <div ref={drop} className={classes}></div>;
}
