import { useState } from 'react';
import { ButtonAction } from '../../components/button/button';
import ImageModal from '../../components/form/image-select/image-modal';
import { IconClose } from '../../components/icons/icons';
import SimpleModal from '../../components/modal/simple-modal';
import { getDefaultImageSpecification, getImageStyling } from '../../utils/image';

export default function AlternativeImage({
  alternative,
  pollType,
  onChange,
  onDelete,
  disabled,
  checkMatchedError
}: Admin.AlternativeImageProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  function onImageChange(src: string, params: any, kaleidoId: string, altText: string) {
    //onChange(alternative.id, 'text')(altText);
    //TODO: Can't send them in like this, will not save both changes
    onChange(
      alternative.id,
      'properties'
    )({
      src: src,
      params,
      kaleidoId,
      alt: altText
    });
  }

  const isImageError = checkMatchedError(['properties', 'src']);
  const isTextError = checkMatchedError(['properties', 'alt']);

  const imageSpecification = pollType?.alternativeProperties || getDefaultImageSpecification();
  const styling = getImageStyling(imageSpecification);

  return (
    <>
      {showModal && (
        <SimpleModal onClose={() => setShowModal(false)} className="max-w-7xl">
          <ImageModal
            alt={alternative.properties?.alt}
            src={alternative.properties.src}
            params={alternative.properties.params || {}}
            imageSpecification={imageSpecification}
            kaleidoId={alternative.properties.kaleidoId}
            onChange={onImageChange}
            onClose={() => setShowModal(false)}
          />
        </SimpleModal>
      )}
      <div className="bg-white py-2 px-3 shadow rounded min-h-12 flex items-center max-w-xl">
        <ButtonAction className="flex-1" onClick={() => setShowModal(true)}>
          {alternative.properties?.src && (
            <div className="flex items-center justify-center h-10 w-10 mr-2 border-gray-100 border bg-gray-200">
              <img
                className="object-cover"
                src={alternative.properties.src}
                alt={alternative.properties.alt}
                style={styling}
              />
            </div>
          )}
          <div className="text-left">
            {alternative.properties?.alt || 'Velg bilde'}
            <br />
            <span className="opacity-80">ID {alternative.id}</span>
          </div>
        </ButtonAction>
        <button
          className="org-button disabled:opacity-60 disabled:cursor-not-allowed"
          disabled={disabled}
          onClick={onDelete}
          aria-label="Slett alternativ"
        >
          <IconClose />
        </button>
      </div>
      {(isImageError || isTextError) && (
        <small className="py-1 px-2 text-xs bg-yellow-400 rounded">
          {isImageError ? 'Bilde må være satt' : 'Alternativ tekst må være satt'}
        </small>
      )}
    </>
  );
}
