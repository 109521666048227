import { ReactNode } from 'react';
import Expand from '../expand/expand';
import ExpandButton from '../expand/expand-button';
import { IconDotdotdot } from '../icons/icons';

function MoreDropdown({ children, dropdownKey }: Props) {
  return (
    <div className="relative">
      <ExpandButton
        expandKey={dropdownKey}
        className="flex items-center justify-center flex-shrink-0 w-8 h-8 rounded hover:bg-gray-500 hover:bg-opacity-10 z-10"
      >
        <IconDotdotdot />
      </ExpandButton>
      <Expand expandKey={dropdownKey}>
        <div className="absolute -right-1.5 top-full mt-1.5 py-1.5 px-1.5 rounded-md bg-white border border-gray-200 shadow-xl z-20 grid gap-0.5 w-max">
          {children}
        </div>
        <ExpandButton
          expandKey={dropdownKey}
          className="fixed inset-0 w-full bg-dark bg-opacity-5 z-10"
        >
          <span className="sr-only">Lukk dropdown-meny</span>
        </ExpandButton>
      </Expand>
    </div>
  );
}

interface Props {
  dropdownKey: string;
  children: ReactNode;
}

export default MoreDropdown;
