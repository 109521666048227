import { useState } from 'react';
import Tile from '../../components/tile/tile';
import { ButtonAction } from '../../components/button/button';
import SimpleModal from '../../components/modal/simple-modal';
import Stack from '../../components/stack/stack';
import {
  getImageField,
  getHighlightedImageField,
  getImageStyling,
  getImageSpecificationFromReusable,
  getDefaultImageSpecification
} from '../../utils/image';

interface Props {
  poll: Midas.Poll;
  pollType: Midas.PollType;
  disabled: boolean;
  onChange: (pollId: number, path: string, value: any) => void;
  checkMatchedError: (path: Array<string | number>) => boolean;
  reusables: Array<Midas.Reusable>;
  reusableTypes: Array<Midas.ReusableType>;
  className?: string;
}

export default function QuestionReusable({
  reusables,
  reusableTypes,
  poll,
  pollType,
  onChange,
  disabled,
  checkMatchedError,
  className = ''
}: Props) {
  const [reusableListOpen, setReusableListOpen] = useState(false);
  const reusableType = reusableTypes.find((rt) => rt.variableName === pollType.questionType);
  const reusable = reusables.find((r) => r.id === poll.reusableId);
  const filteredReusables = reusables.filter((r) => r.type === pollType.questionType);
  const isInvalid = checkMatchedError(['reusableId']);
  const hasReusable = typeof reusable !== 'undefined';

  function onChangeValue(value?: number) {
    if (!value) {
      return;
    }

    onChange(poll.id, 'reusableId', value);
  }

  function openReusableSelect() {
    setReusableListOpen(true);
  }

  if (typeof reusableType === 'undefined') {
    console.error('Could not find matching reusable type: ', {
      reusables,
      reusableTypes,
      poll
    });
    return <div>Cannot render QuestionReusable. Check console.</div>;
  }

  const imageOrEmojiEl = hasReusable
    ? getReusableImage(reusable, reusableType) || getReusableEmoji(reusable, reusableType)
    : undefined;
  const textEl = hasReusable ? getReusableText(reusable, reusableType) : undefined;

  return (
    <>
      {reusableListOpen && (
        <SimpleModal
          onClose={() => {
            setReusableListOpen(false);
          }}
          className="max-w-md"
        >
          <>
            <h3 className="mb-4 text-lg font-semibold">Velg innhold</h3>
            <Stack>
              {filteredReusables.map((reusable) => (
                <button
                  key={reusable.id}
                  className="flex items-center px-3 py-2 text-left bg-white rounded shadow min-h-12 hover:ring-2 hover:ring-primary"
                  onClick={() => {
                    onChangeValue(reusable.id);
                    setReusableListOpen(false);
                  }}
                >
                  {getReusableImage(reusable, reusableType) ||
                    getReusableEmoji(reusable, reusableType)}
                  {textEl || `ID ${reusable.id}`}
                </button>
              ))}
            </Stack>
          </>
        </SimpleModal>
      )}
      <Tile className={`max-w-xl ${className}`}>
        <ButtonAction onClick={openReusableSelect} className="flex w-full">
          {imageOrEmojiEl}
          <div className="flex-1 text-left">
            {textEl || 'Ingen valgt'}
            <br />
            <span className="opacity-80">ID {poll.reusableId || ''}</span>
          </div>

          {isInvalid && (
            <small className="py-1 px-2 text-xs bg-yellow-400 rounded">Må være satt</small>
          )}
        </ButtonAction>
      </Tile>
    </>
  );
}

function getReusableText(reusable: Midas.Reusable, reusableType: Midas.ReusableType) {
  const highlightedText =
    reusableType.fields.find((f) => f.highlight && f.type === 'TEXT') ||
    reusableType.fields.find((f) => f.type === 'TEXT');

  return (
    reusable.fields.find((field) => field.variableName === highlightedText?.variableName)?.text ||
    'Velg en'
  );
}

function getReusableImage(reusable: Midas.Reusable, reusableType: Midas.ReusableType) {
  const imageField = getHighlightedImageField(reusable, reusableType) || getImageField(reusable);

  let styling;
  if (imageField) {
    const imageSpecification =
      getImageSpecificationFromReusable(imageField, reusableType) || getDefaultImageSpecification();
    styling = getImageStyling(imageSpecification);
  }

  return imageField?.properties?.src ? (
    <div className="flex items-center justify-center h-10 w-10 mr-2 border-gray-100 border bg-gray-200">
      <img
        src={imageField.properties.src}
        alt={imageField.text}
        className="object-cover"
        style={styling}
      />
    </div>
  ) : undefined;
}

function getReusableEmoji(reusable: Midas.Reusable, reusableType: Midas.ReusableType) {
  const highlightedEmoji =
    reusableType.fields.find((f) => f.highlight && f.type === 'EMOJI') ||
    reusableType.fields.find((f) => f.type === 'EMOJI');

  const emojiField = reusable?.fields.find(
    (field) => field.variableName === highlightedEmoji?.variableName
  );

  return emojiField ? <span className="text-lg mr-2">{emojiField.text}</span> : undefined;
}
