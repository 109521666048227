/**
 * Deep merge two objects
 * If there are arrays, the first one will be overwritten by the second
 * @param {object} org original object
 * @param {object} obj overwriting object
 * @returns {object} New object out of the two
 */
export const deepMerge = function (org: any, obj: any): any {
  if (obj instanceof Array) {
    return [...obj];
  }
  const item = {
    ...org,
    ...obj
  };

  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Array || typeof obj[key] === 'object') {
        item[key] = deepMerge(org[key] || {}, obj[key]);
      }
    });
  }

  return item;
};

/**
 * Clones object or arrays value by value
 * @param {any} aObject Object or array to be copied from
 * @param {Object} options
 * @param {Array} options.skip A list of strings of keys to skip (regardless of path)
 * @param {Array<String>} options.skipSpecific A list of strings that will skip keys matching the path specified.
 * Note: it cannot target specific elements in an array.
 * @returns {any} Returns a new object identical to the original
 */
export const deepClone: DeepClone = function (aObject: any, options = {}, path = '') {
  if (!aObject) {
    return aObject;
  }

  let value;
  let bObject: any = Array.isArray(aObject) ? [] : {};

  for (const key in aObject) {
    let newPath = '';

    if (!Array.isArray(aObject)) {
      newPath = path.length > 0 ? `${path}.${key}` : `${key}`;
    } else {
      newPath = path as string;
    }

    if (options.skip && options.skip.includes(key)) {
      continue;
    }

    if (options.skipSpecific && options.skipSpecific.includes(newPath)) {
      continue;
    }

    value = aObject[key];

    bObject[key] = typeof value === 'object' ? deepClone(value, options, newPath) : value;
  }

  return bObject;
};

export type DeepClone = (
  aObject: any,
  options?: DeepCloneOptions,
  path?: string | Array<string>
) => any;
export interface DeepCloneOptions {
  skip?: Array<string>;
  skipSpecific?: Array<string>;
}
