import Joi from 'joi';
import { getReusableSchema } from './schemas/reusable';

export function validateReusable(
  reusable: Midas.Reusable,
  reusableType: Midas.ReusableType
): Promise<Joi.ValidationError | Midas.Reusable> {

  const schema = getReusableSchema(reusableType);
  const { error } = schema.validate(reusable, { abortEarly: false });

  if (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(reusable);
}
