import Alternative from '../alternative/alternative';
import Expand from '../../components/expand/expand';
import ExpandButton from '../../components/expand/expand-button';
import { IconChevronClosed, IconCopy, IconGarbage, IconPlus } from '../../components/icons/icons';
import LabelledInput from '../../components/form/labelled-input/labelled-input';
import {
  addAlternative,
  deleteAlternative,
  deletePoll,
  resetPoll,
  savePoll,
  setAlternativeValue
} from '../../services/use-polls';
import { ButtonAction } from '../../components/button/button';
import { ButtonSave } from '../../components/button/button';
import MoreDropdown from '../../components/more-dropdown/more-dropdown';
import { useDrag } from 'react-dnd';
import QuestionReusable from './poll-question';

export const EXECUTE_ITEM_TYPE = 'executeitem';

function PollEditTile({
  unlock,
  reusables,
  reusableTypes,
  poll,
  pollType,
  onInputChange,
  savePoll,
  resetPoll,
  addAlternative,
  deleteAlternative,
  setAlternativeValue,
  duplicatePoll,
  deletePoll,
  checkMatchedError
}: Props) {
  const onChangePredefined = (path: string) => {
    return (e: any) => {
      onInputChange(poll.id, path, e.target.value);
    };
  };

  const onChangeAlternativePredefined = (altId: number, path: string) => {
    return (value: any) => {
      setAlternativeValue(poll.id, altId, path, value);
    };
  };

  const onClick = (e: any) => {
    unlock(poll.id);
  };

  function wrappedCheckMatchedError(prefixPath: Array<string | number>) {
    return function (path: Array<string | number>) {
      return checkMatchedError([...prefixPath, ...path]);
    };
  }

  const [{ opacity }, dragRef, previewRef] = useDrag(
    () => ({
      type: EXECUTE_ITEM_TYPE,
      item: { id: poll.id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  );

  // const isDisabled = !canEdit(poll.isLocked);
  const isDisabled = false;
  const lockedStyling: any = {}; //getRandomColor();
  const sortedAlternatives = poll.alternatives.sort((a, b) => a.index - b.index);

  return (
    <div ref={previewRef} className="relative">
      <div
        ref={dragRef}
        className="relative flex items-center flex-shrink-0 h-14 mr-2 sm:absolute sm:right-full"
      >
        <svg
          className="w-4 h-4 text-gray-700 cursor-move"
          viewBox="0 0 16 16"
          width="16"
          height="16"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2H5v2h2V2zm4 0H9v2h2V2zM5 7h2v2H5V7zm6 0H9v2h2V7zm-6 5h2v2H5v-2zm6 0H9v2h2v-2z"
            opacity=".5"
          />
        </svg>
      </div>
      <div className="flex flex-row w-full relative">
        <ExpandButton
          expandKey={poll.id.toString()}
          className="py-2 px-3 flex items-center flex-1 text-left text-sm bg-white rounded shadow"
        >
          <IconChevronClosed className="mid-expand-indicator" />
          <div className="grid pl-2">
            <div className="font-semibold">{poll.text || 'Mangler tittel'}</div>
            <div className="opacity-80 ">Avstemning | ID {poll.id}</div>
          </div>
        </ExpandButton>

        <div className="absolute right-0 inset-y-0 flex items-center px-3">
          <MoreDropdown dropdownKey={`id-${poll.id}`}>
            <ButtonAction
              className="items-start whitespace-nowrap"
              onClick={() => {
                duplicatePoll(poll.id);
              }}
              variant="secondary"
              disabled={isDisabled}
            >
              <IconCopy />
              <span>Dupliser interaksjon</span>
            </ButtonAction>
            <ButtonAction
              className="items-start whitespace-nowrap"
              onClick={() => {
                deletePoll(poll.id);
              }}
              variant="secondary"
              disabled={isDisabled}
            >
              <IconGarbage />
              <span>Slett interaksjon &hellip;</span>
            </ButtonAction>
          </MoreDropdown>
        </div>

        {isDisabled && (
          <div className="flex items-center">
            <div className="px-4 rounded-l-full" style={{ ...lockedStyling }}>
              {poll}
            </div>
            <button
              className="px-4 rounded-r-full"
              style={{ marginLeft: '1px', ...lockedStyling }}
              onClick={onClick}
            >
              Lås opp
            </button>
          </div>
        )}
      </div>

      <Expand expandKey={poll.id.toString()}>
        <div className="mb-6">
          <div
            className="pt-5 pb-8 px-5 sm:px-10 bg-gray-200"
            style={
              isDisabled
                ? {
                    border: `solid 2px ${lockedStyling.background}`,
                    borderTopLeftRadius: 'unset',
                    borderTopRightRadius: 'unset',
                    borderTop: 'none'
                  }
                : {
                    border: 'solid 2px transparent'
                  }
            }
          >
            <div className="mb-8">
              <LabelledInput
                name={`interaction-${poll.id}-text`}
                label="Spørsmål"
                className="max-w-sm mb-4"
                value={poll.text}
                onChange={onChangePredefined('text')}
                isInvalid={checkMatchedError(['text'])}
                warning="Spørsmål må være satt"
              />
              {pollType && pollType?.questionType !== 'TEXT' ? (
                <QuestionReusable
                  className="mb-4"
                  poll={poll}
                  pollType={pollType}
                  disabled={isDisabled}
                  onChange={onInputChange}
                  checkMatchedError={checkMatchedError}
                  reusables={reusables}
                  reusableTypes={reusableTypes}
                />
              ) : (
                ''
              )}
            </div>

            <div className="grid gap-6 mb-6">
              {sortedAlternatives.map((alt, i) => (
                <Alternative
                  key={alt.id}
                  reusables={reusables}
                  reusableTypes={reusableTypes}
                  alternative={alt}
                  pollType={pollType}
                  onChange={onChangeAlternativePredefined}
                  onDelete={() => {
                    deleteAlternative(poll.id, alt.id);
                  }}
                  disabled={isDisabled}
                  checkMatchedError={wrappedCheckMatchedError(['alternatives', i])}
                />
              ))}
            </div>
            <ButtonAction
              variant="suggestive"
              onClick={() => {
                addAlternative(poll.id);
              }}
              disabled={isDisabled}
            >
              <IconPlus className="-ml-1" />
              Legg til alternativ
            </ButtonAction>
          </div>
          <div className="flex flex-row-reverse gap-2 py-2 px-5 sm:px-10 bg-gray-300 rounded-b">
            <ButtonSave
              variant="primary"
              onSave={() => {
                return savePoll(poll.id);
              }}
              disabled={isDisabled}
            >
              Lagre endringer
            </ButtonSave>
            <ButtonSave
              onSave={() => {
                return resetPoll(poll.id);
              }}
              disabled={isDisabled}
            >
              Forkast endringer
            </ButtonSave>
          </div>
        </div>
      </Expand>

      {isDisabled ? <div className="bg-red-700 absolute inset-0 w-full opacity-5"></div> : ''}
    </div>
  );
}

interface Props {
  reusables: Array<Midas.Reusable>;
  reusableTypes: Array<Midas.ReusableType>;
  poll: Midas.Poll;
  pollType?: Midas.PollType;
  onInputChange: any;
  savePoll: savePoll;
  resetPoll: resetPoll;
  addAlternative: addAlternative;
  deleteAlternative: deleteAlternative;
  duplicatePoll: (pollId: number) => void;
  deletePoll: (pollId: number) => void;
  setAlternativeValue: setAlternativeValue;
  unlock: (id: number) => void;
  checkMatchedError: (path: Array<string | number>) => boolean;
}

export default PollEditTile;
