import { NavLink } from 'react-router-dom';
import LabelledSwitch from '../../components/form/labelled-switch/labelled-switch';

export default function GroupModeSwitch({
  projectId,
  groupId,
  showPreview,
  onTogglePreview
}: Props) {
  return (
    <div className="flex items-end mb-4">
      <nav className="mr-auto">
        <div className="flex gap-2 p-2 text-sm bg-gray-300 shadow-inner rounded-md">
          <NavLink
            className="py-2 px-4"
            activeClassName="bg-white shadow rounded font-medium"
            to={`/projects/${projectId}/groups/${groupId}/edit`}
          >
            Redigering
          </NavLink>
          <NavLink
            className="py-2 px-4"
            activeClassName="bg-white shadow rounded font-medium"
            to={`/projects/${projectId}/groups/${groupId}/execute`}
          >
            Avvikling
          </NavLink>
        </div>
      </nav>
      <div className="py-3 text-sm">
        <LabelledSwitch label="Forhåndsvisning" checked={showPreview} onChange={onTogglePreview} />
      </div>
    </div>
  );
}

interface Props {
  projectId: string;
  groupId: string;
  showPreview: boolean;
  onTogglePreview: () => void;
}
