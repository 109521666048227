import LabelledInput from '../../components/form/labelled-input/labelled-input';
import { IconClose } from '../../components/icons/icons';

export default function AlternativeText({
  alternative,
  onChange,
  onDelete,
  disabled,
  checkMatchedError
}: Admin.AlternativeTextProps) {
  return (
    <div className="flex items-end">
      <LabelledInput
        label="Svaralternativ"
        className="max-w-sm"
        value={alternative.text}
        onChange={(e) => {
          onChange(alternative.id, 'text')(e.target.value);
        }}
        disabled={disabled}
        isInvalid={checkMatchedError(['text'])}
        warning="Svaralternativ må settes"
      />
      <button
        className="py-2 px-2 inline-flex items-center disabled:opacity-60 disabled:cursor-not-allowed"
        disabled={disabled}
        onClick={onDelete}
        aria-label="Slett alternativ"
      >
        <IconClose />
      </button>
    </div>
  );
}
