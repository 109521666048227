import { ReactNode } from 'react';
import classNames from 'classnames';
import Container from '../container/container';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';

function PageHeader({ title, children, className, breadcrumbs }: Props) {
  const pageHeaderClass = classNames('px-4 pt-8 w-full bg-white', className);
  return (
    <div className={pageHeaderClass}>
      <Container>
        {breadcrumbs && <Breadcrumbs paths={breadcrumbs} className="mb-2" />}
        <h1 className="text-3xl mb-6 font-light">{title}</h1>
        {children}
      </Container>
    </div>
  );
}

interface Props {
  title: string;
  className?: string;
  children?: ReactNode;
  breadcrumbs?: Array<Midas.BreadcrumbPath>;
}

export default PageHeader;
