import AlternativeImage from './image';
import AlternativeReusable from './reusable';
import AlternativeText from './text';
import AlternativeEmoji from './emoji';

export default function Alternative(props: Admin.ExecuteAlternativeProps) {
  if (isAlternativeText(props)) {
    return <AlternativeText {...props} />;
  }
  if (isAlternativeEmoji(props)) {
    return <AlternativeEmoji {...props} />;
  }
  if (isAlternativeImage(props)) {
    return <AlternativeImage {...props} />;
  }
  if (isAlternativeReusable(props)) {
    return <AlternativeReusable {...props} />;
  }

  console.error('Alternative type is not known: ', props);
  return <div>Kjenner ikke til alternativ type. Sjekk console</div>;
}

function isAlternativeText(
  props: Admin.ExecuteAlternativeProps
): props is Admin.ExecuteAlternativeTextProps {
  return (props as Admin.ExecuteAlternativeTextProps).alternative.type === 'TEXT';
}

function isAlternativeEmoji(
  props: Admin.ExecuteAlternativeProps
): props is Admin.ExecuteAlternativeEmojiProps {
  return (props as Admin.ExecuteAlternativeEmojiProps).alternative.type === 'EMOJI';
}

function isAlternativeImage(
  props: Admin.ExecuteAlternativeProps
): props is Admin.ExecuteAlternativeImageProps {
  return (props as Admin.ExecuteAlternativeImageProps).alternative.type === 'IMAGE';
}

function isAlternativeReusable(
  props: Admin.ExecuteAlternativeProps
): props is Admin.ExecuteAlternativeReusableProps {
  return (props as Admin.ExecuteAlternativeReusableProps).reusables !== undefined;
}
