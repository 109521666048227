export function getTextFieldFromReusableType(field: Midas.ReusableTypeTextField): Midas.TextField {
  return {
    variableName: field.variableName,
    type: field.type,
    text: ''
  };
}

export function getEmojiFieldFromReusableType(
  field: Midas.ReusableTypeEmojiField
): Midas.EmojiField {
  return {
    variableName: field.variableName,
    type: field.type,
    text: ''
  };
}

export function getImageFieldFromReusableType(
  field: Midas.ReusableTypeImageField
): Midas.ImageField {
  return {
    variableName: field.variableName,
    type: field.type,
    text: '',
    properties: getImageFieldProperties()
  };
}

export function getImageFieldProperties() {
  return {
    src: '',
    kaleidoId: '',
    params: getKaleidoParams()
  };
}

export function getKaleidoParams() {
  return {
    id: '',
    params: {
      w: 1,
      h: 1,
      x: 0,
      y: 0
    }
  };
}
