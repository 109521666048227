import React, { Component } from 'react';
import { IS_ANDROID } from '../../utils/devices';

interface Props {
  expandKey: string;
  isExpanded?: boolean;
  className?: string;
}

interface State {
  isExpanded: boolean;
}

export default class Expand extends Component<Props, State> {
  id = '';
  expandEl = React.createRef<HTMLDivElement>();
  ariaLabelledBy: string | undefined = undefined;

  constructor(props: any) {
    super(props);
    this.id = `${props.expandKey}-content`;
    this.handleClick = this.handleClick.bind(this);
    this.state = { isExpanded: props.isExpanded || false };

    // aria-labelledby causes problems on android talkback
    if (!IS_ANDROID) {
      this.ariaLabelledBy = `${props.expandKey}-button`;
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(event: MouseEvent) {
    if (event.target) {
      const target = event.target as HTMLElement;
      const btn = target.closest('a,button');
      if (btn === null) {
        return;
      }

      if (btn.hasAttribute('aria-controls') && btn.getAttribute('aria-controls') === this.id) {
        document.dispatchEvent(
          new CustomEvent('toggle.select', {
            detail: { isExpanded: !this.state.isExpanded, id: this.id }
          })
        );
        this.setState({ isExpanded: !this.state.isExpanded });
      }
    }
  }

  render() {
    return (
      <div
        id={this.id}
        role="group"
        aria-labelledby={this.ariaLabelledBy}
        hidden={!this.state.isExpanded}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}
