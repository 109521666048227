import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import FormProps from '../form-props';

interface Props extends FormProps {
  description?: string;
  placeholder?: string;
  value?: any;
  prefix?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  warning?: string;
  isInvalid?: boolean;
  focusInput?: boolean;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

export default function LabelledInput({
  label,
  className,
  description,
  placeholder,
  name,
  disabled,
  value,
  pattern,
  minLength,
  warning,
  isInvalid,
  prefix,
  onChange,
  onKeyDown,
  focusInput,
  maxLength
}: Props) {
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusInput && textInput.current !== null) {
      //Set focus on input
      textInput.current.focus();
    }
  }, [focusInput]);

  const labelClassName = classnames('grid gap-1 relative justify-items-start', className);
  const inputClassName = classnames(
    'rounded py-2 px-3 border border-gray-400 font-medium w-full text-sm invalid:border-yellow-400 invalid:shadow-none',
    {
      'pl-10': prefix
    }
  );
  return (
    <label className={labelClassName}>
      <span className="font-semibold text-sm">{label}</span>
      {description && <small className="mb-1 opacity-75 text-xs">{description}</small>}
      {prefix && (
        <span className="absolute top-7 left-0 bg-transparent border border-transparent pt-2 lg:pb-2 pb-4 px-3 text-sm opacity-60">
          {prefix}
        </span>
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        className={inputClassName}
        disabled={disabled}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-invalid={isInvalid}
        pattern={pattern}
        minLength={minLength}
        maxLength={maxLength}
        ref={textInput}
      />
      {isInvalid && warning && (
        <small className="py-1 px-2 text-xs bg-yellow-400 rounded block">{warning}</small>
      )}
    </label>
  );
}
