import Joi from 'joi';
import { createSchema } from './schemas/poll';

export function validatePoll(poll: Midas.Poll): Promise<Joi.ValidationError | Midas.Poll> {
  // Get Alternative type
  const firstAlt = poll.alternatives[0];
  const altType = firstAlt?.type;

  const Schema = createSchema(altType);

  const { error } = Schema.validate(poll, { abortEarly: false });
  if (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(poll);
}
