import Expand from '../../../components/expand/expand';
import ExpandButton from '../../../components/expand/expand-button';
import LabelledInput from '../../../components/form/labelled-input/labelled-input';
import LabelledSwitch from '../../../components/form/labelled-switch/labelled-switch';
import LabelledInputNumber from '../../../components/form/labelled-input-number/labelled-input-number';
import { IconChevronClosed, IconGarbage } from '../../../components/icons/icons';
import MoreDropdown from '../../../components/more-dropdown/more-dropdown';
import { ButtonAction } from '../../../components/button/button';
import LabelledSelect from '../../../components/form/labelled-select/labelled-select';
import LabelledRadio from '../../../components/form/labelled-radio/labelled-radio';
import { IMAGE_FORMAT } from '../../../enums/image';
import { getErrorMessage } from '../../../services/use-project';

interface Props {
  field: any;
  reusableTypeId: number;
  updateReusableTypeField: Function;
  removeField: Function;
  checkMatchedError: (path: Array<string | number>) => boolean;
  getErrorMessage: getErrorMessage;
}

const FIELD_TYPES = [
  {
    value: 'TEXT',
    text: 'Tekst'
  },
  {
    value: 'IMAGE',
    text: 'Bilde'
  },
  {
    value: 'EMOJI',
    text: 'Emoji'
  }
];

function getFieldTypeText(value: string) {
  return FIELD_TYPES.find((field) => field.value === value)?.text || value;
}

export default function ReusableFieldConfig({
  field,
  reusableTypeId,
  updateReusableTypeField,
  removeField,
  checkMatchedError,
  getErrorMessage
}: Props) {
  function wrappedOnChange(path: string) {
    return (e: any) => {
      if (e.target.type === 'checkbox') {
        updateReusableTypeField(reusableTypeId, field.id, path, e.target.checked);
      } else {
        updateReusableTypeField(reusableTypeId, field.id, path, e.target.value);
      }
    };
  }

  return (
    <div className="">
      <div className="relative">
        <ExpandButton
          expandKey={`field-${reusableTypeId}-${field.id}`}
          isExpanded={false}
          className="py-2 px-4 w-full bg-white text-left flex items-center gap-1 rounded shadow relative z-10"
        >
          <div className="flex items-center">
            <IconChevronClosed className="mid-expand-indicator" />
          </div>
          <div className="grid text-sm">
            <strong className="font-semibold">
              {field.title || 'Mangler tittel'} {field.isRequired && '*'}
            </strong>
            <span className="opacity-80 lowercase">{getFieldTypeText(field.type)}</span>
          </div>
        </ExpandButton>
        <div className="absolute right-0 inset-y-0 flex items-center px-3">
          <MoreDropdown dropdownKey={`field-options-${field.id}`}>
            <ButtonAction
              variant="secondary"
              onClick={() => {
                removeField(reusableTypeId, field.id);
              }}
            >
              <IconGarbage className="-ml-1" />
              Slett &hellip;
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      <Expand expandKey={`field-${reusableTypeId}-${field.id}`} isExpanded={false}>
        <div className="pt-6 pb-8 px-10 bg-gray-300 rounded-b mb-6">
          <div className="grid gap-6">
            <div className="flex flex-wrap gap-4">
              <LabelledInput
                label="Tittel"
                value={field.title}
                className="flex-1 min-w-32"
                onChange={wrappedOnChange('title')}
                isInvalid={checkMatchedError(['title'])}
                warning={getErrorMessage(['title'])}
              />
              <LabelledInput
                label="Variabelnavn"
                value={field.variableName}
                className="flex-1 min-w-32"
                onChange={wrappedOnChange('variableName')}
                isInvalid={checkMatchedError(['variableName'])}
                warning={getErrorMessage(['variableName'])}
              />
            </div>
            <div className="flex flex-wrap items-end gap-4">
              <LabelledSelect
                label="Felttype"
                value={field.type}
                className="flex-1"
                onChange={wrappedOnChange('type')}
                options={FIELD_TYPES}
              />
              <LabelledSwitch
                label="Påkrevd felt"
                className="mb-2"
                checked={field.isRequired}
                onChange={wrappedOnChange('isRequired')}
              />
            </div>
            {field.type === 'IMAGE' && (
              <div className="flex flex-wrap items-start gap-4">
                <fieldset className="flex flex-wrap items-end gap-4 mr-8">
                  <legend className="text-sm font-semibold mb-1">Filtype</legend>
                  <div className="flex flex-col flex-1 items-start gap-2">
                    <LabelledRadio
                      label=".jpg"
                      name={`image-properties-${reusableTypeId}-${field.id}`}
                      value={IMAGE_FORMAT.JPG}
                      checked={field.properties?.format === IMAGE_FORMAT.JPG}
                      onChange={wrappedOnChange('properties.format')}
                    />
                    <LabelledRadio
                      label=".png"
                      name={`image-properties-${reusableTypeId}-${field.id}`}
                      value={IMAGE_FORMAT.PNG}
                      checked={field.properties?.format === IMAGE_FORMAT.PNG}
                      onChange={wrappedOnChange('properties.format')}
                    />
                    <LabelledRadio
                      label=".webp"
                      name={`image-properties-${reusableTypeId}-${field.id}`}
                      value={IMAGE_FORMAT.WEBP}
                      checked={field.properties?.format === IMAGE_FORMAT.WEBP}
                      onChange={wrappedOnChange('properties.format')}
                    />
                  </div>
                </fieldset>
                <div className="flex flex-col gap-2">
                  <LabelledInputNumber
                    label="Bredde"
                    description="Bredden på bildet i antall piksler"
                    value={field.properties?.width}
                    min={1}
                    className="flex-1 min-w-32"
                    onChange={wrappedOnChange('properties.width')}
                    isInvalid={checkMatchedError(['properties', 'width'])}
                    warning={getErrorMessage(['properties', 'width'])}
                  />
                  <LabelledInputNumber
                    label="Høyde"
                    description="Høyden på bildet i antall piksler"
                    value={field.properties?.height}
                    min={1}
                    className="flex-1 min-w-32"
                    onChange={wrappedOnChange('properties.height')}
                    isInvalid={checkMatchedError(['properties', 'height'])}
                    warning={getErrorMessage(['properties', 'height'])}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Expand>
    </div>
  );
}
