import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactNode, useEffect, useRef, useState } from 'react';

const wrapperClassNames = 'rounded inline-flex';
const contentClassNames = 'py-2.5 px-4 inline-flex gap-2 text-sm font-medium';
const disabledClassNames = 'disabled:opacity-60 disabled:cursor-not-allowed';

const variantClassNames = {
  default: 'bg-gray-500 bg-opacity-40 hover:bg-opacity-60',
  primary: 'bg-blue-600 text-white hover:bg-blue-700',
  secondary: 'hover:bg-gray-600 hover:bg-opacity-10',
  tertiary: 'bg-white text-black hover:bg-opacity-80',
  suggestive:
    'border border-dashed border-gray-600 hover:bg-white hover:shadow hover:border-white hover:border-solid'
};

const noop = () => {};

export function ButtonAction({ variant, children, className, disabled, onClick }: ButtonProps) {
  return (
    <button
      className={classNames(
        className,
        wrapperClassNames,
        disabledClassNames,
        variantClassNames[variant]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={contentClassNames}>{children}</span>
    </button>
  );
}

const SAVE_STATES = {
  INACTIVE: 'INACTIVE',
  SAVING: 'SAVING',
  DONE: 'DONE'
};
export function ButtonSave({ variant, children, className, disabled, onSave }: SaveButtonProps) {

  const [isSaving, setIsSaving] = useState(SAVE_STATES.INACTIVE);
  let saveTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!isSaving) {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }
      return;
    }

    saveTimeout.current = setTimeout(() => {
      setIsSaving(SAVE_STATES.INACTIVE);
    }, 2000);

    return () => {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }
    };
  }, [isSaving, saveTimeout]);

  function onButtonClick(e: any) {
    setIsSaving(SAVE_STATES.SAVING);

    onSave()
      .then((cb = noop) => {
        setIsSaving(SAVE_STATES.DONE);
        if (typeof cb === 'function') {
          cb();
        }
      })
      .catch((cb = noop) => {
        setIsSaving(SAVE_STATES.DONE);
        if (typeof cb === 'function') {
          cb();
        } else {
          console.log(cb);
        }
        // TODO: Failed state??
      });
  }

  return (
    <button
      className={classNames(
        className,
        wrapperClassNames,
        variantClassNames[variant],
        'mid-save-button',
        {
          [`${disabledClassNames}`]: isSaving === SAVE_STATES.INACTIVE,
          'mid-save-button--animated': isSaving !== SAVE_STATES.INACTIVE
        }
      )}
      onClick={onButtonClick}
      disabled={disabled || isSaving !== SAVE_STATES.INACTIVE}
    >
      <span className={classNames(contentClassNames, 'mid-save-button__content')}>
        {children}
        <span className="mid-save-button__indicator" aria-hidden="true">
          <svg className="w-5 h-5" viewBox="0 0 20 20" focusable="false">
            <path d="M8.198 14.732l-4.135-4.135 1.414-1.414 2.645 2.645 5.905-6.56 1.486 1.337-7.315 8.127zm8.874-11.804c-3.906-3.905-10.238-3.905-14.143 0A9.973 9.973 0 0 0-.001 10c0 2.56.978 5.118 2.93 7.071 3.905 3.905 10.237 3.905 14.143 0A9.971 9.971 0 0 0 20 10.001c0-2.56-.976-5.12-2.928-7.073z"></path>
          </svg>
        </span>
      </span>
      <span className="mid-save-button__progress"></span>
    </button>
  );
}

export function ButtonLink({ variant, children, className, disabled, to }: LinkProps) {
  return (
    <Link className={classNames(className, wrapperClassNames, variantClassNames[variant])} to={to}>
      <span className={contentClassNames}>{children}</span>
    </Link>
  );
}

type Variant = 'default' | 'primary' | 'secondary' | 'tertiary' | 'suggestive';

interface ButtonProps {
  className?: string;
  variant: Variant;
  onClick: (e: any) => void;
  children: ReactNode;
  disabled?: boolean;
}

interface SaveButtonProps {
  className?: string;
  variant: Variant;
  onSave: () => Promise<any>;
  children: ReactNode;
  disabled?: boolean;
}

interface LinkProps {
  className?: string;
  variant: Variant;
  to: string;
  children: ReactNode;
  disabled?: boolean;
}

ButtonAction.defaultProps = { variant: variantClassNames.default };
ButtonSave.defaultProps = { variant: variantClassNames.primary };
ButtonLink.defaultProps = { variant: variantClassNames.default };
