import Expand from '../../../components/expand/expand';
import ExpandButton from '../../../components/expand/expand-button';
import RadioButton from '../../../components/form/labelled-radio/labelled-radio';
import LabelledInput from '../../../components/form/labelled-input/labelled-input';
import LabelledInputNumber from '../../../components/form/labelled-input-number/labelled-input-number';
import LabelledRadio from '../../../components/form/labelled-radio/labelled-radio';
import { IconChevronClosed, IconGarbage, IconPlus } from '../../../components/icons/icons';
import MoreDropdown from '../../../components/more-dropdown/more-dropdown';
import { ButtonAction } from '../../../components/button/button';
import ProjectConfigFlag from './project-config-flag';
import {
  removePollType,
  updatePollType,
  updateRuleType,
  addFlagType,
  removeFlagType,
  updateFlagType,
  checkMatchedError,
  getErrorMessage
} from '../../../services/use-project';
import Stack from '../../../components/stack/stack';
import { IMAGE_FORMAT } from '../../../enums/image';
import { isLimitedPollRules } from '../../../typeguards/rules';
import { RULE_TYPES } from '../../../enums/rules';
import LabelledCheckbox from '../../../components/form/labelled-checkbox/labelled-checkbox';

interface Props {
  pollType: Midas.PollType;
  updatePollType: updatePollType;
  removePollType: removePollType;
  updateRuleType: updateRuleType;
  reusableTypes: Array<Midas.ReusableType>;
  addFlagType: addFlagType;
  removeFlagType: removeFlagType;
  updateFlagType: updateFlagType;
  checkMatchedError: checkMatchedError;
  getErrorMessage: getErrorMessage;
  index: number;
}

export default function PollConfig({
  pollType,
  removePollType,
  reusableTypes,
  updatePollType,
  updateRuleType,
  addFlagType,
  removeFlagType,
  updateFlagType,
  checkMatchedError,
  getErrorMessage,
  index
}: Props) {
  function onChange(itId: number, path: string) {
    return (e: any) => {
      if (e.target.type === 'checkbox') {
        updatePollType(itId, path, e.target.checked);
      } else {
        updatePollType(itId, path, e.target.value);
      }
    };
  }

  function onRuleChange(
    pollTypeId: number,
    newRuleType: Midas.PollRuleType,
    rules: Midas.PollRules
  ) {
    return () => {
      updateRuleType(pollTypeId, newRuleType, rules);
    };
  }

  function wrappedCheckError(path: Array<string | number>) {
    return function (restOfPath: Array<string | number>) {
      return checkMatchedError([...path, ...restOfPath]);
    };
  }

  return (
    <div className="">
      <div className="relative">
        <ExpandButton
          expandKey={`view-type-${pollType.id}`}
          className="py-2 px-4 w-full bg-white text-left rounded shadow flex items-center gap-1 relative z-5"
          isExpanded={false}
        >
          <div className="flex items-center">
            <IconChevronClosed className="mid-expand-indicator" />
          </div>
          <div className="grid text-sm">
            <strong className="font-semibold">{pollType.title || 'Mangler tittel'}</strong>
            <span className="opacity-80">{pollType.variableName}</span>
          </div>
        </ExpandButton>
        <div className="absolute right-0 inset-y-0 flex items-center px-3">
          <MoreDropdown dropdownKey={`options-${pollType.id}`}>
            <ButtonAction
              variant="secondary"
              onClick={() => {
                removePollType(pollType.id);
              }}
            >
              <IconGarbage className="-ml-1" />
              Slett &hellip;
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      <Expand expandKey={`view-type-${pollType.id}`} isExpanded={false}>
        <div className="pt-6 pb-8 px-6 md:px-10 bg-gray-200 rounded-b mb-6">
          <div className="grid gap-6">
            <div className="flex gap-3">
              <LabelledInput
                label="Tittel"
                value={pollType.title}
                onChange={onChange(pollType.id, 'title')}
                className="flex-1"
                isInvalid={checkMatchedError(['properties', 'pollTypes', index, 'title'])}
                warning="Tittel må være satt"
              />
              <LabelledInput
                label="Variabel"
                value={pollType.variableName}
                onChange={onChange(pollType.id, 'variableName')}
                className="flex-1"
                isInvalid={checkMatchedError(['properties', 'pollTypes', index, 'variableName'])}
                warning="Variabel må være satt uten mellomrom"
              />
            </div>

            <hr className="w-full border-gray-300" />

            <div>
              <h2 className="text-lg">Stemmeregler</h2>
              <p>Her bestemmer du hvilke regler som skal gjelde for din avstemning.</p>

              <fieldset className="my-6">
                <legend className="text-sm font-semibold mb-1">Antall stemmer</legend>
                <div className="flex flex-col flex-1 items-start gap-2">
                  <LabelledRadio
                    label="Én stemme"
                    name={`voting-rules-${pollType.id}`}
                    value={RULE_TYPES.SINGLE}
                    checked={pollType.rules.type === RULE_TYPES.SINGLE}
                    onChange={onRuleChange(pollType.id, RULE_TYPES.SINGLE, pollType.rules)}
                  />
                  <LabelledRadio
                    label="Ubegrenset antall stemmer"
                    name={`voting-rules-${pollType.id}`}
                    value={RULE_TYPES.UNLIMITED}
                    checked={pollType.rules.type === RULE_TYPES.UNLIMITED}
                    onChange={onRuleChange(pollType.id, RULE_TYPES.UNLIMITED, pollType.rules)}
                  />
                  <LabelledRadio
                    label="Flere stemmer"
                    name={`voting-rules-${pollType.id}`}
                    value={RULE_TYPES.LIMITED}
                    checked={pollType.rules.type === RULE_TYPES.LIMITED}
                    onChange={onRuleChange(pollType.id, RULE_TYPES.LIMITED, pollType.rules)}
                  />
                </div>
              </fieldset>
              <div className="grid gap-3">
                <LabelledInputNumber
                  label="Max verdi per stemme"
                  description="Bestemmer hvor mye hver stemme en bruker avgir teller."
                  name="rules-maxVoteValue"
                  size={3}
                  min={1}
                  value={pollType.rules.maxVoteValue}
                  onChange={onChange(pollType.id, 'rules.maxVoteValue')}
                  isInvalid={checkMatchedError([
                    'properties',
                    'pollTypes',
                    index,
                    'rules',
                    'maxVoteValue'
                  ])}
                  warning={getErrorMessage([
                    'properties',
                    'pollTypes',
                    index,
                    'rules',
                    'maxVoteValue'
                  ])}
                />
                {isLimitedPollRules(pollType.rules) && (
                  <LabelledInputNumber
                    label="Antall stemmer tillatt"
                    description="Bestemmer hvor mange stemmer hver enkelt bruker kan avgi."
                    name="rules-votesPerUser"
                    size={3}
                    min={1}
                    value={pollType.rules.votesPerUser}
                    onChange={onChange(pollType.id, 'rules.votesPerUser')}
                    isInvalid={checkMatchedError([
                      'properties',
                      'pollTypes',
                      index,
                      'rules',
                      'votesPerUser'
                    ])}
                    warning={getErrorMessage([
                      'properties',
                      'pollTypes',
                      index,
                      'rules',
                      'votesPerUser'
                    ])}
                  />
                )}
                <LabelledCheckbox
                  label="Anonymiser stemmer"
                  checked={pollType.rules.anonymousVotes}
                  name="rules-anonymousVotes"
                  onChange={onChange(pollType.id, 'rules.anonymousVotes')}
                />
              </div>
            </div>

            <hr className="w-full border-gray-300" />

            <div>
              <h2 className="text-lg">Spørsmål</h2>
              <p>Bestemmer hvordan type spørsmål vi ønsker brukerne skal se i avstemningen.</p>

              <fieldset className="my-6">
                <legend className="text-sm font-semibold mb-1">Spørsmål</legend>
                <div className="flex flex-col items-start gap-2">
                  <RadioButton
                    label="Tekst"
                    name={`querstion-type-${pollType.id}`}
                    value="TEXT"
                    checked={pollType.questionType === 'TEXT'}
                    onChange={onChange(pollType.id, 'questionType')}
                  />
                  {reusableTypes?.map((reusableType) => {
                    return (
                      <RadioButton
                        key={reusableType.id}
                        label={`${reusableType.title} (Delt innhold)`}
                        name={`querstion-type-${pollType.id}`}
                        value={reusableType.variableName}
                        checked={pollType.questionType === reusableType.variableName}
                        onChange={onChange(pollType.id, 'questionType')}
                      />
                    );
                  })}
                </div>
              </fieldset>
            </div>

            <hr className="w-full border-gray-300" />

            <div>
              <h2 className="text-lg">Svar</h2>
              <p>Bestemmer hvordan type svar vi ønsker brukerne skal kunne gi i avstemningen.</p>

              <fieldset className="my-6">
                <legend className="text-sm font-semibold mb-1">Svartype</legend>
                <div className="flex flex-col items-start gap-2">
                  <RadioButton
                    label="Tekst"
                    name={`alternative-type-${pollType.id}`}
                    value="TEXT"
                    checked={pollType.alternativeType === 'TEXT'}
                    onChange={onChange(pollType.id, 'alternativeType')}
                  />
                  <RadioButton
                    label="Bilde"
                    name={`alternative-type-${pollType.id}`}
                    value="IMAGE"
                    checked={pollType.alternativeType === 'IMAGE'}
                    onChange={onChange(pollType.id, 'alternativeType')}
                  />
                  <RadioButton
                    label="Emoji"
                    name={`alternative-type-${pollType.id}`}
                    value="EMOJI"
                    checked={pollType.alternativeType === 'EMOJI'}
                    onChange={onChange(pollType.id, 'alternativeType')}
                  />
                  {reusableTypes?.map((reusableType) => {
                    return (
                      <RadioButton
                        key={reusableType.id}
                        label={`${reusableType.title} (Delt innhold)`}
                        name={`alternative-type-${pollType.id}`}
                        value={reusableType.variableName}
                        checked={pollType.alternativeType === reusableType.variableName}
                        onChange={onChange(pollType.id, 'alternativeType')}
                      />
                    );
                  })}
                </div>
              </fieldset>
              {pollType.alternativeType === 'IMAGE' && (
                <>
                  <div className="flex flex-wrap items-end gap-4">
                    <fieldset className="flex flex-wrap items-end gap-4 mt-6 mr-8">
                      <legend className="text-sm font-semibold mb-1">Filtype</legend>
                      <div className="flex flex-col flex-1 items-start gap-2">
                        <LabelledRadio
                          label=".jpg"
                          name={`poll-image-properties-${pollType.id}`}
                          value={IMAGE_FORMAT.JPG}
                          checked={pollType.alternativeProperties?.format === IMAGE_FORMAT.JPG}
                          onChange={onChange(pollType.id, 'alternativeProperties.format')}
                        />
                        <LabelledRadio
                          label=".png"
                          name={`poll-image-properties-${pollType.id}`}
                          value={IMAGE_FORMAT.PNG}
                          checked={pollType.alternativeProperties?.format === IMAGE_FORMAT.PNG}
                          onChange={onChange(pollType.id, 'alternativeProperties.format')}
                        />
                        <LabelledRadio
                          label=".webp"
                          name={`poll-image-properties-${pollType.id}`}
                          value={IMAGE_FORMAT.WEBP}
                          checked={pollType.alternativeProperties?.format === IMAGE_FORMAT.WEBP}
                          onChange={onChange(pollType.id, 'alternativeProperties.format')}
                        />
                      </div>
                    </fieldset>
                    <div className="flex flex-col gap-2">
                      <LabelledInputNumber
                        label="Bredde"
                        description="Bredden på bildet i antall piksler"
                        size={3}
                        min={1}
                        value={pollType.alternativeProperties?.width}
                        onChange={onChange(pollType.id, 'alternativeProperties.width')}
                        isInvalid={checkMatchedError([
                          'properties',
                          'pollTypes',
                          index,
                          'alternativeProperties',
                          'width'
                        ])}
                        warning={getErrorMessage([
                          'properties',
                          'pollTypes',
                          index,
                          'alternativeProperties',
                          'width'
                        ])}
                      />
                      <LabelledInputNumber
                        label="Høyde"
                        description="Høyden på bildet i antall piksler"
                        size={3}
                        min={1}
                        value={pollType.alternativeProperties?.height}
                        onChange={onChange(pollType.id, 'alternativeProperties.height')}
                        isInvalid={checkMatchedError([
                          'properties',
                          'pollTypes',
                          index,
                          'alternativeProperties',
                          'height'
                        ])}
                        warning={getErrorMessage([
                          'properties',
                          'pollTypes',
                          index,
                          'alternativeProperties',
                          'height'
                        ])}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <hr className="w-full border-gray-300" />

            <div>
              <h2 className="text-lg">Flagg</h2>
              <p>
                Kan benyttes til å beskrive status på et spørsmål. Til eksempel om det er en
                deltaker som er ferdig på scenen, og det ønskes å markeres.
              </p>

              {pollType.flags.length > 0 && (
                <div className="my-6">
                  <Stack>
                    {pollType.flags.map((flagType, i) => {
                      return (
                        <ProjectConfigFlag
                          key={flagType.id}
                          pollTypeId={pollType.id}
                          flag={flagType}
                          onChange={updateFlagType}
                          onDelete={removeFlagType}
                          checkMatchedError={wrappedCheckError([
                            'properties',
                            'pollTypes',
                            index,
                            'flags',
                            i
                          ])}
                        />
                      );
                    })}
                  </Stack>
                </div>
              )}

              <div className="flex justify-between items-center">
                <ButtonAction
                  variant="suggestive"
                  onClick={() => {
                    addFlagType(pollType.id);
                  }}
                >
                  <IconPlus className="-ml-1 mr-auto" />
                  Nytt flagg
                </ButtonAction>
              </div>
            </div>
          </div>
        </div>
      </Expand>
    </div>
  );
}
