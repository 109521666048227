import { useEffect, useState } from 'react';
import { getPollVotes } from '../api/polls';

const FETCH_INTERVAL = 20000;

export default function useVotes(projectId: number, pollId: number) {
  const [votes, setVotes] = useState<Midas.PollVotes>();

  useEffect(() => {
    function fetchVotes() {
      getPollVotes(projectId, pollId).then((data) => {
        setVotes(data);
      });
    }
    const interval = setInterval(fetchVotes, FETCH_INTERVAL);

    fetchVotes();

    return () => {
      clearInterval(interval);
    };
  }, [projectId, pollId]);

  return {
    votes
  };
}
