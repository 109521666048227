import React from 'react';

export default function Logo({ className = '' } = {}) {
  return (
    <svg
      className={className}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 18V0L18 18H0Z" fill="#5D666F" />
      <path d="M21 0V18L12 9L21 0Z" fill="#5D666F" />
      <path d="M14 4L10.5 7.5L7 4L10.5 0.5L14 4Z" fill="#5D666F" />
    </svg>
  );
}
