import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

const COLOR = {
  PRIMARY: 'org-color-primary',
  ERROR: 'org-color-error',
  WARNING: 'org-color-warning'
};

// The initial offset.
const OFFSET = 50;
// Space between notifications.
const GUTTER = 10;
// Base height of notification.
// @todo: this assumes that the notification is short (i.e. only one line of text)
const BASE_HEIGHT = 60;
// How long notification should be visible.
export const TIMEOUT = 8000;

export interface Props {
  label: string;
  type?: 'PRIMARY' | 'ERROR' | 'WARNING';
  canClose?: boolean;
  isFloating?: boolean;
  onNotificationRemove: (e: any) => void;
  style?: any;
  position?: number;
}

export default function Notification({
  type = 'PRIMARY',
  label,
  canClose,
  isFloating = true,
  onNotificationRemove = (e: any) => {},
  position = 1
}: Props) {
  const notificationClass = classNames('mid-notification', `${COLOR[type]}`, {
    'mid-notification--float': isFloating
  });

  const [containerClass, setContainerClass] = useState(notificationClass);
  const containerEl = useRef<HTMLDivElement>(null);

  const style = {
    bottom: `${position * (GUTTER + BASE_HEIGHT) + OFFSET}px`
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (containerEl.current) {
        setContainerClass(`${notificationClass} mid-notification--hide`);
      }
    }, TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  const closeNotification = (e: any) => {
    setContainerClass(`${notificationClass} mid-notification--hide`);
  };

  // @todo: trenger X-ikon
  return (
    <div
      ref={containerEl}
      className={containerClass}
      onAnimationEnd={onNotificationRemove}
      style={style}
      role="group"
    >
      <p className="org-unset">
        <strong>{label}</strong>
      </p>
      {canClose ? (
        <button className="org-button" type="button" onClick={closeNotification}>
          X<span className="org-sr">Lukk</span>
        </button>
      ) : (
        ''
      )}
    </div>
  );
}
