import { useState } from 'react';
import { IconPlus } from '../../components/icons/icons';
import Section from '../../components/section/section';
import Stack from '../../components/stack/stack';
import { ButtonAction, ButtonSave } from '../../components/button/button';
import LabelledInput from '../../components/form/labelled-input/labelled-input';
import PageContent from '../../components/page/page-content';
import ProjectListItem from './project-list-item';
import {
  createGroup,
  publishGroup,
  unpublishGroup,
  removeGroup,
  duplicateGroup,
  changeGroupName
} from '../../services/use-groups';
import SimpleModal from '../../components/modal/simple-modal';

interface Params {
  projectId: string;
  publishedGroups: Array<Midas.Group>;
  draftGroups: Array<Midas.Group>;
  finishedGroups: Array<Midas.Group>;
  createGroup: createGroup;
  publishGroup: publishGroup;
  unpublishGroup: unpublishGroup;
  duplicateGroup: duplicateGroup;
  removeGroup: removeGroup;
  changeGroupName: changeGroupName;
}

export default function ProjectList({
  projectId,
  publishedGroups,
  draftGroups,
  finishedGroups,
  createGroup,
  publishGroup,
  unpublishGroup,
  duplicateGroup,
  removeGroup,
  changeGroupName
}: Params) {
  const [groupName, setGroupName] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);

  function onAddGroup() {
    return createGroup(groupName).then(() => {
      setGroupName('');
      setShowCreateModal(false);
    });
  }

  return (
    <>
      <PageContent>
        <div className="flex gap-3 items-end mb-6">
          <ButtonAction
            variant="primary"
            onClick={() => {
              setShowCreateModal(true);
            }}
            className="flex-shrink-0"
          >
            <IconPlus className="-ml-1" />
            Ny gruppe &hellip;
          </ButtonAction>
        </div>

        <div className="grid gap-6">
          <hr className="w-full" />

          <Section className="mb-6">
            <Section.Header
              title="Publisert"
              description="Publiserte eventer er synlig for publikum"
            />
            <Section.Content>
              {publishedGroups.length > 0 && (
                <Stack>
                  {publishedGroups.map((group) => (
                    <ProjectListItem
                      key={group.id}
                      projectId={projectId}
                      group={group}
                      onUnpublish={unpublishGroup}
                      onDelete={removeGroup}
                      onDuplicate={duplicateGroup}
                      isActive={true}
                      changeGroupName={() => Promise.resolve()} // TODO: implement
                    />
                  ))}
                </Stack>
              )}
            </Section.Content>
          </Section>

          <hr className="w-full" />

          <Section className="mb-6">
            <Section.Header
              title="Upublisert"
              description="Eventer som skal publiseres eller er under arbeid"
            />
            <Section.Content>
              {draftGroups && (
                <Stack>
                  {draftGroups.map((group) => (
                    <ProjectListItem
                      key={group.id}
                      projectId={projectId}
                      group={group}
                      onDelete={removeGroup}
                      onPublish={publishGroup}
                      onDuplicate={duplicateGroup}
                      changeGroupName={changeGroupName}
                      isActive={false}
                    />
                  ))}
                </Stack>
              )}
            </Section.Content>
          </Section>

          <hr className="w-full" />

          <Section className="mb-6">
            <Section.Header
              title="Avpublisert"
              description="Avsluttede eventer. Dupliser eventer om du ønsker å bruke innhold og oppsett igjen."
            />
            <Section.Content>
              {finishedGroups && (
                <Stack>
                  {finishedGroups.map((group) => (
                    <ProjectListItem
                      key={group.id}
                      projectId={projectId}
                      group={group}
                      onDelete={removeGroup}
                      onDuplicate={duplicateGroup}
                      isActive={false}
                      changeGroupName={() => Promise.resolve()} // TODO: implement
                    />
                  ))}
                </Stack>
              )}
            </Section.Content>
          </Section>
        </div>
      </PageContent>
      {showCreateModal && (
        <SimpleModal
          className="max-w-lg"
          onClose={() => {
            setShowCreateModal(false);
          }}
        >
          <LabelledInput
            label="Navn på ny gruppe"
            className="mb-2"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <div className="flex justify-end">
            <ButtonAction
              className="mr-2"
              onClick={() => setShowCreateModal(false)}
              variant="default"
            >
              Avbryt
            </ButtonAction>
            <ButtonSave variant="primary" onSave={onAddGroup}>
              Lagre
            </ButtonSave>
          </div>
        </SimpleModal>
      )}
    </>
  );
}
