import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import ExecuteContent from './execute-content';
import EditContent from './edit-content';
import { useState } from 'react';
import usePolls from '../../services/use-polls';
import Page from '../../components/page/page';
import PageHeader from '../../components/page/page-header';
import PageContent from '../../components/page/page-content';
import PageFooter from '../../components/page/page-footer';
import { ButtonSave } from '../../components/button/button';
import useReusables from '../../services/use-reusables';
import GroupModeSwitch from './group-mode-switch';
import Preview from './preview';

interface Params {
  projectId: string;
  groupId: string;
}

export default function GroupPage() {
  const match = useRouteMatch();
  const { projectId, groupId } = useParams<Params>();

  const {
    project,
    unlock,
    isLoading,
    polls,
    addPoll,
    deletePoll,
    savePoll,
    resetPoll,
    duplicatePoll,
    saveAllPolls,
    resetAllPolls,
    setPollValue,
    reorderPolls,
    addAlternative,
    deleteAlternative,
    setAlternativeValue,
    toggleFlag,
    toggleActive,
    checkMatchedError,
    group
  } = usePolls({ projectId: Number(projectId), groupId: Number(groupId) });
  const { reusables, reusableTypes } = useReusables({ projectId: Number(projectId) });

  const [showPreview, setShowPreview] = useState(false);

  if (!project || !reusables) {
    return <div className="p-6">Laster ...</div>;
  }

  const pollTypes = project.properties.pollTypes || [];
  const breadcrumbs: Midas.BreadcrumbPath[] = [
    { path: '/', name: 'Prosjekter' },
    { path: `/projects/${projectId}`, name: project.title }
  ];

  const onTogglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <>
      <div className="flex flex-1 overflow-hidden">
        <Page>
          <PageHeader title={group?.title || ''} breadcrumbs={breadcrumbs}></PageHeader>
          <Switch>
            <Route path={`${match.url}/edit`} exact>
              <PageContent>
                <GroupModeSwitch
                  projectId={projectId}
                  groupId={groupId}
                  showPreview={showPreview}
                  onTogglePreview={onTogglePreview}
                />
                <EditContent
                  pollTypes={pollTypes}
                  unlock={unlock}
                  isLoading={isLoading}
                  reusables={reusables}
                  reusableTypes={reusableTypes}
                  polls={polls}
                  addPoll={addPoll}
                  deletePoll={deletePoll}
                  duplicatePoll={duplicatePoll}
                  savePoll={savePoll}
                  resetPoll={resetPoll}
                  setPollValue={setPollValue}
                  reorderPolls={reorderPolls}
                  addAlternative={addAlternative}
                  deleteAlternative={deleteAlternative}
                  setAlternativeValue={setAlternativeValue}
                  checkMatchedError={checkMatchedError}
                ></EditContent>
              </PageContent>
            </Route>
            <Route path={`${match.url}/execute`} exact>
              <PageContent>
                <GroupModeSwitch
                  projectId={projectId}
                  groupId={groupId}
                  showPreview={showPreview}
                  onTogglePreview={onTogglePreview}
                />
                <ExecuteContent
                  isLoading={isLoading}
                  reusables={reusables}
                  reusableTypes={reusableTypes}
                  pollTypes={pollTypes}
                  polls={polls}
                  projectId={Number(projectId)}
                  reorderPolls={reorderPolls}
                  toggleFlag={toggleFlag}
                  toggleActive={toggleActive}
                ></ExecuteContent>
              </PageContent>
              <PageFooter>
                <div className="flex gap-2 ml-auto text-sm">
                  <ButtonSave variant="secondary" onSave={resetAllPolls}>
                    Forkast endringer
                  </ButtonSave>
                  <ButtonSave variant="primary" onSave={saveAllPolls}>
                    Lagre endringer
                  </ButtonSave>
                </div>
              </PageFooter>
            </Route>
          </Switch>
        </Page>
        {showPreview && <Preview project={project} reusables={reusables} polls={polls} />}
      </div>
    </>
  );
}
