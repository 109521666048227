import { del, get, patch, post, put } from '../utils/http';
import { getApiServer } from '../config/config';

export function getProjects(): Promise<Array<Midas.Project>> {
  return get(`${getApiServer()}/projects`);
}

export function getProject(id: number): Promise<Midas.Project> {
  return get(`${getApiServer()}/projects/${id}`);
}

export function createProject(data: Midas.Project) {
  return post(`${getApiServer()}/projects`, data);
}

export function updateProject(data: Midas.Project) {
  const { id } = data;
  return put(`${getApiServer()}/projects/${id}`, data);
}

export function deleteProject(projectId: number) {
  return del(`${getApiServer()}/projects/${projectId}`);
}

export function changeProjectTitle(projectId: number, title: string) {
  return patch(`${getApiServer()}/projects/${projectId}`, { title: title });
}
