import React, { Component } from 'react';

interface Props {
  onSelect: Function;
  className: string;
}

export class EmojiPicker extends Component<Props> {
  private emojiRef: any;
  private onSelect;
  private className;

  constructor(props: Props) {
    super(props);
    this.emojiRef = React.createRef();

    this.onEmojiSelect = this.onEmojiSelect.bind(this);

    this.onSelect = props.onSelect;
    this.className = props.className || '';
  }

  componentDidMount() {
    this.emojiRef.current.addEventListener('emoji-click', this.onEmojiSelect);
  }

  onEmojiSelect(event: any) {
    this.onSelect(event.detail);
  }

  componentWillUnmount() {
    this.emojiRef.current.removeEventListener('emoji-click', this.onEmojiSelect);
  }

  render() {
    return (
      <div className={this.className}>
        <emoji-picker ref={this.emojiRef}></emoji-picker>
      </div>
    );
  }
}
