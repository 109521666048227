import { ButtonAction } from '../../../components/button/button';
import Expand from '../../../components/expand/expand';
import ExpandButton from '../../../components/expand/expand-button';
import LabelledInput from '../../../components/form/labelled-input/labelled-input';
import LabelledSwitch from '../../../components/form/labelled-switch/labelled-switch';
import { IconChevronClosed, IconGarbage } from '../../../components/icons/icons';
import MoreDropdown from '../../../components/more-dropdown/more-dropdown';
import { removeFlagType, updateFlagType } from '../../../services/use-project';

interface Props {
  pollTypeId: number;
  onChange: updateFlagType;
  onDelete: removeFlagType;
  flag: Midas.FlagType;
  checkMatchedError: (path: Array<string | number>) => boolean;
}

export default function ProjectConfigFlag({
  pollTypeId,
  onChange,
  onDelete,
  flag,
  checkMatchedError
}: Props) {
  const preparedOnChange = (path: string) => {
    return (e: any) => {
      onChange(pollTypeId, flag.id, path, e.target.value);
    };
  };
  const preparedOnChangeSwitch = (path: string) => {
    return (e: any) => {
      onChange(pollTypeId, flag.id, path, e.target.checked);
    };
  };

  return (
    <div>
      <div className="relative">
        <ExpandButton
          expandKey={`flag-${flag.id}`}
          className="py-2 px-4 w-full bg-white text-left rounded shadow flex items-center gap-1 relative z-10"
        >
          <div className="flex items-center">
            <IconChevronClosed className="mid-expand-indicator" />
          </div>
          <div className="grid text-sm">
            <strong className="font-semibold">{flag.title || 'Nytt flagg'}</strong>
            <span className="opacity-80">{flag.variableName || 'Nytt flagg'}</span>
          </div>
        </ExpandButton>
        <div className="absolute right-0 inset-y-0 flex items-center px-3">
          <MoreDropdown dropdownKey="flag-options-1">
            <ButtonAction variant="secondary" onClick={() => onDelete(pollTypeId, flag.id)}>
              <IconGarbage className="-ml-1" />
              Slett &hellip;
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      <Expand expandKey={`flag-${flag.id}`}>
        <div className="pt-6 pb-8 px-10 bg-gray-300 rounded-b mb-6">
          <div className="grid gap-6">
            <div className="flex gap-3">
              <LabelledInput
                label="Tittel"
                value={flag.title}
                onChange={preparedOnChange('title')}
                className="flex-1"
                isInvalid={checkMatchedError(['title'])}
                warning="Tittel må være satt"
              />
              <LabelledInput
                label="Variabel"
                value={flag.variableName}
                onChange={preparedOnChange('variableName')}
                className="flex-1"
                isInvalid={checkMatchedError(['variableName'])}
                warning="Variabel må være satt"
              />
            </div>
            <LabelledSwitch
              label="Tilstand"
              checked={flag.defaultValue}
              onChange={preparedOnChangeSwitch('defaultValue')}
            />
          </div>
        </div>
      </Expand>
    </div>
  );
}
