import { del, get, put, patch, post } from '../utils/http';
import { getApiServer } from '../config/config';

export function getGroups(projectId: number): Promise<Array<Midas.Group>> {
  return get(`${getApiServer()}/projects/${projectId}/groups`);
}

export function getGroup(projectId: number, groupId: number): Promise<Midas.Group> {
  return get(`${getApiServer()}/projects/${projectId}/groups/${groupId}`);
}

export function createGroup(projectId: number, group: Midas.Group): Promise<Midas.Group> {
  return post(`${getApiServer()}/projects/${projectId}/groups`, group);
}

export function updateGroup(projectId: number, group: Midas.Group): Promise<Midas.Group> {
  return put(`${getApiServer()}/projects/${projectId}/groups/${group.id}`, group);
}

export function deleteGroup(projectId: number, groupId: number): Promise<void> {
  return del(`${getApiServer()}/projects/${projectId}/groups/${groupId}`);
}

export function setGroupName(projectId: number, groupId: number, name: string): Promise<void> {
  return patch(`${getApiServer()}/projects/${projectId}/groups/${groupId}`, { title: name });
}
