import Expand from '../../../components/expand/expand';
import ExpandButton from '../../../components/expand/expand-button';
import LabelledInput from '../../../components/form/labelled-input/labelled-input';

import ReusableFieldConfig from './project-config-reusable-field';
import { IconChevronClosed, IconGarbage, IconPlus } from '../../../components/icons/icons';
import { ButtonAction } from '../../../components/button/button';
import Stack from '../../../components/stack/stack';
import MoreDropdown from '../../../components/more-dropdown/more-dropdown';
import {
  removeReusableType,
  addField,
  removeField,
  updateReusableType,
  updateReusableTypeField,
  checkMatchedError,
  getErrorMessage
} from '../../../services/use-project';

interface Props {
  reusableType: Midas.ReusableType;
  removeReusableType: removeReusableType;
  addField: addField;
  removeField: removeField;
  updateReusableType: updateReusableType;
  updateReusableTypeField: updateReusableTypeField;
  checkMatchedError: checkMatchedError;
  getErrorMessage: getErrorMessage;
  index: number;
}

export default function ReusableConfig({
  reusableType,
  removeReusableType,
  addField,
  removeField,
  updateReusableType,
  updateReusableTypeField,
  index,
  checkMatchedError,
  getErrorMessage
}: Props) {
  function wrappedOnChange(resTypeId: number, path: string) {
    return (e: any) => {
      updateReusableType(resTypeId, path, e.target.value);
    };
  }

  function wrappedCheckError(path: Array<string | number>) {
    return function (restOfPath: Array<string | number>) {
      return checkMatchedError([...path, ...restOfPath]);
    };
  }

  function wrappedGetErrorMessage(path: Array<string | number>) {
    return function (restOfPath: Array<string | number>) {
      return getErrorMessage([...path, ...restOfPath]);
    };
  }

  return (
    <div className="relative">
      <div className="relative">
        <ExpandButton
          expandKey={`reusable-${reusableType.id}`}
          className="py-2 px-4 w-full bg-white text-left rounded shadow flex items-center gap-1 relative z-10"
          isExpanded={false}
        >
          <div className="flex items-center">
            <IconChevronClosed className="mid-expand-indicator" />
          </div>
          <div className="grid text-sm">
            <strong className="font-semibold">{reusableType.title || 'Mangler tittel'}</strong>
            <span className="opacity-80">{reusableType.variableName || 'Mangler variabel'}</span>
          </div>
        </ExpandButton>
        <div className="absolute right-0 inset-y-0 flex items-center px-3 z-10">
          <MoreDropdown dropdownKey={`reusable-more-${reusableType.id}`}>
            <ButtonAction
              variant="secondary"
              onClick={() => {
                removeReusableType(reusableType.id);
              }}
            >
              <IconGarbage className="-ml-1" />
              Slett &hellip;
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      <Expand expandKey={`reusable-${reusableType.id}`}>
        <div className="pt-5 pb-8 px-5 sm:px-10 bg-gray-200 rounded-b mb-6">
          <div className="grid gap-6">
            <div className="grid grid-flow-col gap-3">
              <LabelledInput
                label="Tittel"
                value={reusableType.title}
                onChange={wrappedOnChange(reusableType.id, 'title')}
                isInvalid={checkMatchedError(['properties', 'reusableTypes', index, 'title'])}
                warning={getErrorMessage(['properties', 'reusableTypes', index, 'title'])}
              />
              <LabelledInput
                label="Variabel"
                value={reusableType.variableName}
                onChange={wrappedOnChange(reusableType.id, 'variableName')}
                isInvalid={checkMatchedError([
                  'properties',
                  'reusableTypes',
                  index,
                  'variableName'
                ])}
                warning={getErrorMessage([
                  'properties',
                  'reusableTypes',
                  index,
                  'variableName'
                ])}
              />
            </div>
            <LabelledInput
              label="Beskrivelse"
              onChange={wrappedOnChange(reusableType.id, 'description')}
              value={reusableType.description}
              isInvalid={checkMatchedError([
                'properties',
                'reusableTypes',
                index,
                'description'
              ])}
              warning={getErrorMessage([
                'properties',
                'reusableTypes',
                index,
                'description'
              ])}
            />

            <div className="grid gap-2">
              <div className="text-sm font-semibold">Felter</div>
              <Stack>
                {reusableType.fields.map((field, i) => {
                  return (
                    <ReusableFieldConfig
                      key={field.id}
                      field={field}
                      reusableTypeId={reusableType.id}
                      removeField={removeField}
                      updateReusableTypeField={updateReusableTypeField}
                      checkMatchedError={wrappedCheckError([
                        'properties',
                        'reusableTypes',
                        index,
                        'fields',
                        i
                      ])}
                      getErrorMessage={wrappedGetErrorMessage([
                        'properties',
                        'reusableTypes',
                        index,
                        'fields',
                        i
                      ])}
                    />
                  );
                })}
              </Stack>
              <div className="flex justify-between items-center">
                <ButtonAction
                  variant="suggestive"
                  onClick={() => {
                    addField(reusableType.id);
                  }}
                >
                  <IconPlus className="-ml-1 mr-auto" />
                  Nytt felt
                </ButtonAction>
              </div>
            </div>
          </div>
        </div>
      </Expand>
    </div>
  );
}
