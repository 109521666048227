import EmojiSelect from '../../components/form/emoji-select/emoji-select';
import { IconClose } from '../../components/icons/icons';

export default function AlternativeEmoji({
  alternative,
  onChange,
  onDelete,
  disabled,
  checkMatchedError
}: Admin.AlternativeEmojiProps) {
  return (
    <div className="flex items-end">
      <EmojiSelect
        label="Emoji"
        description="Emoji"
        className="max-w-sm"
        value={alternative.text}
        onChange={onChange(alternative.id, 'text')}
        disabled={disabled}
        isInvalid={checkMatchedError(['text'])}
        warning="Emoji må være satt"
      />
      <button
        className="py-2 px-2 inline-flex items-center disabled:opacity-60 disabled:cursor-not-allowed"
        disabled={disabled}
        onClick={onDelete}
        aria-label="Slett alternativ"
      >
        <IconClose />
      </button>
    </div>
  );
}
