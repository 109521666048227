import Joi from 'joi';

const altEmoji = Joi.object({
  id: Joi.number().required(),
  index: Joi.number().required(),
  text: Joi.string().required(),
  type: Joi.string().valid('EMOJI'),
  reusableId: Joi.any(),
  media: Joi.any(),
  properties: Joi.any()
});

const altText = Joi.object({
  id: Joi.number().required(),
  index: Joi.number().required(),
  text: Joi.string().required(),
  type: Joi.string().valid('TEXT'),
  reusableId: Joi.any(),
  media: Joi.any(),
  properties: Joi.any()
});

const altImage = Joi.object({
  id: Joi.number().required(),
  index: Joi.number().required(),
  text: Joi.string().allow(''),
  type: Joi.string().valid('IMAGE'),
  reusableId: Joi.any(),
  media: Joi.any(),
  properties: Joi.object({
    src: Joi.string().required(),
    kaleidoId: Joi.string().required(),
    params: Joi.any(),
    alt: Joi.string().required()
  }).required()
});

const altReusable = Joi.object({
  id: Joi.number().required(),
  index: Joi.number().required(),
  text: Joi.string().allow(''),
  reusableId: Joi.number().required(),
  type: Joi.string().required(),
  media: Joi.any(),
  properties: Joi.any()
});

export function createSchema(type: string) {
  let selectedAlt: any;

  if (type === 'IMAGE') {
    selectedAlt = altImage;
  } else if (type === 'EMOJI') {
    selectedAlt = altEmoji;
  } else if (type === 'TEXT') {
    selectedAlt = altText;
  } else {
    selectedAlt = altReusable;
  }

  return Joi.object({
    id: Joi.number(),
    index: Joi.number(),
    text: Joi.string(),
    published: Joi.boolean(),
    active: Joi.boolean(),
    presentation: Joi.string(),
    reusableId: Joi.number().allow(''),
    flags: Joi.array().items(
      Joi.object({
        name: Joi.string().required(),
        value: Joi.boolean().required()
      })
    ),
    rules: Joi.any(),
    properties: Joi.any(),
    updated: Joi.string(),
    alternatives: Joi.array().items(selectedAlt)
  });
}

export const PollSchemaText = createSchema('TEXT');
export const PollSchemaImage = createSchema('IMAGE');
export const PollSchemaEmoji = createSchema('EMOJI');
