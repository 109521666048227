import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconGarbage, IconEdit } from '../../components/icons/icons';
import { ButtonAction, ButtonSave } from '../../components/button/button';
import MoreDropdown from '../../components/more-dropdown/more-dropdown';
import ConfirmModal from '../../components/modal/confirm-modal';
import SimpleModal from '../../components/modal/simple-modal';
import LabelledInput from '../../components/form/labelled-input/labelled-input';

interface Props {
  project: Midas.Project;
  onDelete: (id: number) => Promise<void>;
  changeProjectName: (projectId: number, name: string) => Promise<any>;
}

export default function ProjectsItem({ project, onDelete, changeProjectName }: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [itemName, setItemName] = useState(project.title);

  function onRemoveProject() {
    return onDelete(project.id).then(() => {
      setShowDeleteModal(false);
    });
  }

  function saveNameChange() {
    return changeProjectName(project.id, itemName);
  }

  return (
    <>
      <div key={project.id} className="relative bg-white rounded shadow min-h-12 flex">
        <Link
          key={project.id}
          className="px-3 py-2 w-full flex items-center"
          to={'/projects/' + project.id}
        >
          <div className="grid">
            <div className="text-sm font-semibold">{project.title}</div>
            <div className="opacity-80 uppercase">Id {project.id}</div>
          </div>
        </Link>
        <div className="absolute right-0 inset-y-0 flex items-center pr-3">
          <MoreDropdown dropdownKey={`id-${project.id}`}>
            <ButtonAction
              className="items-start whitespace-nowrap"
              onClick={() => {
                setShowChangeName(true);
              }}
              variant="secondary"
            >
              <IconEdit className="-ml-1" />
              <span>Endre navn &hellip;</span>
            </ButtonAction>
            <ButtonAction
              className="items-start whitespace-nowrap"
              onClick={() => {
                setShowDeleteModal(true);
              }}
              variant="secondary"
            >
              <IconGarbage className="-ml-1" />
              <span>Slett prosjekt &hellip;</span>
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmModal
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={onRemoveProject}
          confirm="Slett"
        >
          Er du sikker på at du vil slette?
        </ConfirmModal>
      )}
      {showChangeName && (
        <SimpleModal
          className="max-w-lg"
          onClose={() => {
            setShowChangeName(false);
          }}
        >
          <LabelledInput
            label="Navn på prosjekt"
            className="mb-2"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          <div className="flex justify-end">
            <ButtonAction
              className="mr-2"
              onClick={() => setShowChangeName(false)}
              variant="default"
            >
              Avbryt
            </ButtonAction>
            <ButtonSave variant="primary" onSave={saveNameChange}>
              Lagre
            </ButtonSave>
          </div>
        </SimpleModal>
      )}
    </>
  );
}
