import React from 'react';
import { toggleFlag, toggleActive, reorderPolls } from '../../services/use-polls';
import ExecuteItem from './execute-item';
import ExecuteDrop from './execute-drop';

interface Props {
  isLoading: boolean;
  reusables: Array<Midas.Reusable>;
  reusableTypes: Array<Midas.ReusableType>;
  pollTypes: Array<Midas.PollType>;
  toggleFlag: toggleFlag;
  toggleActive: toggleActive;
  projectId: number;
  polls: Array<Midas.Poll>;
  reorderPolls: reorderPolls;
}
export default function ExecuteContent({
  isLoading,
  reusables,
  reusableTypes,
  reorderPolls,
  pollTypes,
  projectId,
  polls,
  toggleFlag,
  toggleActive
}: Props) {
  if (isLoading) {
    return <div className="p-6">Laster...</div>;
  }

  const onToggleVotes = (pollId: number) => {
    return () => {
      toggleActive(pollId);
    };
  };

  function onDrop(item: any, monitor: any, index: number) {
    reorderPolls(item.id, index);
  }

  return (
    <div className="grid gap-3">
      {/*<div className="flex gap-2">
        <button className="px-4 py-1 text-xs font-medium text-white rounded-full shadow bg-dark">
          Ekspander alle
        </button>
        <button className="px-4 py-1 text-xs font-medium text-white rounded-full shadow bg-dark">
          Minimer alle
        </button>
      </div>*/}
      <div>
        {polls?.map((poll, i) => {
          const pollType = pollTypes.find((pt) => pt.variableName === poll.presentation);
          if (!pollType) {
            return undefined;
          }
          return (
            <React.Fragment key={poll.id}>
              <ExecuteDrop onDrop={onDrop} index={i} />
              <ExecuteItem
                projectId={projectId}
                poll={poll}
                pollType={pollType}
                reusables={reusables}
                reusableTypes={reusableTypes}
                onToggleVotes={onToggleVotes}
                toggleFlag={toggleFlag}
              />
            </React.Fragment>
          );
        })}
        <ExecuteDrop onDrop={onDrop} index={polls.length} />
      </div>
    </div>
  );
}
