import classNames from 'classnames';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function Container({ className, children }: Props) {
  const containerClass = classNames('mx-auto max-w-screen-xl w-full sm:w-11/12', className);
  return <div className={containerClass}>{children}</div>;
}
