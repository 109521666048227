export default function AlternativeEmoji({
  alternative,
  points
}: Admin.ExecuteAlternativeEmojiProps) {
  return (
    <>
      <dt className="text-sm font-semibold">{alternative.text}</dt>
      <dd className="text-lg">{points || 0}</dd>
    </>
  );
}
