import { useEffect, useState } from 'react';
import * as api from '../api/project';
import { changeProjectTitle } from './projects';

export type changeProjectName = (projectId: number, title: string) => Promise<any>;
export type deleteProject = (projectId: number) => Promise<any>;

export default function useProjects() {
  const [projects, setProjects] = useState<Array<Midas.Project>>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    api.getProjects().then((items) => {
      setIsLoaded(true);
      setProjects(items);
    });
  }, []);

  const changeProjectName: changeProjectName = (projectId, title) => {
    return changeProjectTitle(projectId, title).then(() => {
      setProjects(
        projects.map((project) =>
          project.id === projectId ? { ...project, title: title } : project
        )
      );
    });
  };

  const deleteProject: deleteProject = (projectId) => {
    return api.deleteProject(projectId).then(() => {
      setProjects(projects.filter((project) => project.id !== projectId));
    });
  };

  return {
    projects,
    isLoaded,
    changeProjectName,
    deleteProject
  };
}
