import { Link } from 'react-router-dom';
import Expand from '../components/expand/expand';
import ExpandButton from '../components/expand/expand-button';
import { ButtonLink } from './button/button';
import { IconChevronOpen, IconHelp } from './icons/icons';
import Logo from './logo/logo';

function Masthead({ name }: Props) {
  return (
    <header className="bg-dark text-white py-2 px-5 flex w-full items-center justify-between">
      <Link className="text-2xl flex items-center font-light" to="/">
        <Logo className="mr-2" /> Midas
      </Link>
      <nav className="text-sm flex items-center gap-2">
        {/* <ButtonLink variant="secondary" to="/settings">
          Settings
        </ButtonLink> */}
        <div className="relative">
          <ExpandButton
            expandKey="user-menu"
            className="text-sm font-semibold flex gap-2 py-2.5 px-3 rounded hover:bg-gray-400 hover:bg-opacity-10"
          >
            {name}
            <IconChevronOpen className="-mr-1" />
          </ExpandButton>
          <Expand expandKey="user-menu">
            <div className="absolute z-20 p-1 top-full right-0 bg-white rounded shadow-lg text-dark">
              <ButtonLink variant="secondary" to="/">
                Logg ut
              </ButtonLink>
            </div>
            <ExpandButton
              expandKey="user-menu"
              className="fixed inset-0 w-full bg-dark bg-opacity-5 z-10"
            >
              <span className="sr-only">Lukk dropdown-meny</span>
            </ExpandButton>
          </Expand>
        </div>
        <div className="relative">
          <ExpandButton
            expandKey="help-menu"
            className="text-sm py-2.5 px-3 -my-1 -mr-1 rounded hover:bg-gray-400 hover:bg-opacity-10 grid place-items-center"
          >
            <IconHelp />
            <span className="sr-only">Hjelp</span>
          </ExpandButton>
          <Expand expandKey="help-menu">
            <div className="absolute z-20 p-1 top-full right-0 bg-white rounded shadow-lg text-dark w-max max-w-60 grid gap-1">
              <p className="py-2 px-4">
                Er det noe du lurer på, eller ønsker gi tilbakemelding på?
              </p>
              <div className="h-px bg-gray-300 mx-1 my-1"></div>
              <div className="grid">
                <a
                  className="py-2.5 px-4 inline-flex gap-2 rounded text-sm font-semibold disabled:opacity-60 disabled:cursor-not-allowed hover:bg-gray-600 hover:bg-opacity-10"
                  target="_blank"
                  rel="noreferrer"
                  href="https://confluence.nrk.no/display/nettinrk/Avstemninger+i+Midas"
                >
                  Brukerveiledning
                </a>
                <a
                  className="py-2.5 px-4 inline-flex gap-2 rounded text-sm font-semibold disabled:opacity-60 disabled:cursor-not-allowed hover:bg-gray-600 hover:bg-opacity-10"
                  target="_blank"
                  rel="noreferrer"
                  href="https://nrk.workplace.com/groups/1120157244843914"
                >
                  Workplace
                </a>
                <a
                  className="py-2.5 px-4 inline-flex gap-2 rounded text-sm font-semibold disabled:opacity-60 disabled:cursor-not-allowed hover:bg-gray-600 hover:bg-opacity-10"
                  target="_blank"
                  rel="noreferrer"
                  href="https://nrk.slack.com/archives/C1H44N63X"
                >
                  Slack (#midas)
                </a>
              </div>
              <div className="h-px bg-gray-300 mx-1 my-1"></div>
              <div className="grid">
                <a
                  className="py-2.5 px-4 inline-flex gap-2 rounded text-sm font-semibold disabled:opacity-60 disabled:cursor-not-allowed hover:bg-gray-600 hover:bg-opacity-10"
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/nrkno/pin-midas-v3"
                >
                  GitHub
                </a>
              </div>
            </div>
            <ExpandButton
              expandKey="help-menu"
              className="fixed inset-0 w-full bg-dark bg-opacity-5 z-10"
            >
              <span className="sr-only">Lukk dropdown-meny</span>
            </ExpandButton>
          </Expand>
        </div>
      </nav>
    </header>
  );
}

interface Props {
  name: string;
}

export default Masthead;
