import Section from '../../../components/section/section';
import ReusableConfig from './project-config-reusable';
import PollConfig from './project-config-poll';
import { ButtonAction } from '../../../components/button/button';
import Stack from '../../../components/stack/stack';
import { IconPlus } from '../../../components/icons/icons';
import {
  addReusableType,
  removeReusableType,
  addField,
  removeField,
  addPollType,
  removePollType,
  updatePollType,
  updateRuleType,
  updateReusableType,
  updateReusableTypeField,
  addFlagType,
  removeFlagType,
  updateFlagType,
  checkMatchedError,
  getErrorMessage
} from '../../../services/use-project';
import LabelledInput from '../../../components/form/labelled-input/labelled-input';
import LabelledRadio from '../../../components/form/labelled-radio/labelled-radio';
import LabelledInputNumber from '../../../components/form/labelled-input-number/labelled-input-number';

const EXECUTION_TYPES = {
  SERIE: 'SERIE',
  SINGLE: 'SINGLE'
};

interface Props {
  project?: Midas.Project;
  addReusableType: addReusableType;
  removeReusableType: removeReusableType;
  addField: addField;
  removeField: removeField;
  addPollType: addPollType;
  removePollType: removePollType;
  updatePollType: updatePollType;
  updateRuleType: updateRuleType;
  updateReusableType: updateReusableType;
  updateReusableTypeField: updateReusableTypeField;
  addFlagType: addFlagType;
  removeFlagType: removeFlagType;
  updateFlagType: updateFlagType;
  updateValue: (path: string) => (e: any) => void;
  checkMatchedError: checkMatchedError;
  getErrorMessage: getErrorMessage;
}

export default function ProjectConfig({
  project,
  addReusableType,
  removeReusableType,
  addField,
  removeField,
  addPollType,
  removePollType,
  updatePollType,
  updateRuleType,
  updateReusableType,
  updateReusableTypeField,
  addFlagType,
  removeFlagType,
  updateFlagType,
  updateValue,
  checkMatchedError,
  getErrorMessage
}: Props) {
  if (!project) {
    return <div className="p-6">Laster...</div>;
  }

  return (
    <>
      <Section className="mb-8">
        <Section.Header
          title="Innstillinger"
          description="Feltene du må fylle ut for sette opp en avstemning"
        />

        <Section.Content>
          <div className="bg-white rounded shadow px-8 pb-8 pt-7">
            <div className="grid gap-6">
              <LabelledInput
                label="URL til avstemningsklienten"
                value={project.properties?.clientURL}
                onChange={updateValue('properties.clientURL')}
                isInvalid={checkMatchedError(['properties', 'clientURL'])}
                warning="Klient URL er feil"
              />
              <LabelledInputNumber
                label="Maks antall publiserte grupper"
                min={1}
                value={project.maxPublishedGroups}
                onChange={updateValue('maxPublishedGroups')}
                isInvalid={checkMatchedError(['maxPublishedGroups'])}
                warning={getErrorMessage(['maxPublishedGroups'])}
              />
              <fieldset>
                <legend className="text-sm font-semibold mb-1">Avstemningstype</legend>
                <div className="flex flex-col flex-1 items-start gap-2">
                  <LabelledRadio
                    label="Ett spørsmål av gangen"
                    name="execution-type"
                    value={EXECUTION_TYPES.SINGLE}
                    checked={project.properties.executionType === EXECUTION_TYPES.SINGLE}
                    onChange={updateValue('properties.executionType')}
                  />
                  <LabelledRadio
                    label="Alle spørsmål åpne samtidig"
                    name="execution-type"
                    value={EXECUTION_TYPES.SERIE}
                    checked={project.properties.executionType === EXECUTION_TYPES.SERIE}
                    onChange={updateValue('properties.executionType')}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </Section.Content>
      </Section>

      <Section>
        <Section.Header
          title="Delt innhold"
          description="Gjør det enklere å gjenbruke biter med innhold, uten å redigere flere steder"
        />
        <Section.Content>
          <Stack>
            {project.properties?.reusableTypes?.map((reusableType, i) => {
              return (
                <ReusableConfig
                  key={reusableType.id}
                  index={i}
                  reusableType={reusableType}
                  updateReusableType={updateReusableType}
                  updateReusableTypeField={updateReusableTypeField}
                  removeReusableType={removeReusableType}
                  addField={addField}
                  removeField={removeField}
                  checkMatchedError={checkMatchedError}
                  getErrorMessage={getErrorMessage}
                />
              );
            })}
          </Stack>
          <ButtonAction
            variant="suggestive"
            onClick={() => {
              addReusableType();
            }}
          >
            <IconPlus className="-ml-1" />
            Ny innholdstype
          </ButtonAction>
        </Section.Content>
      </Section>

      <hr className="w-full my-8 bg-gray-300" />

      <Section>
        <Section.Header
          title="Interaksjonstyper"
          description="Gjør det enklere å tilpasse ulike visninger i publikumsvisningen"
        />
        <Section.Content>
          <Stack>
            {project.properties?.pollTypes.map((pollType, i) => {
              return (
                <PollConfig
                  key={pollType.id}
                  index={i}
                  pollType={pollType}
                  removePollType={removePollType}
                  reusableTypes={project.properties.reusableTypes}
                  updatePollType={updatePollType}
                  updateRuleType={updateRuleType}
                  addFlagType={addFlagType}
                  removeFlagType={removeFlagType}
                  updateFlagType={updateFlagType}
                  checkMatchedError={checkMatchedError}
                  getErrorMessage={getErrorMessage}
                />
              );
            })}
          </Stack>
          <ButtonAction
            variant="suggestive"
            onClick={() => {
              addPollType();
            }}
          >
            <IconPlus className="-ml-1" />
            Ny interaksjonstype
          </ButtonAction>
        </Section.Content>
      </Section>
    </>
  );
}
