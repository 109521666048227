import { Component } from 'react';

interface Props {
  expandKey: string;
  isExpanded?: boolean;
  className?: string;
}

interface State {
  isExpanded: boolean;
}

export default class ExpandButton extends Component<Props, State> {
  id = '';
  ariaControls = '';

  constructor(props: Props) {
    super(props);
    this.id = `${props.expandKey}-button`;
    this.ariaControls = `${props.expandKey}-content`;
    this.state = { isExpanded: props.isExpanded || false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('toggle.select', this.handleToggle);
  }

  componentWillUnmount() {
    document.removeEventListener('toggle.select', this.handleToggle);
  }

  handleToggle(e: any) {
    if (e.detail && e.detail.id === this.ariaControls) {
      this.setState({ isExpanded: e.detail.isExpanded });
    }
  }

  render() {
    return (
      <button
        id={this.id}
        aria-expanded={this.state.isExpanded}
        aria-controls={this.ariaControls}
        className={this.props.className}
      >
        {this.props.children}
      </button>
    );
  }
}
