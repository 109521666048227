import Joi from 'joi';
import * as api from '../api/polls';
import { createSchema } from '../validation/schemas/poll';

export function getPoll(projectId: number, interactionId: number) {
  return api.getPoll(projectId, interactionId);
}

export function getPollByGroup(projectId: number, groupId: number) {
  return api.getPollByGroup(projectId, groupId).then((polls) => {
    if (!polls) {
      return [];
    }
    const pollIds = polls.map((interaction: any) => interaction.id);
    return Promise.all(
      pollIds.map((pollId: number) => api.getAlternatives(projectId, pollId))
    ).then((alternatives) => {
      return pollIds.map((pollId: number, i: number) => {
        return {
          ...polls[i],
          alternatives: alternatives[i]
        } as Midas.Poll;
      });
    });
  });
}

export function validatePoll(poll: Midas.Poll): Promise<Joi.ValidationError | Midas.Poll> {
  // Get Alternative type
  const firstAlt = poll.alternatives[0];
  const altType = firstAlt?.type;

  const Schema = createSchema(altType);

  const { error } = Schema.validate(poll, { abortEarly: false });
  if (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(poll);
}

export function saveReOrder(projectId: number, polls: Array<Midas.Poll>) {
  return Promise.all(
    polls.map((poll) => {
      return api.patchPoll(projectId, { id: poll.id, index: poll.index });
    })
  );
}

export function addPoll(projectId: number, poll: Midas.Poll) {
  return api.createPoll(projectId, poll);
}

export function updatePoll(projectId: number, interaction: Midas.Poll) {
  return api.updatePoll(projectId, interaction);
}

export function deletePoll(projectId: number, pollId: number) {
  return api.deletePoll(projectId, pollId);
}
