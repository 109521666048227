import Page from '../../components/page/page';
import PageHeader from '../../components/page/page-header';
import PageContent from '../../components/page/page-content';
import { IconPlus } from '../../components/icons/icons';
import { ButtonLink } from '../../components/button/button';
import Stack from '../../components/stack/stack';
import ProjectsItem from './projects-item';
import useProjects from '../../services/use-projects';

export default function ProjectsPage() {
  const { projects, isLoaded, changeProjectName, deleteProject } = useProjects();

  function removeProject(projectId: number) {
    // TODO: Show modal
    return deleteProject(projectId);
  }

  return (
    <>
      <Page>
        <PageHeader title="Prosjekter"></PageHeader>
        <PageContent>
          <div className="lg:flex gap-12">
            <div className="mb-6 lg:w-1/4">
              <ButtonLink className="w-full max-w-56" variant="primary" to={'/projects/create'}>
                Nytt prosjekt
                <IconPlus className="-mr-1 ml-auto" />
              </ButtonLink>
            </div>
            <div className="flex-1">
              <div className="mb-6">
                <input
                  type="text"
                  className="rounded py-2 px-3 border border-gray-400 font-medium w-full text-sm invalid:border-yellow-400 invalid:shadow-none"
                  placeholder="Finn prosjekt &hellip;"
                />
              </div>
              <Stack>
                {isLoaded ? (
                  projects.map((project) => {
                    return (
                      <ProjectsItem
                        key={project.id}
                        project={project}
                        onDelete={removeProject}
                        changeProjectName={changeProjectName}
                      />
                    );
                  })
                ) : (
                  <span>Laster...</span>
                )}
              </Stack>
            </div>
          </div>
        </PageContent>
      </Page>
    </>
  );
}
