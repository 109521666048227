import classNames from 'classnames';
import FormProps from '../form-props';

interface Props extends FormProps {
  value?: any;
  isInvalid?: boolean;
  checked?: boolean;
  onChange?: (e: any) => void;
}

export default function LabelledRadio({
  name,
  className,
  label,
  value,
  checked,
  disabled,
  isInvalid,
  onChange
}: Props) {
  const labelClassName = classNames('inline-flex items-center', className);

  return (
    <label className={labelClassName}>
      <input
        key={value}
        type="radio"
        value={value}
        name={name}
        className="org-input"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div className="ml-1 text-sm">{label}</div>
    </label>
  );
}
