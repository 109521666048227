import classNames from 'classnames';
import { ReactNode } from 'react';

function Tile({ className, children }: Props) {
  const tileClass = classNames(
    'bg-white py-2 px-3 shadow rounded min-h-12 flex items-center',
    className
  );
  return <div className={tileClass}>{children}</div>;
}

interface Props {
  className?: string;
  children: ReactNode;
}

export default Tile;
