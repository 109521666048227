import * as api from './groups';
import { useEffect, useState } from 'react';
import { deepClone } from '../utils/obj';

interface Props {
  projectId: number;
}

export type createGroup = (title: string) => Promise<void>;
export type removeGroup = (groupId: number) => Promise<void>;
export type publishGroup = (groupId: number) => Promise<void>;
export type unpublishGroup = (groupId: number) => Promise<void>;
export type changeGroupName = (groupId: number, name: string) => Promise<void>;
export type duplicateGroup = (groupId: number) => Promise<void>;

type setGroupState = (groupId: number, state: Midas.GroupState) => Promise<void>;

export default function useGroups({ projectId }: Props) {
  const [groups, setGroups] = useState<Array<Midas.Group>>([]);

  useEffect(() => {
    api.getGroups(projectId).then((data) => {
      setGroups(data);
    });
  }, [projectId]);

  const createGroup: createGroup = function (title) {
    return api.addGroup(projectId, title).then((data) => {
      setGroups([...groups, data]);
    });
  };

  const removeGroup: removeGroup = function (groupId) {
    return api.deleteGroup(projectId, groupId).then(() => {
      setGroups(groups.filter((group) => group.id !== groupId));
    });
  };

  const publishGroup: publishGroup = function (groupId) {
    return setPublishedState(groupId, 'PUBLISHED');
  };

  const unpublishGroup: unpublishGroup = function (groupId) {
    return setPublishedState(groupId, 'ARCHIVED');
  };

  const setPublishedState: setGroupState = function (groupId, state) {
    const group = groups.find((g) => groupId === g.id);
    if (group) {
      group.state = state;
      return api.updateGroup(projectId, group).then((updatedGroup) => {
        setGroups(
          groups.map((group) => {
            if (group.id === updatedGroup.id) {
              return updatedGroup;
            }
            return group;
          })
        );
      });
    }
    return Promise.reject(`Unable to find group with id: ${groupId}`);
  };

  const duplicateGroup: duplicateGroup = (groupId) => {
    const group = groups.find((g) => groupId === g.id);
    if (group) {
      const duplicate: Midas.Group = deepClone(group, {
        skip: ['publishTime', 'status'],
        skipSpecific: ['id', 'interactions.id', 'interactions.data.id']
      });

      duplicate.state = 'DRAFT';
      duplicate.title = `KOPI: ${duplicate.title}`;

      return api.createGroup(projectId, duplicate).then((result) => {
        setGroups([...groups, result]);
      });
    }
    return Promise.resolve();
  };

  const changeGroupName: changeGroupName = function (groupId, name) {
    return api.setGroupName(projectId, groupId, name).then(() => {
      setGroups(
        groups.map((group) => {
          return group.id === groupId
            ? {
                ...group,
                title: name
              }
            : group;
        })
      );
    });
  };

  return {
    groups,
    createGroup,
    removeGroup,
    publishGroup,
    unpublishGroup,
    duplicateGroup,
    changeGroupName
  };
}
