import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getName, initAuth } from './api/auth';
import Masthead from './components/masthead';
import ProjectsPage from './views/projects/projects-page';
import CreateProjectPage from './views/project/create-project-page';
import ProjectPage from './views/project/project-page';
import Container from './components/container/container';
import GroupPage from './views/group/group-page';
import kaleido from '@nrk/kaleido-ui';
import 'emoji-picker-element';

function App() {
  const [name, setName] = useState('Bruker');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    initAuth()
      .then(() => {
        return kaleido.initialize({
          username: 'midas-admin-test',
          environment: 'stage',
          authenticate: true
        });
      })
      .then(() => {
        const tmpName = getName();
        if (tmpName) {
          setName(tmpName);
          setIsLoggedIn(true);
        }
      });
  }, []);

  return (
    <Router>
      <Masthead name={name} />
      {isLoggedIn ? (
        <Switch>
          <Route path="/" exact component={ProjectsPage}></Route>
          <Route path="/projects" exact component={ProjectsPage}></Route>
          <Route path="/projects/create" exact component={CreateProjectPage}></Route>
          <Route path="/projects/:projectId/groups/:groupId" component={GroupPage}></Route>
          <Route path="/projects/:projectId" component={ProjectPage}></Route>
          <Route path="/settings">
            <h1>Settings</h1>
          </Route>
        </Switch>
      ) : (
        <Container>
          <div className="p-6">
            <h1>Et øyeblikk... vi prøver å logge deg inn i AD</h1>
          </div>
        </Container>
      )}
    </Router>
  );
}

export default App;
