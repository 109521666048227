import { del, get, post, put, patch } from '../utils/http';
import { getApiServer } from '../config/config';

export function getAlternatives(projId: number, pollId: number): Promise<Array<Midas.Alternative>> {
  return get(`${getApiServer()}/projects/${projId}/polls/${pollId}/alternatives`);
}

// TODO: filter by group..
export function getPolls(projectId: number): Promise<Array<Midas.Poll>> {
  return get(`${getApiServer()}/projects/${projectId}/polls/`);
}

export function getPoll(projectId: number, pollId: number): Promise<Midas.Poll> {
  return get(`${getApiServer()}/projects/${projectId}/polls/${pollId}`);
}

export function createPoll(projectId: number, data: any): Promise<Midas.Poll> {
  return post(`${getApiServer()}/projects/${projectId}/polls/`, data);
}

export function updatePoll(projectId: number, data: Midas.Poll): Promise<Midas.Poll> {
  const { id } = data;
  return put(`${getApiServer()}/projects/${projectId}/polls/${id}`, data);
}

export function getPollByGroup(projectId: number, groupId: number): Promise<Array<Midas.Poll>> {
  return get(`${getApiServer()}/projects/${projectId}/polls?groupId=${groupId}`);
}

export function deletePoll(projectId: number, pollId: number) {
  return del(`${getApiServer()}/projects/${projectId}/polls/${pollId}`);
}

export function patchPoll(projectId: number, pollChange: any) {
  return patch(`${getApiServer()}/projects/${projectId}/polls/${pollChange.id}`, pollChange);
}

export function getPollVotes(projectId: number, pollId: number): Promise<Midas.PollVotes> {
  return get(`${getApiServer()}/projects/${projectId}/polls/${pollId}/votes`);
}
