let config = {} as any;

try {
  const configText = document.getElementById('app-config')?.innerHTML || '{}';
  config = JSON.parse(configText);
} catch (ex) {
  console.log('Failed reading config from root');
}

export function getApiServer() : string {
  return config?.API_SERVER;
}
