import Joi from 'joi';
import {
  getReusableTextFieldSchema,
  getReusableImageFieldSchema,
  getReusableEmojiFieldSchema
} from './reusable-fields';

export function getReusableSchema(reusableType: Midas.ReusableType) {
  return Joi.object({
    id: Joi.number(),
    type: Joi.string().required(),
    fields: Joi.array().ordered(
      ...reusableType.fields.map((field) => {
        switch (field.type) {
          case 'TEXT':
            return getReusableTextFieldSchema(field.isRequired, field.variableName);
          case 'IMAGE':
            return getReusableImageFieldSchema(field.isRequired, field.variableName);
          case 'EMOJI':
            return getReusableEmojiFieldSchema(field.isRequired, field.variableName);
          default:
            console.error('Field with no validation type: ', field);
            throw Error('Found no validation type for field');
        }
      })
    ),
    updated: Joi.any()
  });
}
