export function SectionHeader({ title, description }: Props) {
  return (
    <div className="mb-6 lg:w-1/4">
      <h2 className="font-medium text-lg">{title}</h2>
      {description && <p className="text-sm text-gray-700">{description}</p>}
    </div>
  );
}

interface Props {
  title: string;
  description?: string;
}
