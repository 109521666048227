import fetch from 'unfetch';
import { getAccessToken } from '../api/auth';

export async function get(url: string) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`
    }
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((res) => {
      throw res;
    });
  });
}

export async function post(url: string, data: any) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(data)
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((res) => {
      throw res;
    });
  });
}

export async function put(url: string, data: any) {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(data)
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((res) => {
      throw res;
    });
  });
}

export async function del(url: string) {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`
    }
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((res) => {
      throw res;
    });
  });
}

export async function patch(url: string, data: any) {
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(data)
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((res) => {
      throw res;
    });
  });
}
