import Joi from 'joi';

const ReusableTypeSchema = Joi.object({
  id: Joi.number(),
  title: Joi.string().min(3).max(250).required().messages({
    'any.required': '"Tittel" er påkrevd',
    'string.empty': '"Tittel" må fylles ut',
    'string.min': '"Tittel" må ha minst tre tegn',
    'string.max': '"Tittel" kan maks ha 250 tegn'
  }),
  description: Joi.string().allow('').optional(),
  variableName: Joi.string().alphanum().min(1).max(250).invalid('TEXT', 'IMAGE', 'EMOJI').required().messages({
    'any.required': '"Variabel" er påkrevd',
    'any.invalid': '"Variabel" kan ikke være satt til "TEXT", "IMAGE" eller "EMOJI"',
    'string.empty': '"Variabel" må fylles ut',
    'string.min': '"Variabel" må ha minst ett tegn',
    'string.max': '"Variabel" kan maks ha 250 tegn',
    'string.alphanum': 'Kun bokstaver og tall er tillatt'
  }),
  fields: Joi.array().items(
    Joi.object({
      id: Joi.number(),
      title: Joi.string().min(1).max(250).required().messages({
        'any.required': '"Tittel" er påkrevd',
        'string.empty': '"Tittel" må fylles ut',
        'string.min': '"Tittel" må ha minst ett tegn',
        'string.max': '"Tittel" kan maks ha 250 tegn'
      }),
      variableName: Joi.string().alphanum().min(1).max(250).required().messages({
        'any.required': '"Variabelnavn" er påkrevd',
        'string.empty': '"Variabelnavn" må fylles ut',
        'string.min': '"Variabelnavn" må ha minst ett tegn',
        'string.max': '"Variabelnavn" kan maks ha 250 tegn',
        'string.alphanum': 'Kun bokstaver og tall er tillatt'
      }),
      isRequired: Joi.boolean().required(),
      highlight: Joi.boolean().required(),
      properties: Joi.alternatives().conditional('type', {
        is: 'IMAGE',
        then: Joi.object({
          height: Joi.number().min(1).required().messages({
            'any.required': '"Høyde" er påkrevd',
            'number.base': '"Høyde" må være et tall',
            'number.min': '"Høyde" må ha minst en pixel'
          }),
          width: Joi.number().min(1).required().messages({
            'any.required': '"Bredde" er påkrevd',
            'number.base': '"Bredde" må være et tall',
            'number.min': '"Bredde" må ha minst en pixel'
          }),
          format: Joi.string().valid('jpg', 'png', 'webp').required()
        }),
        otherwise: Joi.any()
      }),
      type: Joi.string().valid('TEXT', 'IMAGE', 'EMOJI')
    })
  )
});

export default ReusableTypeSchema;
