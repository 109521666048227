import classNames from 'classnames';
import Container from '../container/container';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function PageFooter({ className, children }: Props) {
  const pageFooterClass = classNames(
    'px-4 py-2 w-full sticky inset-x-0 bottom-0 bg-white shadow-md z-20',
    className
  );
  return (
    <div className={pageFooterClass}>
      <Container className="flex flex-row-reverse">{children}</Container>
    </div>
  );
}
