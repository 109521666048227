import { createPortal } from 'react-dom';
import { IconClose } from '../icons/icons';
import classNames from 'classnames';

interface Props {
  children: any;
  onClose: (e: any) => void;
  className?: string;
}

export default function SimpleModal({ children, className, onClose }: Props) {
  const modalClassName = classNames(
    'rounded shadow-lg bg-gray-200 pt-4 pb-6 px-6 relative w-full',
    className
  );

  return createPortal(
    <div className="fixed inset-0 z-30 flex items-center justify-center w-full h-full bg-dark bg-opacity-80">
      <button
        onClick={onClose}
        className="absolute inset-0 w-full h-full cursor-default"
        aria-hidden="true"
      >
        Lukk modal
      </button>
      <div className={modalClassName}>
        <button
          onClick={onClose}
          className="absolute flex items-center justify-center w-10 h-10 rounded top-1 right-1 hover:bg-gray-300"
        >
          <span className="sr-only">Lukk modal</span>
          <IconClose />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
}
