{
  "title": "",
  "maxPublishedGroups": 1,
  "properties": {
    "executionType": "SERIE",
    "reusableTypes": [
      {
        "id": 1,
        "title": "Deltaker",
        "description": "Participant",
        "variableName": "participant",
        "fields": [
          {
            "id": 1,
            "title": "Navn",
            "variableName": "artistName",
            "isRequired": false,
            "highlight": false,
            "type": "TEXT"
          },
          {
            "id": 2,
            "title": "Låt",
            "variableName": "song",
            "isRequired": false,
            "highlight": false,
            "type": "TEXT"
          },
          {
            "id": 3,
            "title": "Bilde",
            "variableName": "image",
            "isRequired": false,
            "highlight": false,
            "type": "IMAGE",
            "properties": {
              "format": "png",
              "width": "500",
              "height": "500"
            }
          },
          {
            "id": 4,
            "title": "Liste bilde",
            "variableName": "listimage",
            "isRequired": false,
            "highlight": false,
            "type": "IMAGE",
            "properties": {
              "format": "png",
              "width": "300",
              "height": "300"
            }
          }
        ]
      }
    ],
    "pollTypes": [
      {
        "id": 1,
        "title": "List",
        "variableName": "list",
        "alternativeType": "participant",
        "flags": [],
        "rules": {
          "type": "LIMITED",
          "votesPerUser": 3,
          "maxVoteValue": 1,
          "resultsPublished": true,
          "anonymousVotes": false
        }
      }
    ],
    "clientURL": ""
  }
}
