import {
  getHighlightedImageField,
  getImageField,
  getImageSpecificationFromReusable,
  getDefaultImageSpecification,
  getImageStyling
} from '../../../utils/image';

export default function AlternativeReusable({
  reusables,
  reusableTypes,
  alternative,
  points
}: Admin.ExecuteAlternativeReusableProps) {
  const reusableType = reusableTypes.find((rt) => rt.variableName === alternative.type);
  const reusable = reusables.find((r) => r.id === alternative.reusableId);
  const hasReusable = typeof reusable !== 'undefined';

  if (typeof reusableType === 'undefined') {
    console.error('Could not find matching reusable type: ', {
      reusables,
      reusableTypes
    });
    return <div>Cannot render AlternativeReusable. Check console.</div>;
  }

  const highlightedText =
    reusableType.fields.find((f) => f.highlight && f.type === 'TEXT') ||
    reusableType.fields.find((f) => f.type === 'TEXT');

  const textField = reusable?.fields.find((f) => f.variableName === highlightedText?.variableName);

  const imageOrEmojiEl = hasReusable
    ? getReusableImage(reusable, reusableType) || getReusableEmoji(reusable, reusableType)
    : undefined;

  return (
    <>
      <dt className="text-sm font-semibold">
        <span className="flex items-center gap-2">
          {imageOrEmojiEl}
          {textField?.text || alternative.text}
        </span>
      </dt>
      <dd className="text-lg">{points}</dd>
    </>
  );
}

function getReusableImage(reusable: Midas.Reusable, reusableType: Midas.ReusableType) {
  const imageField = getHighlightedImageField(reusable, reusableType) || getImageField(reusable);

  let styling;
  if (imageField) {
    const imageSpecification =
      getImageSpecificationFromReusable(imageField, reusableType) || getDefaultImageSpecification();
    styling = getImageStyling(imageSpecification);
  }

  return imageField?.properties?.src ? (
    <div className="flex items-center justify-center h-10 w-10 mr-2 border-gray-100 border bg-gray-200">
      <img
        src={imageField.properties.src}
        alt={imageField.text}
        style={styling}
        className="w-12 h-12 object-cover rounded-sm"
      />
    </div>
  ) : undefined;
}

function getReusableEmoji(reusable: Midas.Reusable, reusableType: Midas.ReusableType) {
  const highlightedEmoji =
    reusableType.fields.find((f) => f.highlight && f.type === 'EMOJI') ||
    reusableType.fields.find((f) => f.type === 'EMOJI');

  const emojiField = reusable?.fields.find(
    (field) => field.variableName === highlightedEmoji?.variableName
  );

  return emojiField ? emojiField.text : undefined;
}
