import React, { useState } from 'react';
import Stack from '../../components/stack/stack';
import PollEditTile from '../poll/poll-tile';
import { ButtonAction } from '../../components/button/button';
import { IconPlus } from '../../components/icons/icons';
import SimpleModal from '../../components/modal/simple-modal';
import ConfirmModal from '../../components/modal/confirm-modal';
import {
  addPoll,
  setPollValue,
  addAlternative,
  deleteAlternative,
  deletePoll,
  resetPoll,
  savePoll,
  setAlternativeValue,
  reorderPolls,
  checkMatchedError
} from '../../services/use-polls';
import ExecuteDrop from './execute-drop';
import { duplicateGroup } from '../../services/use-groups';

interface Props {
  isLoading: boolean;
  pollTypes: Array<Midas.PollType>;
  reusables: Array<Midas.Reusable>;
  reusableTypes: Array<Midas.ReusableType>;
  polls: Array<Midas.Poll>;
  addPoll: addPoll;
  duplicatePoll: duplicateGroup;
  deletePoll: deletePoll;
  savePoll: savePoll;
  resetPoll: resetPoll;
  setPollValue: setPollValue;
  addAlternative: addAlternative;
  deleteAlternative: deleteAlternative;
  setAlternativeValue: setAlternativeValue;
  reorderPolls: reorderPolls;
  unlock: (id: number) => void;
  checkMatchedError: checkMatchedError;
}

export default function EditContent({
  pollTypes,
  unlock,
  isLoading,
  reusables,
  reusableTypes,
  polls,
  addPoll,
  deletePoll,
  duplicatePoll,
  savePoll,
  resetPoll,
  setPollValue,
  addAlternative,
  deleteAlternative,
  setAlternativeValue,
  reorderPolls,
  checkMatchedError
}: Props) {
  const [showSelector, setShowSelector] = useState(false);
  const [showVerifyDelete, setShowVerifyDelete] = useState(false);
  const [deletePollId, setDeletePollId] = useState(-1);

  function onDrop(item: any, monitor: any, index: number) {
    reorderPolls(item.id, index);
  }

  function handleClickDelete(pollId: number) {
    setDeletePollId(pollId);
    setShowVerifyDelete(true);
  }

  function wrappedCheckMatchedError(pollId: number, prefixPath: Array<string>) {
    return function (path: Array<string | number>) {
      return checkMatchedError(pollId, [...prefixPath, ...path]);
    };
  }

  return (
    <>
      {isLoading ? (
        <div>{ghostMessages(5)}</div>
      ) : (
        <div className="flex-1 mr-2">
          {polls && (
            <Stack>
              {polls?.map((poll, i) => {
                return (
                  <React.Fragment key={poll.id}>
                    <ExecuteDrop onDrop={onDrop} index={i} />
                    <PollEditTile
                      key={poll.id}
                      unlock={unlock}
                      reusables={reusables}
                      reusableTypes={reusableTypes}
                      poll={poll}
                      pollType={pollTypes.find((type) => type.variableName === poll.presentation)}
                      onInputChange={setPollValue}
                      savePoll={savePoll}
                      deletePoll={handleClickDelete}
                      duplicatePoll={duplicatePoll}
                      resetPoll={resetPoll}
                      addAlternative={addAlternative}
                      deleteAlternative={deleteAlternative}
                      setAlternativeValue={setAlternativeValue}
                      checkMatchedError={wrappedCheckMatchedError(poll.id, [])}
                    />
                  </React.Fragment>
                );
              })}
              <ExecuteDrop onDrop={onDrop} index={polls.length} />
            </Stack>
          )}
          {polls?.length === 0 && <div>Ingen interaksjoner</div>}

          {showSelector && (
            <SimpleModal onClose={() => setShowSelector(false)} className="max-w-md">
              <>
                <h3 className="mb-4 text-lg font-semibold">Velg interaksjonstype</h3>
                <Stack>
                  {pollTypes.map((pollType) => (
                    <button
                      key={pollType.variableName}
                      className="grid items-center px-3 py-2 text-left bg-white rounded shadow min-h-12 hover:ring-2 hover:ring-primary"
                      onClick={() => {
                        addPoll(pollType.variableName);
                        setShowSelector(false);
                      }}
                    >
                      <strong className="font-semibold">{pollType.title}</strong>
                      <span className="opacity-80">{pollType.variableName}</span>
                    </button>
                  ))}
                </Stack>
              </>
            </SimpleModal>
          )}

          {showVerifyDelete && (
            <ConfirmModal
              onCancel={() => {
                setShowVerifyDelete(false);
              }}
              onConfirm={() => {
                return deletePoll(deletePollId).then(() => {
                  setShowVerifyDelete(false);
                });
              }}
              confirm="Slett interaksjon"
            >
              Er du sikker på at du vil slette interaksjonen?
            </ConfirmModal>
          )}

          <div className="mt-3">
            <ButtonAction variant="suggestive" onClick={() => setShowSelector(true)}>
              <IconPlus className="-ml-1" />
              Lag ny interaksjon
            </ButtonAction>
          </div>
        </div>
      )}
    </>
  );
}

const ghostMessages = (count: number) => {
  const array = [];

  for (let i = 0; i < count; i++) {
    array.push(
      <div className="animation-pulse" key={i}>
        <div className="relative py-4 pl-4 pr-16 bg-white rounded-md shadow min-h-16">
          <span className="block w-11/12 h-2 mb-4 bg-gray-400"></span>
          <span className="block w-2/3 h-2 bg-gray-400"></span>
        </div>
        <div className="flex justify-between pt-2">
          <span className="block w-32 h-2 bg-gray-400"></span>
          <span className="block w-24 h-2 bg-gray-400"></span>
        </div>
      </div>
    );
  }
  return array;
};
