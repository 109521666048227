export const isReusableTypeTextField = (
  field: Midas.ReusableTypeField
): field is Midas.ReusableTypeTextField => {
  return field.type === 'TEXT';
};

export const isReusableTypeEmojiField = (
  field: Midas.ReusableTypeField
): field is Midas.ReusableTypeEmojiField => {
  return field.type === 'EMOJI';
};

export const isReusableTypeImageField = (
  field: Midas.ReusableTypeField
): field is Midas.ReusableTypeImageField => {
  return field.type === 'IMAGE';
};

export const isTextField = (field: Midas.Field): field is Midas.TextField => {
  return field.type === 'TEXT';
};

export const isEmojiField = (field: Midas.Field): field is Midas.EmojiField => {
  return field.type === 'EMOJI';
};

export const isImageField = (field: Midas.Field): field is Midas.ImageField => {
  return field.type === 'IMAGE';
};
