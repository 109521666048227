import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

function Breadcrumbs({ paths, className = '' }: Props) {
  const classname = classnames('text-sm opacity-80', className);
  return (
    <div className={classname}>
      {paths.map((item, i) => (
        <React.Fragment key={item.name}>
          {i > 0 && <span className="px-1 py-2"> &gt; </span>}
          {item.path ? (
            <Link className="py-2 hover:underline" to={item.path}>
              {item.name}
            </Link>
          ) : (
            item.name
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

interface Props {
  paths: Array<Midas.BreadcrumbPath>;
  className?: string;
}

export default Breadcrumbs;
