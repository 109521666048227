import Joi from 'joi';

export const getReusableTextFieldSchema = (isRequired: boolean, variableName: string) => {
  return Joi.object({
    text: isRequired ? Joi.string().required() : Joi.string().allow(''),
    variableName: Joi.string().required(),
    type: Joi.string().valid('TEXT').required(),
    properties: Joi.any()
  });
}

export const getReusableImageFieldSchema = (isRequired: boolean, variableName: string) => {
  return Joi.object({
    text: isRequired ? Joi.string().required() : Joi.string().allow(''),
    variableName: Joi.string().required(),
    type: Joi.string().valid('IMAGE').required(),
    properties: Joi.object({
      src: Joi.string().required(),
      params: Joi.any(),
      kaleidoId: Joi.string().required()
    })
  });
}

export const getReusableEmojiFieldSchema = (isRequired: boolean, variableName: string) => {
  return Joi.object({
    text: isRequired ? Joi.string().required() : Joi.string().allow(''),
    variableName: Joi.string().required(),
    type: Joi.string().valid('EMOJI').required(),
    properties: Joi.any()
  });
}
