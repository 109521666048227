import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconGarbage, IconEdit } from '../../components/icons/icons';
import ProjectTile from './project-tile/project-tile';
import { ButtonAction, ButtonSave } from '../../components/button/button';
import MoreDropdown from '../../components/more-dropdown/more-dropdown';
import ConfirmModal from '../../components/modal/confirm-modal';
import SimpleModal from '../../components/modal/simple-modal';
import LabelledInput from '../../components/form/labelled-input/labelled-input';
import {
  changeGroupName,
  publishGroup,
  unpublishGroup,
  duplicateGroup
} from '../../services/use-groups';

interface Params {
  projectId: string;
  group: Midas.Group;
  onDelete: (id: number) => Promise<void>;
  onPublish?: publishGroup;
  onUnpublish?: unpublishGroup;
  onDuplicate: duplicateGroup;
  isActive: boolean;
  changeGroupName: changeGroupName;
}

export default function ProjectListItem({
  projectId,
  group,
  onDelete,
  onPublish,
  onUnpublish,
  onDuplicate,
  isActive = false,
  changeGroupName
}: Params) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [itemName, setItemName] = useState(group?.title || '');

  function onConfirmDelete() {
    return onDelete(group.id).then(() => {
      setShowDeleteModal(false);
    });
  }

  function onConfirmPublish() {
    if (!onPublish) {
      return Promise.resolve();
    }
    return onPublish(group.id).then(() => {
      setShowPublishModal(false);
    });
  }

  function onConfirmUnpublish() {
    if (!onUnpublish) {
      return Promise.resolve();
    }
    return onUnpublish(group.id).then(() => {
      setShowUnpublishModal(false);
    });
  }

  function onConfirmDuplicate() {
    if (!onDuplicate) {
      return Promise.resolve();
    }
    return onDuplicate(group.id).then(() => {
      setShowDuplicateModal(false);
    });
  }

  function saveNameChange() {
    return changeGroupName(group.id, itemName).then(() => {
      setShowChangeName(false);
    });
  }

  return (
    <>
      <div className="relative">
        <Link to={`/projects/${projectId}/groups/${group.id}/${isActive ? 'execute' : 'edit'}`}>
          <ProjectTile title={group.title} tags={group.tags} id={group.id} />
        </Link>

        <div className="absolute right-0 inset-y-0 flex items-center px-3">
          <MoreDropdown dropdownKey={`id-${group.id}`}>
            {onPublish && (
              <ButtonAction
                variant="secondary"
                onClick={() => {
                  setShowPublishModal(true);
                }}
              >
                Publiser gruppe
              </ButtonAction>
            )}
            {onUnpublish && (
              <ButtonAction
                variant="secondary"
                onClick={() => {
                  setShowUnpublishModal(true);
                }}
              >
                Avpubliser gruppe
              </ButtonAction>
            )}

            <ButtonAction
              variant="secondary"
              onClick={() => {
                setShowDuplicateModal(true);
              }}
            >
              Duplisér gruppe
            </ButtonAction>
            <ButtonAction
              variant="secondary"
              onClick={() => {
                setShowChangeName(true);
              }}
            >
              <IconEdit className="-ml-1" />
              <span>Endre navn &hellip;</span>
            </ButtonAction>
            <ButtonAction
              variant="secondary"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <IconGarbage className="-ml-1" />
              <span>Slett gruppe &hellip;</span>
            </ButtonAction>
          </MoreDropdown>
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmModal
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={onConfirmDelete}
          confirm="Slett"
        >
          Er du sikker på at du vil slette?
        </ConfirmModal>
      )}
      {showPublishModal && (
        <ConfirmModal
          onCancel={() => {
            setShowPublishModal(false);
          }}
          onConfirm={onConfirmPublish}
          confirm="Publiser"
        >
          Er du sikker på at du vil publisere gruppen?
        </ConfirmModal>
      )}
      {showUnpublishModal && (
        <ConfirmModal
          onCancel={() => {
            setShowUnpublishModal(false);
          }}
          onConfirm={onConfirmUnpublish}
          confirm="Avpubliser"
        >
          Er du sikker på at du vil avpublisere gruppen?
        </ConfirmModal>
      )}
      {showDuplicateModal && (
        <ConfirmModal
          onCancel={() => {
            setShowDuplicateModal(false);
          }}
          onConfirm={onConfirmDuplicate}
          confirm="Dupliser"
        >
          Er du sikker på at du vil duplisere gruppen?
        </ConfirmModal>
      )}
      {showChangeName && (
        <SimpleModal
          className="max-w-lg"
          onClose={() => {
            setShowChangeName(false);
          }}
        >
          <LabelledInput
            label="Navn på gruppe"
            className="mb-2"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          <div className="flex justify-end">
            <ButtonAction
              className="mr-2"
              onClick={() => setShowChangeName(false)}
              variant="default"
            >
              Avbryt
            </ButtonAction>
            <ButtonSave variant="primary" onSave={saveNameChange}>
              Lagre
            </ButtonSave>
          </div>
        </SimpleModal>
      )}
    </>
  );
}
