import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LabelledInput from '../../components/form/labelled-input/labelled-input';
import RadioGroup from '../../components/form/radio-group/radio-group';
import Section from '../../components/section/section';
import ProjectConfig from './config/project-config';
import Page from '../../components/page/page';
import PageHeader from '../../components/page/page-header';
import PageContent from '../../components/page/page-content';
import PageFooter from '../../components/page/page-footer';
import useProject from '../../services/use-project';
import { ButtonLink, ButtonSave } from '../../components/button/button';

enum PROJECT_TYPE {
  STANDARD,
  CUSTOM
}

export default function CreateProjectPage() {
  const history = useHistory();

  const {
    project,
    setValue,
    addReusableType,
    removeReusableType,
    addField,
    removeField,
    addPollType,
    removePollType,
    saveProject,
    saveStandard,
    updatePollType,
    updateRuleType,
    updateReusableType,
    updateReusableTypeField,
    addFlagType,
    removeFlagType,
    updateFlagType,
    checkMatchedError,
    getErrorMessage
  } = useProject({ projectId: undefined });

  const [projectType, setProjectType] = useState(PROJECT_TYPE.STANDARD);
  const [standardUrl, setStandardUrl] = useState('');
  const [standardVotes, setStandardVotes] = useState(3);

  function handleChange(e: any) {
    if (e.target?.value) {
      setProjectType(Number(e.target.value));
    }
  }

  function handleChangeFactory(path: string) {
    return function handleChange(e: any) {
      const value = e.target?.value;
      if (path && typeof value !== 'undefined') {
        setValue(path, value);
      }
    };
  }

  function handleSaveProject(project: Midas.Project) {
    return () => {
      history.push(`/projects/${project.id}`);
    };
  }

  function onSave() {
    if (projectType === PROJECT_TYPE.CUSTOM) {
      return saveProject()
        .then(handleSaveProject)
        .catch((error) => {
          console.log('Got error saving: ', error);
        });
    } else {
      return saveStandard(project?.title || '', standardUrl, standardVotes)
        .then(handleSaveProject)
        .catch((error) => {
          console.log('Got error saving: ', error);
        });
    }
  }

  return (
    <div className="flex-1 flex overflow-hidden">
      <Page>
        <PageHeader title="Nytt prosjekt"></PageHeader>
        <PageContent>
          <div className="grid gap-4">
            <Section>
              <Section.Header
                title="Metadata"
                description="En god tittel og beskrivelse gjør det enkelt å finne igjen prosjektet"
              />
              <Section.Content>
                <div className="bg-white rounded shadow px-8 pb-8 pt-7">
                  <div className="grid gap-6">
                    <LabelledInput
                      label="Tittel"
                      value={project?.title || ''}
                      onChange={handleChangeFactory('title')}
                      isInvalid={checkMatchedError(['title'])}
                      warning="Tittel må være satt"
                    />
                  </div>
                </div>
              </Section.Content>
            </Section>

            <hr className="w-full my-4 bg-gray-300" />

            <Section>
              <Section.Header
                title="Type"
                description="Velg type for å se hva som må konfigureres"
              />
              <Section.Content>
                <RadioGroup>
                  <RadioGroup.Button
                    label="Standard"
                    description="Du trenger kun å fylle ut noen få felter for å ta denne i bruk"
                    name="configuration-type"
                    value={PROJECT_TYPE.STANDARD}
                    checked={projectType === PROJECT_TYPE.STANDARD}
                    onChange={handleChange}
                  />
                  <RadioGroup.Button
                    label="Tilpasset"
                    description="Gir deg full kontroll over alt som kan konfigureres"
                    name="configuration-type"
                    value={PROJECT_TYPE.CUSTOM}
                    checked={projectType === PROJECT_TYPE.CUSTOM}
                    onChange={handleChange}
                  />
                </RadioGroup>
              </Section.Content>
            </Section>

            <hr className="w-full my-4 bg-gray-300" />

            {projectType === PROJECT_TYPE.STANDARD ? (
              <Section>
                <Section.Header
                  title="Innstillinger"
                  description="Feltene du må fylle ut for å sette opp en avstemning"
                />

                <Section.Content>
                  <div className="bg-white rounded shadow px-8 pb-8 pt-7">
                    <div className="grid gap-6">
                      <LabelledInput
                        label="URL til avstemningsklienten"
                        value={standardUrl}
                        onChange={(e) => {
                          setStandardUrl(e.target.value);
                        }}
                        isInvalid={checkMatchedError(['properties', 'clientURL'])}
                        warning="Klient URL er feil"
                      />

                      <LabelledInput
                        label="Antall tillate stemmer"
                        value={standardVotes}
                        onChange={(e) => {
                          setStandardVotes(e.target.value);
                        }}
                        isInvalid={checkMatchedError([
                          'properties',
                          'pollTypes',
                          0,
                          'rules',
                          'votesPerUser'
                        ])}
                        warning="Antall tillate stemmer må være tall"
                      />
                    </div>
                  </div>
                </Section.Content>
              </Section>
            ) : (
              <ProjectConfig
                project={project}
                addReusableType={addReusableType}
                removeReusableType={removeReusableType}
                addField={addField}
                removeField={removeField}
                addPollType={addPollType}
                removePollType={removePollType}
                updatePollType={updatePollType}
                updateRuleType={updateRuleType}
                updateReusableType={updateReusableType}
                updateReusableTypeField={updateReusableTypeField}
                addFlagType={addFlagType}
                removeFlagType={removeFlagType}
                updateFlagType={updateFlagType}
                updateValue={handleChangeFactory}
                checkMatchedError={checkMatchedError}
                getErrorMessage={getErrorMessage}
              />
            )}
          </div>
        </PageContent>

        <PageFooter>
          <div className="flex gap-2 items-center ml-auto">
            <ButtonLink variant="secondary" to={'/'}>
              Avbryt
            </ButtonLink>
            <ButtonSave variant="primary" onSave={onSave}>
              Opprett prosjekt
            </ButtonSave>
          </div>
        </PageFooter>
      </Page>
    </div>
  );
}
