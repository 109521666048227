import { useEffect, useRef, useState } from 'react';
import kaleido from '@nrk/kaleido-ui';
import { IconDownload } from '../../icons/icons';
import { ButtonAction } from '../../button/button';
import LabelledInput from '../labelled-input/labelled-input';
import { getImageStyling, isImageInPortrait } from '../../../utils/image';

// Depends on env: from config
const gfxUrl = 'https://gfx-stage.nrk.no';

interface Props {
  kaleidoId?: string;
  params: any;
  src: string;
  alt: string;
  imageSpecification: Midas.ImageSpecification;
  onChange: (src: string, params: any, kaleidoId: string, altText: string) => void;
  onClose: () => void;
}

export default function ImageModal({
  kaleidoId,
  params,
  src,
  alt,
  imageSpecification,
  onChange,
  onClose
}: Props) {
  const kaleidoEl = useRef<HTMLDivElement>(null);
  const [imgId, setImgId] = useState(kaleidoId || '');
  const [imgParams, setImgParams] = useState<any>(params);
  const [timerId, setTimerId] = useState<any>();
  const [altText, setAltText] = useState(alt);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    if (!imgId || !imgParams) {
      return;
    }
    let timeoutId = setTimeout(() => {
      kaleido
        .getDerivate(imgId, {
          width: imageSpecification?.width || 889,
          height: imageSpecification?.height || 500,
          format: imageSpecification?.format || 'png',
          ...imgParams.params
        })
        .then((derivate: any) => {
          setImgSrc(`${gfxUrl}/${derivate.uri}`);
        });
    }, 500);

    setTimerId(timeoutId);

    return () => {
      clearTimeout(timerId);
    };
  }, [imgId, imgParams]);

  useEffect(() => {
    if (!imgId) {
      kaleido.editor(kaleidoEl.current, false);
    } else {
      let format = '16:9';
      if (imageSpecification?.width && imageSpecification?.height) {
        format = `${imageSpecification?.width}:${imageSpecification?.height}`;
      }

      kaleido.editor(kaleidoEl.current, {
        id: imgId,
        onChange: onImageSelect,
        params: params.params,
        format
      });
    }
  }, [imgId]);

  function onImagePick() {
    kaleido.picker({
      onChange: (state: any) => {
        setImgId(state.selected[0]);
      }
    });
  }

  function onImageSelect(imgState: any) {
    setImgParams({
      ...imgState
    });
  }

  function onAltChange(e: any) {
    setAltText(e.target.value);
  }

  function onSave() {
    onChange(imgSrc, imgParams, imgId, altText);
    onClose();
  }

  const isPortrait = isImageInPortrait(imageSpecification);
  const styling = getImageStyling(imageSpecification);

  if (isPortrait) {
    styling.height = '500px';
  }

  return (
    <>
      <div className="flex">
        <div className="mr-2">
          <label className="text-sm font-semibold">Bilde</label>
          <ButtonAction onClick={onImagePick} variant="suggestive" className="mb-2">
            <IconDownload />
            Last opp eller finn bilde
          </ButtonAction>
          <LabelledInput label="Alternativ tekst" value={altText} onChange={onAltChange} />
        </div>
        <div className="block overflow-hidden m-auto" style={styling}>
          <div ref={kaleidoEl}></div>
        </div>
      </div>
      <div>
        <div className="flex justify-end pt-2">
          <ButtonAction className="mr-2" variant="default" onClick={onClose}>
            Avbryt
          </ButtonAction>
          <ButtonAction variant="primary" onClick={onSave}>
            Bekreft
          </ButtonAction>
        </div>
      </div>
    </>
  );
}
