import Joi from 'joi';
import dotProp from 'dot-prop-immutable';
import { useEffect, useState } from 'react';
import { getProject, createProject, updateProject } from '../api/project';
import { validateProject } from '../validation/project';
import { createStandard } from './projects';
import { isLimitedPollRules } from '../typeguards/rules';
import { isJoiValidationError } from '../typeguards/validation';

interface Props {
  projectId?: string;
}

/* Interface start */
export type setValue = (path: string, value: any) => void;
export type addReusableType = () => void;
export type removeReusableType = (reusableId: number) => void;
export type addField = (reusableId: number) => void;
export type removeField = (reusableId: number, fieldId: number) => void;
export type updateReusableType = (resTypeId: number, path: string, value: any) => void;
export type updateReusableTypeField = (
  resTypeId: number,
  fieldId: number,
  path: string,
  value: any
) => void;
export type addPollType = () => void;
export type updatePollType = (pollTypeId: number, path: string, value: any) => void;
export type removePollType = (pollTypeId: number) => void;
export type updateRuleType = (
  pollTypeId: number,
  ruleType: Midas.PollRuleType,
  rule: Midas.PollRules
) => void;
export type addFlagType = (pollTypeId: number) => void;
export type removeFlagType = (pollTypeId: number, flagTypeId: number) => void;
export type updateFlagType = (
  pollTypeId: number,
  flagTypeId: number,
  path: string,
  value: any
) => void;
export type resetProject = () => Promise<Midas.Project>;
export type saveProject = () => Promise<Midas.Project>;
export type saveStandard = (
  title: string,
  url: string,
  voteCount: number
) => Promise<Midas.Project>;
export type checkMatchedError = (path: Array<string | number>) => boolean;
export type getErrorMessage = (path: Array<string | number>) => string;
/* Interface end */

export default function useProject({ projectId }: Props) {
  const [project, setProject] = useState<Midas.Project>();
  const [errors, setErrors] = useState<Array<Joi.ValidationErrorItem>>([]);

  useEffect(() => {
    if (projectId === undefined) {
      setProject(getInitialProject());
    } else {
      getProject(Number(projectId)).then((project: Midas.Project) => {
        setProject(project);
      });
    }
  }, [projectId]);

  const setValue: setValue = (path, value) => {
    if (!project) {
      return;
    }
    const updatedProject = dotProp.set(project, path, value);
    setProject(updatedProject);
  };

  const addReusableType: addReusableType = () => {
    const list = project?.properties?.reusableTypes || [];
    const currentNumbers = list.map((item) => item.id);
    const nextNumber = Math.max(...currentNumbers, 0) + 1;

    const newReusableType = {
      id: nextNumber,
      title: '',
      description: '',
      variableName: '',
      fields: []
    };

    const updated = dotProp.set(project, `properties.reusableTypes`, [
      ...(project?.properties.reusableTypes || []),
      newReusableType
    ]);

    setProject(updated);
  };

  const removeReusableType: removeReusableType = (reusableId) => {
    const updated = dotProp.set(
      project,
      `properties.reusableTypes`,
      project?.properties.reusableTypes.filter((rt) => rt.id !== reusableId)
    );

    setProject(updated);
  };

  const addField: addField = (reusableId) => {
    const reusableType = project?.properties.reusableTypes.find((rt) => rt.id === reusableId);

    if (!reusableType) {
      return;
    }

    const currentNumbers = reusableType.fields.map((item) => item.id);
    const nextNumber = Math.max(...currentNumbers, 0) + 1;

    const updatedRT = {
      ...reusableType,
      fields: [
        ...reusableType?.fields,
        {
          id: nextNumber,
          title: '',
          variableName: '',
          isRequired: false,
          highlight: false,
          type: 'TEXT'
        }
      ]
    };

    const updatedProject = dotProp.set(
      project,
      'properties.reusableTypes',
      project?.properties.reusableTypes.map((rt) => (rt.id === reusableId ? updatedRT : rt))
    );

    setProject(updatedProject);
  };

  const removeField: removeField = (reusableId, fieldId) => {
    const reusableType = project?.properties.reusableTypes.find((rt) => rt.id === reusableId);
    if (!reusableType) {
      return;
    }

    const updatedRT = {
      ...reusableType,
      fields: reusableType.fields.filter((field) => field.id !== fieldId)
    };

    const updatedProject = dotProp.set(
      project,
      'properties.reusableTypes',
      project?.properties.reusableTypes.map((rt) => (rt.id === reusableId ? updatedRT : rt))
    );

    setProject(updatedProject);
  };

  const updateReusableType: updateReusableType = (resTypeId, path, value) => {
    const reusableType = project?.properties.reusableTypes.find(
      (intType) => intType.id === resTypeId
    );
    if (!reusableType || !project) {
      return;
    }

    const updated = dotProp.set(reusableType, path, value);

    setProject({
      ...project,
      properties: {
        ...project?.properties,
        reusableTypes: project?.properties.reusableTypes.map((reT) =>
          reT.id === resTypeId ? updated : reT
        )
      }
    });
  };

  const updateReusableTypeField: updateReusableTypeField = (resTypeId, fieldId, path, value) => {
    const reusableType = project?.properties.reusableTypes.find(
      (intType) => intType.id === resTypeId
    );
    if (!reusableType || !project) {
      return;
    }

    const field = reusableType.fields.find((field) => field.id === fieldId);
    if (!field) {
      return;
    }

    let updatedField = dotProp.set(field, path, value);
    if (path === 'type') {
      if (value === 'IMAGE') {
        updatedField = dotProp.set(updatedField, 'properties', {
          width: 640,
          height: 380,
          format: 'png'
        });
      } else {
        updatedField = dotProp.set(updatedField, 'properties', undefined);
      }
    }

    const updatedReusableType = {
      ...reusableType,
      fields: reusableType.fields.map((field) => (field.id === fieldId ? updatedField : field))
    };

    setProject({
      ...project,
      properties: {
        ...project?.properties,
        reusableTypes: project?.properties.reusableTypes.map((reT) =>
          reT.id === resTypeId ? updatedReusableType : reT
        )
      }
    });
  };

  const addPollType: addPollType = () => {
    const nextNumber = getNextNumber(project?.properties.pollTypes || [], 'id');

    const newPollType: Midas.PollType = {
      id: nextNumber,
      title: '',
      variableName: '',
      questionType: 'TEXT',
      alternativeType: 'TEXT',
      flags: [],
      rules: {
        type: 'SINGLE',
        maxVoteValue: 1,
        resultsPublished: true,
        anonymousVotes: false
      }
    };

    const updatedProject = dotProp.set(project, `properties.pollTypes`, [
      ...(project?.properties.pollTypes || []),
      newPollType
    ]);

    setProject(updatedProject);
  };

  const updatePollType: updatePollType = (pollTypeId, path, value) => {
    const pollType = project?.properties?.pollTypes.find((pollType) => pollType.id === pollTypeId);
    if (!project || !pollType) {
      return;
    }

    const updated = dotProp.set(pollType, path, value);

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.map((pt) => (pt.id === pollTypeId ? updated : pt))
      }
    });
  };

  const removePollType: removePollType = function (pollId) {
    if (!project) {
      return;
    }

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.filter((pt) => pt.id !== pollId)
      }
    });
  };

  const updateRuleType: updateRuleType = function (pollTypeId, newRuleType, rules) {
    const pollType = project?.properties?.pollTypes.find((pollType) => pollType.id === pollTypeId);
    if (!project || !pollType) {
      return;
    }

    const updatedPollType = dotProp.set(pollType, 'rules.type', newRuleType) as Midas.PollType;

    if (isLimitedPollRules(updatedPollType.rules)) {
      if (!updatedPollType.rules.votesPerUser) {
        updatedPollType.rules.votesPerUser = 3;
      }
    } else {
      updatedPollType.rules = {
        type: newRuleType,
        maxVoteValue: rules.maxVoteValue,
        resultsPublished: rules.resultsPublished,
        anonymousVotes: rules.anonymousVotes
      } as Midas.SinglePollRules | Midas.UnlimitedPollRules;
    }

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.map((pt) =>
          pt.id === pollTypeId ? updatedPollType : pt
        )
      }
    });
  };

  const addFlagType: addFlagType = function (pollTypeId) {
    const pollType = project?.properties.pollTypes.find((pt) => pt.id === pollTypeId);

    if (!project || !pollType) {
      return;
    }

    const nextId = getNextNumber(pollType.flags, 'id');

    const updatedPollType: Midas.PollType = {
      ...pollType,
      flags: [
        ...pollType.flags,
        {
          id: nextId,
          variableName: '',
          title: '',
          defaultValue: false
        }
      ]
    };

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.map((pt) =>
          pt.id === pollTypeId ? updatedPollType : pt
        )
      }
    });
  };

  const removeFlagType: removeFlagType = function (pollTypeId, flagTypeId) {
    const pollType = project?.properties.pollTypes.find((pt) => pt.id === pollTypeId);

    if (!project || !pollType) {
      return;
    }

    const updatedPollType: Midas.PollType = {
      ...pollType,
      flags: pollType.flags.filter((flag) => flag.id !== flagTypeId)
    };

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.map((pt) =>
          pt.id === pollTypeId ? updatedPollType : pt
        )
      }
    });
  };

  const updateFlagType: updateFlagType = function (pollTypeId, flagTypeId, path, value) {
    const pollType = project?.properties.pollTypes.find((pollType) => pollType.id === pollTypeId);

    const flagType = pollType?.flags.find((flagType) => flagType.id === flagTypeId);

    if (!project || !pollType || !flagType) {
      return;
    }

    const updatedFlagType: Midas.FlagType = {
      ...flagType,
      [path]: value
    };

    const updatedPollType: Midas.PollType = {
      ...pollType,
      flags: pollType.flags.map((ft) => (ft.id === flagTypeId ? updatedFlagType : ft))
    };

    setProject({
      ...project,
      properties: {
        ...project.properties,
        pollTypes: project.properties.pollTypes.map((pt) =>
          pt.id === pollTypeId ? updatedPollType : pt
        )
      }
    });
  };

  const resetProject: resetProject = function () {
    return getProject(Number(projectId)).then((project: Midas.Project) => {
      setProject(project);
      return project;
    });
  };

  const saveProject: saveProject = function () {
    if (!project) {
      return Promise.reject();
    }
    setErrors([]);
    if (project.id) {
      return validateProject(project)
        .then(() => updateProject(project))
        .then((data) => {
          setProject(data);
          return data;
        })
        .catch((error) => {
          if (error.isJoi) {
            setErrors(error.details);
          }
          throw error;
        });
    } else {
      return validateProject(project)
        .then(() => createProject(project))
        .then((data) => {
          setProject(data);
          return data;
        })
        .catch((error) => {
          if (isJoiValidationError(error)) {
            console.log(error.details);
            setErrors(error.details);
          }
          throw error;
        });
    }
  };

  const saveStandard: saveStandard = function (title, url, voteCount) {
    const project = createStandard(title, url, voteCount);

    return validateProject(project)
      .then(() => createProject(project))
      .then((data) => {
        setProject(data);
        return data;
      })
      .catch((error) => {
        if (isJoiValidationError(error)) {
          console.log(error.details);
          setErrors(error.details);
        }
        throw error;
      });
  };

  const checkMatchedError: checkMatchedError = function (path) {
    if (errors.length === 0) {
      return false;
    }
    return errors.some((error) => {
      return (
        Array.isArray(error.path) &&
        Array.isArray(path) &&
        error.path.length === path.length &&
        error.path.every((val: string | number, index: number) => val === path[index])
      );
    });
  };

  const getErrorMessage: getErrorMessage = function (path) {
    if (errors.length === 0) {
      return '';
    }

    const error = errors.find((error) => {
      return (
        Array.isArray(error.path) &&
        Array.isArray(path) &&
        error.path.length === path.length &&
        error.path.every((val: string | number, index: number) => val === path[index])
      );
    });

    return error ? error.message : '';
  };

  return {
    project,
    setValue,
    saveProject,
    saveStandard,
    resetProject,
    addReusableType,
    removeReusableType,
    addField,
    removeField,
    updateReusableType,
    updateReusableTypeField,
    addPollType,
    removePollType,
    updatePollType,
    updateRuleType,
    addFlagType,
    removeFlagType,
    updateFlagType,
    checkMatchedError,
    getErrorMessage
  };
}

function getInitialProject(): Midas.Project {
  return {
    id: 0,
    title: '',
    maxPublishedGroups: 1,
    properties: {
      executionType: 'SERIE',
      reusableTypes: [],
      pollTypes: []
    }
  };
}

function getNextNumber(list: Array<any>, selector: string) {
  const items = list.map((item) => item[selector]);
  return Math.max(...items, 0) + 1;
}
