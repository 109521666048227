import { Component } from 'react';
import { createPortal } from 'react-dom';
import Notification, { Props as NotificationProps } from './notification';

export type NotificationDetail = Pick<NotificationProps, 'label' | 'canClose' | 'type'>;

export function dispatchNotification(detail: NotificationDetail) {
  document.dispatchEvent(new CustomEvent('notification', { detail }));
}

interface NotificationEvents extends NotificationDetail {
  id: string;
}

interface State {
  notifications: Array<NotificationEvents>;
}

let notificationRoot = document.getElementById('notification-root');

if (!notificationRoot) {
  notificationRoot = document.createElement('div');
  notificationRoot.setAttribute('id', 'notification-root');
  document.body.appendChild(notificationRoot);
}

export default class NotificationContainer extends Component<{}, State> {
  el = document.createElement('div');

  constructor(props: any) {
    super(props);
    this.notificationHandler = this.notificationHandler.bind(this);
    this.state = {
      notifications: []
    };
  }

  componentDidMount() {
    document.addEventListener('notification', this.notificationHandler);
    notificationRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener('notification', this.notificationHandler);
    notificationRoot?.removeChild(this.el);
  }

  notificationHandler(e: any) {
    const notificationDetail = e.detail as NotificationDetail;

    this.setState({
      notifications: [
        ...this.state.notifications,
        {
          id: Date.now().toString(36) + Math.random().toString(36).slice(2, 5),
          ...notificationDetail
        }
      ]
    });
  }

  removeNotification(id: string) {
    const index = this.state.notifications.findIndex((n: any) => n.id === id);
    if (index >= 0) {
      const notifications = [...this.state.notifications];
      notifications.splice(index, 1);
      this.setState({ notifications });
    }
  }

  render() {
    return createPortal(
      <div>
        {this.state.notifications.map((n: any, i: number) => (
          <Notification
            key={n.id}
            label={n.label}
            onNotificationRemove={(e: any) => {
              this.removeNotification(n.id);
            }}
            position={i}
            canClose={true}
          />
        ))}
      </div>,
      this.el
    );
  }
}
