import { get, post, put, del } from '../utils/http';
import { getApiServer } from '../config/config';

export function getReusables(projectId: number): Promise<Array<Midas.Reusable>> {
  return get(`${getApiServer()}/projects/${projectId}/reusables`);
}

export function getReusable(projectId: number, id: number): Promise<Midas.Reusable> {
  return get(`${getApiServer()}/projects/${projectId}/reusables/${id}`);
}

export function createReusable(projectId: number, data: Midas.NewReusable): Promise<Midas.Reusable> {
  return post(`${getApiServer()}/projects/${projectId}/reusables`, data);
}

export function updateReusable(projectId: number, data: Midas.Reusable): Promise<Midas.Reusable> {
  const { id } = data;

  return put(`${getApiServer()}/projects/${projectId}/reusables/${id}`, data);
}

export function deleteReusable(projectId: number, reusableId: number) {
  return del(`${getApiServer()}/projects/${projectId}/reusables/${reusableId}`);
}
