import Joi from 'joi';

const PollTypeSchema = Joi.object({
  id: Joi.number(),
  title: Joi.string().min(3).max(250).required().messages({
    'any.required': '"Tittel" er påkrevd',
    'string.empty': '"Tittel" må fylles ut',
    'string.min': '"Tittel" må ha minst tre tegn',
    'string.max': '"Tittel" kan maks ha 250 tegn'
  }),
  variableName: Joi.string().alphanum().min(1).max(250).required().messages({
    'any.required': '"Variabel" er påkrevd',
    'string.empty': '"Variabel" må fylles ut',
    'string.min': '"Variabel" må ha minst ett tegn',
    'string.max': '"Variabel" kan maks ha 250 tegn',
    'string.alphanum': 'Kun bokstaver og tall er tillatt'
  }),
  alternativeType: Joi.string().alphanum().min(1).max(250).required(),
  questionType: Joi.string().alphanum().min(1).max(250).required(),
  alternativeProperties: Joi.object({
    height: Joi.number().min(1).required().messages({
      'any.required': '"Høyde" er påkrevd',
      'number.base': '"Høyde" må være et tall',
      'number.min': '"Høyde" må ha minst en pixel'
    }),
    width: Joi.number().min(1).required().messages({
      'any.required': '"Bredde" er påkrevd',
      'number.base': '"Bredde" må være et tall',
      'number.min': '"Bredde" må ha minst en pixel'
    }),
    format: Joi.string().valid('jpg', 'png', 'webp').required()
  }).optional(),
  flags: Joi.array().items(
    Joi.object({
      id: Joi.number().required(),
      variableName: Joi.string().alphanum().min(1).max(250).required(),
      title: Joi.string(),
      defaultValue: Joi.boolean().required()
    })
  ),
  rules: Joi.object({
    type: Joi.string().valid('LIMITED', 'SINGLE', 'UNLIMITED').required(),
    maxVoteValue: Joi.number().min(1).required().messages({
      'any.required': '"Max verdi per stemme" er påkrevd',
      'number.base': '"Max verdi per stemme" må være et tall',
      'number.min': '"Max verdi per stemme" må være et positivt tall'
    }),
    votesPerUser: Joi.alternatives().conditional('type', {
      is: 'LIMITED',
      then: Joi.number().min(1).required().messages({
        'any.required': '"Antall stemmer tillatt" er påkrevd',
        'number.base': '"Antall stemmer tillatt" må være et tall',
        'number.min': '"Antall stemmer tillatt" må være et positivt tall'
      }),
      otherwise: Joi.forbidden()
    }),
    resultsPublished: Joi.boolean().required(),
    anonymousVotes: Joi.boolean().required()
  }).required()
});

export default PollTypeSchema;
