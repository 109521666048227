import classNames from 'classnames';
import Container from '../container/container';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function PageContent({ className, children }: Props) {
  const pageContentClass = classNames('px-4 pt-8 pb-24 w-full flex-1 bg-gray-100', className);
  return (
    <div className={pageContentClass}>
      <Container>{children}</Container>
    </div>
  );
}
