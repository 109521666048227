import React from 'react';
import { ButtonAction } from '../../components/button/button';
import { IconPlus } from '../../components/icons/icons';
import PageContent from '../../components/page/page-content';
import Section from '../../components/section/section';
import Stack from '../../components/stack/stack';
import useReusables from '../../services/use-reusables';
import ReusableItem from './reusable';
import ErrorBoundary from '../../components/error-boundary/error-boundary';

interface Params {
  projectId: number;
}

export default function ProjectReusables({ projectId }: Params) {
  const {
    reusableTypes,
    reusables,
    resetReusable,
    saveReusable,
    addReusable,
    setFieldValue,
    removeReusable,
    checkMatchedError
  } = useReusables({ projectId });

  return (
    <>
      <PageContent>
        <div className="grid gap-3">
          {reusableTypes?.length > 0 ? (
            reusableTypes.map((reusableType, index) => {
              const filtededReusables = reusables.filter(
                (reusable) => reusable.type === reusableType.variableName
              );

              return (
                <React.Fragment key={reusableType.id}>
                  {index > 0 ? <hr className="bg-gray-300 my-8" /> : ''}
                  <Section>
                    <Section.Header
                      title={reusableType.title}
                      description={reusableType.variableName}
                    />
                    <Section.Content>
                      <Stack>
                        {filtededReusables.map((reusable) => (
                          <ErrorBoundary>
                            <ReusableItem
                              key={reusable.id}
                              reusable={reusable}
                              reusableType={reusableType}
                              saveReusable={saveReusable}
                              resetReusable={resetReusable}
                              setFieldValue={setFieldValue}
                              removeReusable={removeReusable}
                              checkMatchedError={checkMatchedError}
                            ></ReusableItem>
                          </ErrorBoundary>
                        ))}
                      </Stack>
                      <ButtonAction
                        variant="suggestive"
                        onClick={() => addReusable(reusableType.variableName)}
                      >
                        <IconPlus className="-ml-1" />
                        Ny {reusableType.title}
                      </ButtonAction>
                    </Section.Content>
                  </Section>
                </React.Fragment>
              );
            })
          ) : (
            <div>Dette prosjektet har ikke delt innhold</div>
          )}
        </div>
      </PageContent>
    </>
  );
}
