import { useState } from 'react';
import classnames from 'classnames';
import FormProps from '../form-props';
import { EmojiPicker } from './emoji-picker';

interface Props extends FormProps {
  description: string;
  value?: any;
  onChange?: (value: string, props?: any) => void;
  isInvalid: boolean;
  warning?: string;
}

export default function EmojiSelect({
  label,
  description,
  className,
  disabled,
  value,
  onChange,
  isInvalid,
  warning
}: Props) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [devval, setDevval] = useState('');

  function onToggle(value?: boolean) {
    setPickerOpen(value === undefined ? !pickerOpen : value);
  }

  function onSelect(detail: any) {
    setDevval(detail.unicode);
    if (onChange) {
      onChange(detail.unicode);
    }
    onToggle(false);
  }

  const labelClassName = classnames('grid relative justify-items-start', className);
  const inputClassName =
    'h-10 w-10 rounded py-2 px-3 border border-gray-400 font-medium text-sm invalid:border-yellow-400 invalid:shadow-none';

  return (
    <label className={labelClassName}>
      <span className="font-semibold text-sm">{label}</span>
      {description && <small className="mb-1 opacity-75 text-xs">{description}</small>}
      <button className={inputClassName} disabled={disabled} onClick={() => onToggle()}>
        {value || devval}
      </button>
      {pickerOpen && (
        <EmojiPicker className="absolute z-10 top-full mt-1" onSelect={onSelect}></EmojiPicker>
      )}
      {isInvalid && warning && (
        <small className="py-1 px-2 text-xs bg-yellow-400 rounded">{warning}</small>
      )}
    </label>
  );
}
