interface Props {
  project: Midas.Project;
  reusables: Array<Midas.Reusable>;
  polls: Array<Midas.Poll>;
}

export default function Preview({ project, reusables, polls }: Props) {
  const activePoll = polls.find((poll) => poll.active);

  // TODO: post message to iframe

  return (
    <aside className="flex flex-col text-white bg-gray-800">
      <div
        className="px-3 py-4 font-semibold text-center text-black bg-yellow-400 w-80"
        aria-hidden="true"
      >
        Forhåndsvisning av {project.title}
      </div>
      <div>
        <p>
          @todo
          <br />
          URL: {project.properties.clientURL}
          <br />
          Active: {activePoll?.text} ({activePoll?.id})
        </p>
      </div>
    </aside>
  );
}
