import { useEffect, useState } from 'react';
import dotProp from 'dot-prop-immutable';
import {
  getReusables,
  getReusable,
  updateReusable,
  createReusable,
  deleteReusable
} from '../api/reusables';
import { getProject } from '../api/project';
import { isReusableTypeEmojiField, isReusableTypeImageField } from '../typeguards/field';
import {
  getEmojiFieldFromReusableType,
  getImageFieldFromReusableType,
  getTextFieldFromReusableType
} from './fields';
import { validateReusable } from '../validation/reusables';

interface Props {
  projectId: number;
}

export type resetReusables = () => Promise<void>;
export type resetReusable = (reusableId: number) => Promise<void>;
export type saveReusable = (id: number) => Promise<Midas.Reusable>;
export type addReusable = (variableName: string) => void;
export type removeReusable = (reusableId: number) => Promise<void>;
export type setValue = (reusableId: number, path: string, value: any) => void;
export type setFieldValue = (
  reusableId: number,
  variableName: string,
  value: any,
  properties?: any
) => void;
export type addFieldToReusable = (
  reusableId: number,
  variableName: string,
  value: any,
  properties?: any
) => void;
export type checkMatchedError = (reusableId: number, path: Array<string | number>) => boolean;

export const isNewReusable = (id: number) => {
  return id < 0;
};

export default function useReusables({ projectId }: Props) {
  const [reusables, setReusables] = useState<Array<Midas.Reusable>>([]);
  const [reusableTypes, setReusableTypes] = useState<Array<Midas.ReusableType>>([]);
  const [errors, setErrors] = useState<Record<number, Array<any>>>({});

  useEffect(() => {
    getReusables(projectId).then((data) => {
      setReusables(data);
    });

    getProject(projectId).then((data) => {
      const types = data.properties?.reusableTypes?.filter(
        (data) => !(data instanceof Array)
      ) as Array<Midas.ReusableType>;
      setReusableTypes(types);
    });
  }, [projectId]);

  function resetReusables() {
    getReusables(projectId).then((data) => {
      setReusables(data);
    });
  }

  const resetReusable: resetReusable = (id) => {
    if (isNewReusable(id)) {
      const reusable = reusables.find((reusable) => reusable.id === id);
      const reusableType = reusableTypes.find((rt) => rt.variableName === reusable?.type);

      if (reusable && reusableType) {
        const newReusable: Midas.Reusable = createNewReusable(reusableType);
        newReusable.id = id;
        setReusables(reusables.map((reusable) => (reusable.id === id ? newReusable : reusable)));
        return Promise.resolve();
      } else {
        return Promise.reject('Unable to find reusable to reset');
      }
    } else {
      return getReusable(projectId, id).then((data) => {
        if (data) {
          setReusables(reusables.map((reusable) => (reusable.id === id ? data : reusable)));
        }
      });
    }
  };

  const saveReusable: saveReusable = (id) => {
    const selectedReusable = reusables.find((reusable) => reusable.id === id);
    const selectedReusableType = reusableTypes.find(
      (reusableType) => reusableType.variableName === selectedReusable?.type
    );

    if (!selectedReusable || !selectedReusableType) {
      return Promise.reject();
    }

    return validateReusable(selectedReusable, selectedReusableType)
      .then(() => {
        if (isNewReusable(id)) {
          return createReusable(projectId, selectedReusable);
        } else {
          return updateReusable(projectId, selectedReusable);
        }
      })
      .then((data) => {
        if (isNewReusable(id)) {
          const newReusableList = reusables.filter((reusable) => reusable.id !== id);
          setReusables([...newReusableList, data]);
        } else {
          setReusables(reusables.map((reusable) => (reusable.id === id ? data : reusable)));
          setErrors({});
        }
        return data;
      })
      .catch((error) => {
        if (error.isJoi) {
          setErrors({ [id]: error.details });
        }

        throw error;
      });
  };

  /**
   * Finds the lowest negative number to use as a temporary ID
   */
  const getTemporaryId = () => {
    return reusables.reduce((acc, reusable) => Math.min(acc, reusable.id), 0) - 1;
  };

  const createNewReusable = (reusableType: Midas.ReusableType) => {
    return {
      id: getTemporaryId(),
      type: reusableType.variableName,
      fields: Object.values(reusableType.fields)
        .filter((field) => !(field instanceof Array))
        .map((field) => {
          if (isReusableTypeEmojiField(field)) {
            return getEmojiFieldFromReusableType(field);
          }
          if (isReusableTypeImageField(field)) {
            return getImageFieldFromReusableType(field);
          }
          return getTextFieldFromReusableType(field);
        })
    };
  };

  const addReusable: addReusable = (variableName) => {
    const reusableType = reusableTypes.find((rt) => rt.variableName === variableName);
    if (!reusableType) {
      return Promise.reject('Did not find matching reusable type');
    }

    const newReusable: Midas.Reusable = createNewReusable(reusableType);

    setReusables([...reusables, newReusable]);
  };

  const removeReusable: removeReusable = async (reusableId) => {
    if (!isNewReusable(reusableId)) {
      await deleteReusable(projectId, reusableId);
    }
    const newReusableList = reusables.filter((reusable) => reusable.id !== reusableId);

    setReusables(newReusableList);
  };

  const setValue: setValue = (reusableId, path, value) => {
    const selectedReusable = reusables.find((reusable) => reusable.id === reusableId);
    if (!selectedReusable) {
      return;
    }

    const updatedReusable = dotProp.set(selectedReusable, path, value);

    setReusables(
      reusables.map((reusable) => (reusable.id === reusableId ? updatedReusable : reusable))
    );
  };

  const setFieldValue: setFieldValue = (reusableId, variableName, value, properties) => {
    const selectedReusable = reusables.find((reusable) => reusable.id === reusableId);
    if (!selectedReusable) {
      return;
    }

    let selectedField = selectedReusable.fields.find(
      (field) => field.variableName === variableName
    );
    if (!selectedField) {
      return addFieldToReusable(reusableId, variableName, value, properties);
    }

    const updatedField = {
      ...selectedField,
      text: value,
      properties: properties
    };

    const updatedReusable = {
      ...selectedReusable,
      fields: selectedReusable.fields.map((field) =>
        field.variableName === variableName ? updatedField : field
      )
    };

    setReusables(
      reusables.map((reusable) => (reusable.id === reusableId ? updatedReusable : reusable))
    );
  };

  const addFieldToReusable: addFieldToReusable = function (
    reusableId,
    variableName,
    value,
    properties
  ) {
    const selectedReusable = reusables.find((reusable) => reusable.id === reusableId);
    if (!selectedReusable) {
      return;
    }
    const selectedReusableType = reusableTypes.find(
      (rt) => rt.variableName === selectedReusable.type
    );
    if (!selectedReusableType) {
      return;
    }
    const selectedFieldType = selectedReusableType.fields.find(
      (fieldType) => fieldType.variableName === variableName
    );
    if (!selectedFieldType) {
      return;
    }

    const newField = {
      variableName: variableName,
      type: selectedFieldType.type,
      text: value,
      properties: properties
    };

    setReusables(
      reusables.map((reusable) =>
        reusable.id !== reusableId
          ? reusable
          : {
              ...selectedReusable,
              fields: [...selectedReusable.fields, newField]
            }
      )
    );
  };

  const checkMatchedError: checkMatchedError = function (reusableId, path) {
    return errors[reusableId]?.some((error) => {
      return (
        Array.isArray(error.path) &&
        Array.isArray(path) &&
        error.path.length === path.length &&
        error.path.every((val: string, index: number) => val === path[index])
      );
    });
  };

  return {
    reusables,
    reusableTypes,
    resetReusables,
    resetReusable,
    saveReusable,
    addReusable,
    setValue,
    setFieldValue,
    removeReusable,
    checkMatchedError
  };
}
