import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import FormProps from '../form-props';

interface Props extends FormProps {
  description?: string;
  placeholder?: string;
  value?: any;
  size?: number;
  min?: number;
  max?: number;
  warning?: string;
  isInvalid?: boolean;
  focusInput?: boolean;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

export default function LabelledInputNumber({
  label,
  className,
  description,
  placeholder,
  name,
  disabled,
  value,
  size,
  min,
  warning,
  isInvalid,
  onChange,
  onKeyDown,
  focusInput,
  max
}: Props) {
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusInput && textInput.current !== null) {
      //Set focus on input
      textInput.current.focus();
    }
  }, [focusInput]);

  const labelClassName = classnames('flex flex-row-reverse items-center gap-2', className);
  const inputClassName =
    'rounded py-2 px-3 border border-gray-400 font-medium text-sm invalid:border-yellow-400 invalid:shadow-none';

  return (
    <label className={labelClassName}>
      <div className="grid flex-1">
        <span className="font-semibold text-sm">{label}</span>
        {description && <small className="opacity-75 text-sm">{description}</small>}
        {isInvalid && warning && (
          <small className="py-1 px-2 text-sm bg-yellow-400 rounded block">{warning}</small>
        )}
      </div>
      <input
        type="number"
        placeholder={placeholder}
        value={value}
        size={size}
        className={inputClassName}
        disabled={disabled}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-invalid={isInvalid}
        min={min}
        max={max}
        ref={textInput}
      />
    </label>
  );
}
