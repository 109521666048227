import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { SectionHeader } from './section-header';
import { SectionContent } from './section-content';

function Section({ children, className }: Props) {
  let subComponentList = [SectionHeader, SectionContent];

  let subComponents = subComponentList.map((childType) => {
    return React.Children.map(children, (child: any) => {
      if (child && child.type) {
        return child.type === childType ? child : null;
      }
      return null;
    });
  });

  return (
    <div className={classNames('lg:flex gap-16', className)}>
      {subComponents.map((component) => component)}
    </div>
  );
}

interface Props {
  className?: string;
  children: ReactNode;
}

Section.Header = SectionHeader;
Section.Content = SectionContent;

export default Section;
