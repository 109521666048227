import classNames from 'classnames';
import FormProps from '../form-props';

export default function RadioGroup({ children }: any) {
  return (
    <div
      role="radiogroup"
      className="h-full grid grid-flow-col gap-3"
      aria-labelledby="radio-group-test"
    >
      {children}
    </div>
  );
}

interface Props extends FormProps {
  description?: string;
  checked: boolean;
  value: any;
  onChange: (e: any) => void;
}

function RadioButton({ label, description, checked, disabled, value, onChange, name }: Props) {
  const radioButtonClasses = checked ? 'ring-2 ring-primary' : '';
  return (
    <>
      <label
        className={classNames(
          'flex items-start rounded-md py-4 pl-4 pr-8 bg-white shadow cursor-pointer',
          radioButtonClasses
        )}
      >
        <div className="grid">
          <div className="font-semibold">{label}</div>
          {description ? (
            <div id={`description-${value}`} aria-hidden="true">
              {description}
            </div>
          ) : (
            ''
          )}
        </div>
        <input
          className="order-first mt-1 mr-2 w-4 h-4"
          name={name}
          type="radio"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          aria-describedby={`description-${value}`}
          value={value}
        ></input>
      </label>
    </>
  );
}

RadioGroup.Button = RadioButton;
