import { useState } from 'react';
import {
  getDefaultImageSpecification,
  getImageSpecificationFromReusable,
  getImageStyling
} from '../../../utils/image';
import SimpleModal from '../../modal/simple-modal';
import ImageModal from './image-modal';

interface Props {
  field: Midas.ImageField;
  reusableTypeImageField: Midas.ReusableTypeImageField;
  reusableType: Midas.ReusableType;
  onChange: (value: string, prop?: any) => void;
  isInvalid?: boolean;
  warning?: string;
}

export default function ImageSelect({
  field,
  reusableTypeImageField,
  reusableType,
  onChange,
  isInvalid,
  warning
}: Props) {
  const [showModal, setShowModal] = useState(false);

  function onImageChange(src: string, params: any, kaleidoId: string, altText: string) {
    onChange(altText, {
      src,
      params,
      kaleidoId
    });
  }

  function onImageOpen() {
    setShowModal(true);
  }
  function onImageClose() {
    setShowModal(false);
  }

  let styling;

  if (field && reusableTypeImageField) {
    const imageSpecification =
      getImageSpecificationFromReusable(field, reusableType) || getDefaultImageSpecification();
    styling = getImageStyling(imageSpecification);
  }

  return (
    <>
      {showModal && (
        <SimpleModal onClose={onImageClose} className="max-w-7xl">
          <ImageModal
            kaleidoId={field.properties.kaleidoId}
            params={field.properties.params || {}}
            imageSpecification={reusableTypeImageField.properties || {}}
            src={field.properties.src || ''}
            alt={field.text || ''}
            onChange={onImageChange}
            onClose={onImageClose}
          />
        </SimpleModal>
      )}
      <div className="grid gap-2">
        <label className="text-sm font-semibold">Bilde</label>
        <button
          onClick={onImageOpen}
          className="flex gap-2 items-center p-2 rounded shadow bg-white text-sm text-left disabled:opacity-60 disabled:cursor-not-allowed"
        >
          {field.properties.src && (
            <div className="w-16 h-16 flex items-center justify-center border-gray-100 border bg-gray-200">
              <img
                src={field.properties.src}
                className="object-cover rounded-sm "
                style={styling}
                alt={field.text}
              />
            </div>
          )}
          {field.text || 'Last opp eller finn bilde'}
        </button>
      </div>
      {isInvalid && warning && (
        <small className="py-1 px-2 text-xs bg-yellow-400 rounded">{warning}</small>
      )}
    </>
  );
}
