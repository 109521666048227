import Expand from '../../components/expand/expand';
import ExpandButton from '../../components/expand/expand-button';
import LabelledSwitch from '../../components/form/labelled-switch/labelled-switch';
import { IconChevronClosed } from '../../components/icons/icons';
import { toggleFlag } from '../../services/use-polls';
import Alternative from './alternative/alternative';
import useVotes from '../../services/use-votes';

interface Params {
  projectId: number;
  poll: Midas.Poll;
  pollType: Midas.PollType;
  reusables: Array<Midas.Reusable>;
  reusableTypes: Array<Midas.ReusableType>;
  onToggleVotes: (pollId: number) => () => void;
  toggleFlag: toggleFlag;
}

export const EXECUTE_ITEM_TYPE = 'executeitem';

export default function ExecuteItem({
  projectId,
  poll,
  pollType,
  reusables,
  reusableTypes,
  onToggleVotes,
  toggleFlag
}: Params) {
  const { votes } = useVotes(projectId, poll.id);

  return (
    <div className="relative mb-2">
      <div className="flex-1">
        <ExpandButton
          expandKey={`poll-${poll.id}`}
          className="relative z-10 flex items-center justify-between flex-1 w-full py-2 pl-3 pr-6 text-left bg-white rounded shadow"
        >
          <div className="flex items-center flex-1 gap-2">
            <IconChevronClosed className="mid-expand-indicator" />
            {poll.active && (
              <svg className="w-5 h-5 text-red-600" viewBox="0 0 20 20" fill="currentColor">
                <circle cx="10" cy="10" r="10" opacity="0.25" />
                <circle cx="10" cy="10" r="7" />
              </svg>
            )}
            <div className="grid mr-auto">
              <strong className="font-semibold">{poll.text || 'Mangler tittel'}</strong>
              <span className="opacity-80">Reaksjoner | ID {poll.id}</span>
            </div>
          </div>
          <div className="flex items-center text-2xl font-light text-right">
            {votes?.votes || 0}
          </div>
        </ExpandButton>
        <Expand expandKey={`poll-${poll.id}`}>
          <div className="grid lg:flex items-start flex-wrap w-full gap-6 lg:gap-12 pl-10 pr-6 py-6 bg-gray-200 rounded-b">
            <div className="flex-1 grid gap-2 content-start items-start lg:w-1/2 lg:order-last lg:sticky lg:top-6">
              <LabelledSwitch
                label="Åpen for stemmer"
                className="flex-row-reverse justify-between px-6 py-4 font-semibold bg-white rounded-md shadow-sm cursor-pointer hover:ring-2 hover:ring-gray-400 min-h-24"
                onChange={onToggleVotes(poll.id)}
                checked={poll.active}
              />
              {poll.flags.map((flag) => {
                const flagType = pollType?.flags.find((ft) => ft.variableName === flag.name);
                return (
                  <LabelledSwitch
                    key={flag.name}
                    label={flagType?.title || flag.name}
                    className="flex-row-reverse justify-between flex-1 px-6 py-4 font-semibold bg-white rounded-md shadow-sm cursor-pointer hover:ring-2 hover:ring-gray-400"
                    onChange={() => toggleFlag(poll.id, flag.name)}
                    checked={flag.value}
                  />
                );
              })}
            </div>
            <div className="flex-1 lg:w-1/2">
              <dl className="w-full">
                {poll.alternatives.map((alt) => {
                  const alternativeVotes = votes?.alternatives.find(
                    (altVotes) => altVotes.id === alt.id
                  );
                  return (
                    <div
                      key={alt.id}
                      className="flex items-center justify-between py-2 border-t border-gray-300"
                    >
                      <Alternative
                        reusables={reusables}
                        reusableTypes={reusableTypes}
                        alternative={alt}
                        points={alternativeVotes?.votes || 0}
                      />
                    </div>
                  );
                })}
              </dl>
            </div>
          </div>
        </Expand>
      </div>
    </div>
  );
}
