import Tile from '../../../components/tile/tile';

interface Props {
  title: string;
  tags?: Array<string>;
  id: number;
}

export default function ProjectTile({ title, tags, id }: Props) {
  return (
    <Tile>
      <div className="grid text-sm">
        <div className="font-semibold">{title}</div>
        <div className="opacity-80">
          ID {id}
          {tags && '|'}
          {tags &&
            tags.map((tag, i) => {
              const hasNext = i + 1 < tags.length;
              return <span key={tag}>{hasNext ? `${tag}, ` : tag}</span>;
            })}
        </div>
      </div>
    </Tile>
  );
}
