import * as msal from '@azure/msal-browser';

// See guide for @azure/msal-browser:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md

const SCOPES: string[] = ['api://midas-v3/default'];

const msalConfig = {
  auth: {
    clientId: '224c626e-5ccb-4dba-a969-bb786bffd0ce',
    authority: 'https://login.microsoftonline.com/9d2ac018-e843-4e14-9e2b-4e0ddac75450',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export function initAuth() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    return Promise.resolve();
  } else {
    return new Promise((resolve, reject) => {
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
              // No user signed in
              msalInstance.loginRedirect({ scopes: SCOPES });
            } else {
              msalInstance.setActiveAccount(accounts[0]);
              resolve(accounts[0]);
            }
          } else {
            if (tokenResponse.account) {
              msalInstance.setActiveAccount(tokenResponse.account);
              resolve(tokenResponse.account);
            }
          }
        })
        .catch((err) => {
          // Handle error
          console.error(err);
          reject(err);
        });
    });
  }
}

export async function getAccessToken() {
  return msalInstance
    .acquireTokenSilent({ scopes: SCOPES })
    .then((tokenResponse) => tokenResponse.accessToken)
    .catch((error) => {
      if (error.name === 'InteractionRequiredAuthError') {
        msalInstance.acquireTokenRedirect({ scopes: SCOPES });
      } else {
        throw error;
      }
    });
}

export function getName(): string | null {
  const account = msalInstance.getActiveAccount();
  if (account !== null) {
    return account.name || account.username;
  }
  return null;
}

export function login() {
  msalInstance.loginRedirect({ scopes: SCOPES });
}

export function logout() {
  console.warn('@TODO - logout() not implemented yet');
  // @todo we should use logoutRedirect since logout is deprecated.
  // however, where should we redirect to?
  // msalInstance.logoutRedirect()
}
