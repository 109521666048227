import classNames from 'classnames';
import {
  OrgDownload,
  OrgCopy,
  OrgClose,
  OrgGarbage,
  OrgChevronClosed,
  OrgChevronOpen,
  OrgDotdotdot,
  OrgPlus,
  OrgHelp,
  OrgEdit
} from '@nrk/origo/jsx';

function IconWrap(icon: Function) {
  return function ({ className }: Props) {
    const iconClassNames = classNames('inline-flex text-sm opacity-60', className);
    return <span className={iconClassNames}>{icon({})}</span>;
  };
}
export const IconCopy = IconWrap(OrgCopy);
export const IconClose = IconWrap(OrgClose);
export const IconDotdotdot = IconWrap(OrgDotdotdot);
export const IconDownload = IconWrap(OrgDownload);
export const IconGarbage = IconWrap(OrgGarbage);
export const IconChevronOpen = IconWrap(OrgChevronOpen);
export const IconChevronClosed = IconWrap(OrgChevronClosed);
export const IconPlus = IconWrap(OrgPlus);
export const IconHelp = IconWrap(OrgHelp);
export const IconEdit = IconWrap(OrgEdit);

interface Props {
  className?: string;
}
