import Joi from 'joi';

import ProjectSchema from './schemas/project';

export function validateProject(
  project: Midas.Project
): Promise<Joi.ValidationError | Midas.Project> {
  const { error } = ProjectSchema.validate(project, { abortEarly: false });
  if (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(project);
}
