import * as api from '../api/groups';

export function getGroups(projectId: number) {
  return api.getGroups(projectId);
}

export function addGroup(projectId: number, groupTitle: string) {
  const newGroup: Midas.Group = {
    id: 0,
    title: groupTitle,
    state: 'DRAFT'
  };

  return api.createGroup(projectId, newGroup);
}

export function createGroup(projectId: number, group: Midas.Group) {
  return api.createGroup(projectId, group);
}

export function updateGroup(projectId: number, group: Midas.Group) {
  return api.updateGroup(projectId, group);
}

export function deleteGroup(projectId: number, groupId: number) {
  return api.deleteGroup(projectId, groupId);
}

export function getGroup(projectId: number, groupId: number) {
  return api.getGroup(projectId, groupId);
}

export function setGroupName(projectId: number, groupId: number, name: string) {
  return api.setGroupName(projectId, groupId, name);
}
