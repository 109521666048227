import ExpandButton from '../../components/expand/expand-button';
import Expand from '../../components/expand/expand';
import LabelledInput from '../../components/form/labelled-input/labelled-input';
import ReusableField from './reusable-field';
import { IconChevronClosed, IconGarbage } from '../../components/icons/icons';
import { ButtonAction, ButtonSave } from '../../components/button/button';
import MoreDropdown from '../../components/more-dropdown/more-dropdown';

import {
  saveReusable,
  resetReusable,
  setFieldValue,
  removeReusable,
  checkMatchedError,
  isNewReusable
} from '../../services/use-reusables';
import { useState } from 'react';
import ConfirmModal from '../../components/modal/confirm-modal';

interface Props {
  reusable: Midas.Reusable;
  reusableType: Midas.ReusableType;
  saveReusable: saveReusable;
  resetReusable: resetReusable;
  setFieldValue: setFieldValue;
  removeReusable: removeReusable;
  checkMatchedError: checkMatchedError;
}

function getGuessedTitle(reusable: Midas.Reusable): string | undefined {
  return reusable.fields.find((field) => field.type === 'TEXT' && field.text !== '')?.text;
}

export default function ReusableItem({
  reusable,
  reusableType,
  saveReusable,
  resetReusable,
  setFieldValue,
  removeReusable,
  checkMatchedError
}: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  function onChangeWrapper(variableName: string) {
    return (e: any) => {
      setFieldValue(reusable.id, variableName, e.target.value);
    };
  }
  function onPropChangeWrapper(variableName: string) {
    return (value: string, prop: any) => {
      setFieldValue(reusable.id, variableName, value, prop);
    };
  }

  function onConfirmDelete() {
    return removeReusable(reusable.id);
  }

  function wrappedCheckError(reusableId: number, pathPrefix: Array<string | number>) {
    return function (path: Array<string | number>) {
      return checkMatchedError(reusableId, [...pathPrefix, ...path]);
    };
  }

  return (
    <>
      <div className="relative">
        <div className="relative flex">
          <ExpandButton
            className="py-2 px-3 w-full bg-white text-left rounded shadow flex items-center gap-2"
            expandKey={`reusable-${reusable.id}`}
            isExpanded={false}
          >
            <div className="flex items-center">
              <IconChevronClosed className="mid-expand-indicator" />
            </div>
            <div className="grid text-sm">
              <strong className="font-semibold">
                {getGuessedTitle(reusable) || `Reusable ${Math.abs(reusable.id)}`}
              </strong>
              {!isNewReusable(reusable.id) && <span className="opacity-80">ID {reusable.id}</span>}
            </div>
          </ExpandButton>
          <div className="absolute right-0 inset-y-0 flex items-center px-3">
            <MoreDropdown dropdownKey={`reusable-options-${reusable.id}`}>
              <ButtonAction
                variant="secondary"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                <IconGarbage className="-ml-1" />
                Slett {reusable.type} &hellip;
              </ButtonAction>
            </MoreDropdown>
          </div>
        </div>
        <Expand expandKey={`reusable-${reusable.id}`} isExpanded={false}>
          <div className="pt-5 pb-8 px-5 sm:px-10 bg-gray-200 rounded-b">
            <div className="grid gap-6">
              {reusableType?.fields?.map((reusableTypeField, i) => {
                const field = reusable.fields.find(
                  (field) => field.variableName === reusableTypeField.variableName
                );

                if (!field) {
                  console.error(
                    'Finner ikke matchende felt + felttype: ',
                    reusableType.fields,
                    reusable.fields,
                    field
                  );

                  return <div>Problemer med å vise feltet. Sjekk console!</div>;
                }

                return (
                  <ReusableField
                    key={reusableTypeField.id}
                    field={field}
                    reusableTypeField={reusableTypeField}
                    reusableType={reusableType}
                    onChange={onChangeWrapper(reusableTypeField.variableName)}
                    onPropChange={onPropChangeWrapper(reusableTypeField.variableName)}
                    checkMatchedError={wrappedCheckError(reusable.id, ['fields', i])}
                  />
                );
              })}
              <div className="flex items-center gap-6">
                <LabelledInput className="flex-grow-0 w-12" label="ID" />
                <LabelledInput className="w-full" label="Tagger" />
              </div>
            </div>
          </div>
          <div className="bg-gray-300 rounded-b py-2 px-10 flex flex-row-reverse items-center gap-2">
            <ButtonSave
              onSave={() => {
                return saveReusable(reusable.id);
              }}
              variant="primary"
            >
              Lagre
            </ButtonSave>
            <ButtonSave
              onSave={() => {
                return resetReusable(reusable.id);
              }}
              variant="secondary"
            >
              Forkast endringer
            </ButtonSave>
          </div>
        </Expand>
      </div>
      {showDeleteModal && (
        <ConfirmModal
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={onConfirmDelete}
          confirm="Slett"
        >
          Er du sikker på at du vil slette?
        </ConfirmModal>
      )}
    </>
  );
}
