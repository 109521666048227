import dotProp from 'dot-prop-immutable';
import { useEffect, useRef, useState } from 'react';
import { getProject } from '../api/project';
import { deepClone } from '../utils/obj';
import { validatePoll } from '../validation/poll';
import { getGroup } from './groups';
import * as api from './polls';

interface Props {
  projectId: number;
  groupId: number;
}

/** EXAMPLE DATA */
export type addPoll = (variableName: string) => Promise<any>;
export type deletePoll = (id: number) => Promise<any>;
export type duplicatePoll = (id: number) => Promise<any>;
export type savePoll = (id: number) => Promise<any>;
export type resetPoll = (id: number) => Promise<any>;
export type saveAllPolls = () => Promise<any[]>;
export type resetAllPolls = () => Promise<void>;
export type setPollValue = (id: number, path: string, value: any) => void;
export type reorderPolls = (pollId: number, newIndex: number) => void;
export type addAlternative = (pollId: number) => void;
export type deleteAlternative = (pollId: number, alternativeIndex: number) => void;
export type setAlternativeValue = (
  pollId: number,
  alternativeId: number,
  path: string,
  value: any
) => void;
export type toggleActive = (pollId: number) => void;
export type toggleFlag = (pollId: number, flagName: string) => void;
export type checkMatchedError = (pollId: number, path: Array<string | number>) => boolean;

const sortPolls = function (a: Midas.Poll, b: Midas.Poll) {
  if (a.index < b.index) {
    return -1;
  }
  if (a.index > b.index) {
    return 1;
  }
  return 0;
};

export default function usePolls({ projectId, groupId }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [project, setProject] = useState<Midas.Project>();
  const [group, setGroup] = useState<Midas.Group>();
  const [polls, setPolls] = useState<Array<Midas.Poll>>([]);

  const [errors, setErrors] = useState<Record<number, Array<any>>>({});
  const [showPreview, setShowPreview] = useState(true);
  const previewEl = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (!projectId || !groupId) {
      return;
    }

    Promise.all([
      getProject(projectId),
      getGroup(projectId, groupId),
      api.getPollByGroup(projectId, groupId)
    ]).then(([project, group, polls]) => {
      const sortedPolls = polls.sort(sortPolls);

      setProject(project);
      setGroup(group);
      setPolls(sortedPolls);
    });
  }, [projectId, groupId]);

  const addPoll: addPoll = (variableName) => {
    const text = 'Nytt spørsmål';

    const pollType = project?.properties.pollTypes.find(
      (pollType) => pollType.variableName === variableName
    );

    if (!pollType) {
      console.log('pollType not found');
      return Promise.reject();
    }

    const newPoll = createEmptyPoll(text, groupId, pollType);

    return api.addPoll(projectId, newPoll).then((data) => {
      setPolls([...polls, data]);
    });
  };

  const deletePoll: deletePoll = (pollId) => {
    return api.deletePoll(projectId, pollId).then(() => {
      setPolls(polls.filter((poll) => poll.id !== pollId));
    });
  };

  const duplicatePoll: duplicatePoll = (pollId: number) => {
    const poll = polls.find((poll) => poll.id === pollId);

    if (!poll) {
      return Promise.reject();
    }

    const newPoll: Midas.Poll = deepClone(poll, {
      skipSpecific: ['id', 'updated', 'active', 'visible']
    });
    newPoll.groupId = groupId;
    newPoll.index = Math.max(...polls.map((poll) => poll.index)) + 1;

    return api.addPoll(projectId, newPoll).then((createdPoll: Midas.Poll) => {
      setPolls([...polls, createdPoll]);
    });
  };

  const saveAllPolls: saveAllPolls = () => {
    // TODO: Make dirty check?
    return Promise.all(
      polls.map((poll) => {
        return savePoll(poll.id);
      })
    );
  };

  const savePoll: savePoll = (id) => {
    const selectedPoll = polls.find((poll) => poll.id === id);

    if (!selectedPoll) {
      return Promise.reject();
    }

    return validatePoll(selectedPoll)
      .then(() => api.updatePoll(projectId, selectedPoll))
      .then((data) => {
        setPolls(polls.map((poll) => (poll.id === id ? data : poll)));
        setErrors({});
      })
      .catch((error) => {
        if (error.isJoi) {
          setErrors({ [id]: error.details });
        }

        throw error;
      });
  };

  const resetPoll: resetPoll = (id) => {
    return api.getPoll(projectId, id).then((data) => {
      setPolls(polls.map((poll) => (poll.id === id ? data : poll)));
    });
  };

  const resetAllPolls: resetAllPolls = () => {
    return api.getPollByGroup(projectId, groupId).then((polls) => {
      const sortedPolls = polls.sort(sortPolls);
      setPolls(sortedPolls);
    });
  };

  const setPollValue: setPollValue = (id, path, value) => {
    const selectedPoll = polls.find((poll) => poll.id === id);
    if (!selectedPoll) {
      return;
    }
    const updatedPoll = dotProp.set(selectedPoll, path, value);

    setPolls(polls.map((poll) => (poll.id === id ? updatedPoll : poll)));
  };

  const reorderPolls: reorderPolls = function (pollId, newIndex) {
    const newList = polls
      .map((poll, ind) => {
        return {
          ...poll,
          index: poll.id === pollId ? newIndex : ind < newIndex ? ind : ind + 1
        };
      })
      .sort(sortPolls);

    api.saveReOrder(projectId, newList).then(() => {
      setPolls(newList);
    });
  };

  const setAlternativeValue: setAlternativeValue = (pollId, alternativeId, path, value) => {
    const selectedPoll = polls.find((poll) => poll.id === pollId);
    const selectedAlternative = selectedPoll?.alternatives?.find((alt) => alt.id === alternativeId);

    if (!selectedPoll || !selectedAlternative) {
      return;
    }

    const updatedAlternative = dotProp.set(selectedAlternative, path, value);

    const updatedPoll: Midas.Poll = {
      ...selectedPoll,
      alternatives: selectedPoll.alternatives.map((alt) =>
        alt.id === alternativeId ? updatedAlternative : alt
      )
    };

    setPolls(polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)));
  };

  const addAlternative: addAlternative = (pollId) => {
    const selectedPoll = polls.find((poll) => poll.id === pollId);
    if (!selectedPoll) {
      return;
    }

    const pollType = project?.properties.pollTypes.find(
      (pt) => pt.variableName === selectedPoll.presentation
    );

    if (!pollType) {
      return;
    }

    const ids = selectedPoll.alternatives?.map((alt) => alt.id) || [];

    const nextId = Math.max(...ids, 0) + 1;

    const updatedPoll = {
      ...selectedPoll,
      alternatives: [
        ...(selectedPoll.alternatives || []),
        createEmptyAlterantive(nextId, pollType.alternativeType)
      ]
    };

    setPolls(polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)));
  };

  const deleteAlternative: deleteAlternative = (pollId, altId) => {
    const selectedPoll = polls.find((poll) => poll.id === pollId);

    if (!selectedPoll) {
      return;
    }

    const updatedPoll = {
      ...selectedPoll,
      alternatives: selectedPoll.alternatives.filter((alt) => alt.id !== altId)
    };

    setPolls(polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)));
  };

  const toggleActive: toggleActive = function (pollId) {
    const selectedPoll = polls.find((poll) => poll.id === pollId);
    if (!selectedPoll) {
      return;
    }

    const updatedPoll = {
      ...selectedPoll,
      active: !selectedPoll.active
    };

    setPolls(polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)));
  };

  const toggleFlag: toggleFlag = function (pollId, flagName) {
    const selectedPoll = polls.find((poll) => poll.id === pollId);
    const flag = selectedPoll?.flags.find((flag) => flag.name === flagName);
    if (!flag || !selectedPoll) {
      return;
    }

    const updatedFlag: Midas.Flag = {
      ...flag,
      value: !flag.value
    };

    const updatedPoll: Midas.Poll = {
      ...selectedPoll,
      flags: selectedPoll.flags.map((flag) => (flag.name === flagName ? updatedFlag : flag))
    };

    setPolls(polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)));
  };

  const checkMatchedError: checkMatchedError = function (pollId, path) {
    return errors[pollId]?.some((error) => {
      return (
        Array.isArray(error.path) &&
        Array.isArray(path) &&
        error.path.length === path.length &&
        error.path.every((val: string, index: number) => val === path[index])
      );
    });
  };

  const unlock = (id: number) => {};

  return {
    project,
    previewEl,
    unlock,
    isLoading,
    polls,
    setPolls,
    setPollValue,
    addPoll,
    deletePoll,
    duplicatePoll,
    savePoll,
    resetPoll,
    saveAllPolls,
    resetAllPolls,
    toggleActive,
    reorderPolls,
    addAlternative,
    deleteAlternative,
    setAlternativeValue,
    showPreview,
    setShowPreview,
    toggleFlag,
    checkMatchedError,
    group
  };
}

function createEmptyPoll(text: string, groupId: number, pollType: Midas.PollType): Midas.Poll {
  return {
    id: 0, // We get one from the server
    groupId: groupId,
    text: text,
    media: {},
    rules: pollType.rules,
    published: false,
    active: false,
    index: 0,
    externalIds: [],
    presentation: pollType.variableName,
    flags: (pollType.flags || []).map((flag) => {
      return { name: flag.variableName, value: flag.defaultValue };
    }),
    properties: {},
    alternatives: [
      createEmptyAlterantive(1, pollType.alternativeType),
      createEmptyAlterantive(2, pollType.alternativeType)
    ]
  };
}

function createEmptyAlterantive(index: number, alternativeType: string): Midas.Alternative {
  return {
    id: index,
    index: index,
    text: '',
    type: alternativeType,
    media: {},
    properties: {}
  };
}
