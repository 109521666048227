import LabelledInput from '../../components/form/labelled-input/labelled-input';
import ImageSelect from '../../components/form/image-select/image-select';
import EmojiSelect from '../../components/form/emoji-select/emoji-select';
import ErrorBoundary from '../../components/error-boundary/error-boundary';

interface Props {
  field: Midas.Field;
  reusableTypeField: Midas.ReusableTypeField;
  reusableType: Midas.ReusableType;
  onChange: (e: any) => void;
  onPropChange: (value: string, prop?: any) => void;
  checkMatchedError: (path: Array<string | number>) => boolean;
}

export default function ReusableField({
  field,
  reusableTypeField,
  reusableType,
  onChange,
  onPropChange,
  checkMatchedError
}: Props) {
  switch (reusableTypeField.type) {
    case 'TEXT':
      return (
        <ErrorBoundary>
          <div className="flex items-center gap-6">
            <LabelledInput
              label={reusableTypeField.title}
              value={field.text || ''}
              onChange={onChange}
              isInvalid={checkMatchedError(['text'])}
              warning={`${reusableTypeField.title} må være satt`}
            />
          </div>
        </ErrorBoundary>
      );
    case 'IMAGE': {
      const imgError = checkMatchedError(['properties', 'src']);
      const altError = checkMatchedError(['text']);
      return (
        <ErrorBoundary>
          <ImageSelect
            field={field as Midas.ImageField}
            reusableTypeImageField={reusableTypeField as Midas.ReusableTypeImageField}
            reusableType={reusableType}
            onChange={onPropChange}
            isInvalid={imgError || altError}
            warning={imgError ? 'Bilde må være satt' : 'Alternativ tekst må være satt'}
          />
        </ErrorBoundary>
      );
    }
    case 'EMOJI':
      return (
        <ErrorBoundary>
          <div className="flex items-center gap-6">
            <EmojiSelect
              label={reusableTypeField.title}
              value={field.text || ''}
              description=""
              onChange={onPropChange}
              isInvalid={checkMatchedError(['text'])}
              warning="Emoji må være satt"
            />
          </div>
        </ErrorBoundary>
      );
    default:
      return <div>???</div>;
  }
}
