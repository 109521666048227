import dotProp from 'dot-prop-immutable';
import standardJSON from '../services/standard.json';
import { deepClone } from '../utils/obj';
import * as api from '../api/project';

export function createStandard(title: string, url: string, voteCount: number) {
  let project = deepClone(standardJSON);

  project = dotProp.set(project, 'title', title);
  project = dotProp.set(project, 'properties.clientURL', url);

  project.properties.pollTypes[0].rules.votesPerUser = voteCount;

  return project;
}

export function changeProjectTitle(projectId: number, title: string) {
  return api.changeProjectTitle(projectId, title);
}
