import AlternativeImage from './alternative-image';
import AlternativeReusable from './alternative-reusable';
import AlternativeText from './alternative-text';
import AlternativeEmoji from './alternative-emoji';

export default function Alternative(props: Admin.AlternativeProps) {
  if (isAlternativeText(props)) {
    return <AlternativeText {...props} />;
  }
  if (isAlternativeEmoji(props)) {
    return <AlternativeEmoji {...props} />;
  }
  if (isAlternativeImage(props)) {
    return <AlternativeImage {...props} />;
  }
  if (isAlternativeReusable(props)) {
    return <AlternativeReusable {...props} />;
  }

  console.error('Alternative type is not known: ', props);
  return <div>Kjenner ikke til alternativ type. Sjekk console</div>;
}

function isAlternativeText(props: Admin.AlternativeProps): props is Admin.AlternativeTextProps {
  return (props as Admin.AlternativeTextProps).alternative.type === 'TEXT';
}

function isAlternativeEmoji(props: Admin.AlternativeProps): props is Admin.AlternativeEmojiProps {
  return (props as Admin.AlternativeEmojiProps).alternative.type === 'EMOJI';
}

function isAlternativeImage(props: Admin.AlternativeProps): props is Admin.AlternativeImageProps {
  return (props as Admin.AlternativeImageProps).alternative.type === 'IMAGE';
}

function isAlternativeReusable(
  props: Admin.AlternativeProps
): props is Admin.AlternativeReusableProps {
  return (props as Admin.AlternativeReusableProps).reusables !== undefined;
}
