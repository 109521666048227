import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { ButtonAction, ButtonSave } from '../button/button';

interface Props {
  children: any;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  className?: string;
  confirm?: string;
  cancel?: string;
}

export default function ConfirmModal({
  children,
  className,
  onCancel,
  onConfirm,
  confirm = 'Bekreft',
  cancel = 'Avbryt'
}: Props) {
  const modalClassName = classNames(
    'rounded shadow-lg bg-white relative w-full max-w-lg overflow-hidden',
    className
  );

  return createPortal(
    <div className="fixed inset-0 z-30 flex items-center justify-center w-full h-full bg-dark bg-opacity-80">
      <div className={modalClassName}>
        <div className="px-4 pt-4 pb-8 bg-gray-200">{children}</div>
        <div className="flex justify-end p-2">
          <ButtonAction variant="default" onClick={onCancel} className="mr-2">
            {cancel}
          </ButtonAction>
          <ButtonSave variant="primary" onSave={onConfirm}>
            {confirm}
          </ButtonSave>
        </div>
      </div>
    </div>,
    document.body
  );
}
